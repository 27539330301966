import React from 'react';
import { GetProductByMenuIdAPI, URLForImages } from '../../Apis/Apis';
import axios from 'axios';

const Design = ({ item, setMenuDetail }) => {

    const getProducts = async (id) => {
        try {
            const { data } = await axios.get(`${GetProductByMenuIdAPI}/${id}`);
            if (data.success === true) {
                setMenuDetail(
                    {
                        status: true,
                        products: data?.products,
                        menu: data?.menu
                    }
                );
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div onClick={() => getProducts(item?._id)} className="d-flex align-items-center list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
            <div className="list-card-image">
                <a>
                    <img alt="#" src={item?.file_id ? `${URLForImages}/${item?.file_id?.file_path}` : 'img/trending2.png'} className="img-fluid item-img w-100" />
                </a>
            </div>
            <div className="p-3 position-relative">
                <div className="list-card-body">
                    <h6 className="mb-1"><a className="text-black">{item?.name}
                    </a>
                    </h6>
                    <p className="text-gray m-0">{item?.branch_id?.address} Branch</p>
                    <p className="text-gray time m-0">
                        <span className=" text-dark rounded-sm pl-2 pb-1 pt-1 pr-2">
                        </span> <span className="float-right text-black-50"></span>
                    </p>
                </div>
                {/* <div className="list-card-badge">
                    <span className="badge badge-danger">Status</span> <small>{item?.status === 1 ? 'Active' : 'InActive'}</small>
                </div> */}
            </div>
        </div>
    )
}

export default Design
import React, { useState } from 'react';
import { ContactAPI } from '../../Apis/Apis';
import { AlertHandler } from '../../Common/AlertHandler';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';

const Contact = () => {
    const User = useSelector(state => state.Auth.user);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const sendContactData = async (e) => {
        e.preventDefault();
        if (name === '') return AlertHandler('Name Required', 'error');
        if (email === '') return AlertHandler('Email Required', 'error');
        if (phone === '') return AlertHandler('Phone Required', 'error');
        if (message === '') return AlertHandler('Message Required', 'error');
        try {
            setLoading(true);
            const { data } = await axios.post(ContactAPI, { name, email, phone, message });
            if (data.success === true) {
                setLoading(false);
                AlertHandler(data.message, 'success');
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    return (
        <div className="osahan-profile">
            <div className="d-none">
                <div className="bg-primary border-bottom p-3 d-flex align-items-center">
                    <a className="toggle togglew toggle-2" href="#"><span /></a>
                    <h4 className="font-weight-bold m-0 text-white">Contact Us</h4>
                </div>
            </div>
            <div className="container position-relative">
                <div className="py-5 osahan-profile row">
                    <div className="col-md-12 mb-3">
                        <div className="rounded shadow-sm">
                            <div className="osahan-cart-item-profile bg-white rounded shadow-sm p-4">
                                <div className="flex-column">
                                    <h6 className="font-weight-bold">Tell us about yourself</h6>
                                    <p className="text-muted">Whether you have questions or you would just like to say hello, contact us.</p>
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="exampleFormControlInput1" className="small font-weight-bold">Your Name</label>
                                            <input onChange={(e) => setName(e.target.value)} type="text" className="form-control" id="exampleFormControlInput1" placeholder='Your Name' />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="exampleFormControlInput2" className="small font-weight-bold">Email Address</label>
                                            <input onChange={(e) => setEmail(e.target.value)} type="email" className="form-control" id="exampleFormControlInput2" placeholder="Email Address" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="exampleFormControlInput3" className="small font-weight-bold">Phone Number</label>
                                            <input onChange={(e) => setPhone(e.target.value)} type="number" className="form-control" id="exampleFormControlInput3" placeholder="Phone Number" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="exampleFormControlTextarea1" className="small font-weight-bold">HOW CAN WE HELP YOU?</label>
                                            <textarea onChange={(e) => setMessage(e.target.value)} className="form-control" id="exampleFormControlTextarea1" placeholder="Hi there, I would like to ..." rows={3} defaultValue={""} />
                                        </div>
                                        <a className="btn btn-primary btn-block text-white" onClick={sendContactData} >
                                            {loading ? <CircularProgress size='1rem' style={{ color: 'white' }} /> : 'SUBMIT'}
                                        </a>
                                    </form>
                                    <div className="mapouter pt-3">
                                        <div className="gmap_canvas">
                                            <iframe width="100%" height="100%" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3403.281784817847!2d74.27577594998174!3d31.461433857108055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391901618fdb69b1%3A0xd4e93fd76b35bf0!2sSkylinx%20Technologies!5e0!3m2!1sen!2s!4v1663157475271!5m2!1sen!2s" frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0} />
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Contact;
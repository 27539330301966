import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    message: null,
    type: null
}

const AlertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        setAlert(state, action) {
            state.message = action.payload.message;
            state.type = action.payload.type;
        },
        removeAlert(state, action) {
            state.message = null;
            state.type = null;
        }
    }
});

export const { setAlert, removeAlert } = AlertSlice.actions;
export default AlertSlice.reducer;
import React ,{useEffect} from 'react';

const RestaurantHead = ({ data, URLForImages }) => {
    useEffect(() => {
   window.scrollTo(0,0)
    }, [])
    
    return (
        <div className="offer-section py-4">
            <div style={{marginTop:""}} className="container position-relative">
                <img style={{ margin: 0 }} alt="#" src={data?.restaurant?.file_id !== null ? `${URLForImages}/${data?.restaurant?.file_id?.file_path}` : "img/trending3.png"} className="restaurant-pic pt-3" />
                <div className="pt-3 text-white r-detail" style={{ width: '70%' }}>
                    <h2 data-aos="fade-up" className="font-weight-bold" style={{ textTransform: 'capitalize', fontSize:'36px' }}>{data?.restaurant?.name}</h2>
                    <p className="text-white m-0" style={{fontSize:'15px'}}>{data?.restaurant?.bio}</p>
                    <div className="rating-wrap d-flex align-items-center mt-2">
                        <ul className="rating-stars list-unstyled">
                            <li>
                                <i style={{fontSize:'20px'}} className={(data?._actualRating === 0 || data?._actualRating === null) ? "fa fa-star" : "fa fa-star text-warning"} />
                                <i  style={{fontSize:'20px'}} className={data?._actualRating < 2 ? "fa fa-star" : "fa fa-star text-warning"} />
                                <i  style={{fontSize:'20px'}} className={data?._actualRating < 3 ? "fa fa-star" : "fa fa-star text-warning"} />
                                <i  style={{fontSize:'20px'}} className={data?._actualRating < 4 ? "fa fa-star" : "fa fa-star text-warning"} />
                                <i  style={{fontSize:'20px'}} className={data?._actualRating < 5 ? "fa fa-star" : "fa fa-star text-warning"} />
                            </li>
                        </ul>
                        <p className="label-rating text-white ml-2"> ({data?.rating?.peopleRated} Reviews)</p>
                    </div>
                </div>
                <div className="pb-4">
                    <div className="row">
                        <div className="col-6 col-md-2">
                        </div>
                        <div className="col-6 col-md-2">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RestaurantHead;
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    coords: null,
    address: null
}

const LocationSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        setLocation(state, action) {
            state.coords = action.payload.coords;
            state.address = action.payload.address
        },
        removeLocation(state, action) {
            state.coords = null;
            state.address = null
        }
    }
});

export const { setLocation, removeLocation } = LocationSlice.actions;
export default LocationSlice.reducer;
import React from 'react';

const RatingReviews = ({ data, productDetail, ratingData })  => {
    // console.log(isArray(data) && data,'majisssd');
    // const ratingscopydata =  data &&  [...data];
    // console.log(ratingscopydata , 'baloch');
    
    // const dataRating= [...data];
    console.log("data",data);

    return (
        <div className="graph-star-rating-body">
            {
                productDetail === null ?
                    <>
                        <div className="rating-list">
                            
                            <div className="rating-list-left font-weight-bold ">5 Star</div>
                            <div className="rating-list-center">
                                <div className="progress">
                                    <div role="progressbar" className="progress-bar bg-info" aria-valuenow={data?.rating?.star5} aria-valuemin={0} aria-valuemax={100} style={{ width: `${data?.rating?.star5}%` }} />
                                </div>
                            </div>
                            <div className="rating-list-right font-weight-bold ">{(data?.rating?.star5)} %</div>
                        </div>
                        <div className="rating-list">
                            <div className="rating-list-left font-weight-bold ">4 Star</div>
                            <div className="rating-list-center">
                                <div className="progress">
                                    <div role="progressbar" className="progress-bar bg-info" aria-valuenow={data?.rating?.star4} aria-valuemin={0} aria-valuemax={100} style={{ width: `${data?.rating?.star4}%` }} />
                                </div>
                            </div>
                            <div className="rating-list-right font-weight-bold ">{(data?.rating?.star4 ) } %</div>
                        </div>
                        <div className="rating-list">
                            <div className="rating-list-left font-weight-bold ">3 Star</div>
                            <div className="rating-list-center">
                                <div className="progress">
                                    <div role="progressbar" className="progress-bar bg-info" aria-valuenow={data?.rating?.star3} aria-valuemin={0} aria-valuemax={100} style={{ width: `${data?.rating?.star3}%` }} />
                                </div>
                            </div>
                            <div className="rating-list-right font-weight-bold ">{(data?.rating?.star3 ) } %</div>
                        </div>
                        <div className="rating-list">
                            <div className="rating-list-left font-weight-bold ">2 Star</div>
                            <div className="rating-list-center">
                                <div className="progress">
                                    <div role="progressbar" className="progress-bar bg-info" aria-valuenow={data?.rating?.star2} aria-valuemin={0} aria-valuemax={100} style={{ width: `${data?.rating?.star2}%` }} />
                                </div>
                            </div>
                            <div className="rating-list-right font-weight-bold ">{(data?.rating?.star2 ) } %</div>
                        </div>
                        <div className="rating-list">
                            <div className="rating-list-left font-weight-bold ">1 Star</div>
                            <div className="rating-list-center">
                                <div className="progress">
                                    <div role="progressbar" className="progress-bar bg-info" aria-valuenow={data?.rating?.star1} aria-valuemin={0} aria-valuemax={100} style={{ width: `${data?.rating?.star1}%` }} />
                                </div>
                            </div>
                            <div className="rating-list-right font-weight-bold ">{(data?.rating?.star1 ) } %</div>
                        </div>
                    </>
                    :
                    <>
                        <div className="rating-list">
                            <div className="rating-list-left font-weight-bold ">5 Star</div>
                            <div className="rating-list-center">
                                <div className="progress">
                                    <div role="progressbar" className="progress-bar bg-info" aria-valuenow={productDetail?.rating?.star5} aria-valuemin={0} aria-valuemax={100} style={{ width: `${productDetail?.rating?.star5 !== undefined ? productDetail?.rating?.star5 : '0'}%` }} />
                                </div>
                            </div>
                            <div className="rating-list-right font-weight-bold ">{productDetail?.rating?.star5 !== undefined ? (productDetail?.rating?.star5 / 100) * 100 : '0'} %</div>
                        </div>
                        <div className="rating-list">
                            <div className="rating-list-left font-weight-bold ">4 Star</div>
                            <div className="rating-list-center">
                                <div className="progress">
                                    <div role="progressbar" className="progress-bar bg-info" aria-valuenow={productDetail?.rating?.star4} aria-valuemin={0} aria-valuemax={100} style={{ width: `${productDetail?.rating?.star4 !== undefined ? productDetail?.rating?.star4 : '0'}%` }} />
                                </div>
                            </div>
                            <div className="rating-list-right font-weight-bold ">{productDetail?.rating?.star4 !== undefined ? (productDetail?.rating?.star4 / 100) * 100 : '0'} %</div>
                        </div>
                        <div className="rating-list">
                            <div className="rating-list-left font-weight-bold ">3 Star</div>
                            <div className="rating-list-center">
                                <div className="progress">
                                    <div role="progressbar" className="progress-bar bg-info" aria-valuenow={productDetail?.rating?.star3} aria-valuemin={0} aria-valuemax={100} style={{ width: `${productDetail?.rating?.star3 !== undefined ? productDetail?.rating?.star3 : '0'}%` }} />
                                </div>
                            </div>
                            <div className="rating-list-right font-weight-bold ">{productDetail?.rating?.star3 !== undefined ? (productDetail?.rating?.star3 / 100) * 100 : '0'} %</div>
                        </div>
                        <div className="rating-list">
                            <div className="rating-list-left font-weight-bold ">2 Star</div>
                            <div className="rating-list-center">
                                <div className="progress">
                                    <div role="progressbar" className="progress-bar bg-info" aria-valuenow={productDetail?.rating?.star2} aria-valuemin={0} aria-valuemax={100} style={{ width: `${productDetail?.rating?.star2 !== undefined ? productDetail?.rating?.star2 : '0'}%` }} />
                                </div>
                            </div>
                            <div className="rating-list-right font-weight-bold ">{productDetail?.rating?.star2 !== undefined ? (productDetail?.rating?.star2 / 100) * 100 : '0'} %</div>
                        </div>
                        <div className="rating-list">
                            <div className="rating-list-left font-weight-bold ">1 Star</div>
                            <div className="rating-list-center">
                                <div className="progress">
                                    <div role="progressbar" className="progress-bar bg-info" aria-valuenow={productDetail?.rating?.star1} aria-valuemin={0} aria-valuemax={100} style={{ width: `${productDetail?.rating?.star1 !== undefined ? productDetail?.rating?.star1 : '0'}%` }} />
                                </div>
                            </div>
                            <div className="rating-list-right font-weight-bold ">{productDetail?.rating?.star1 !== undefined ? (productDetail?.rating?.star1 / 100) * 100 : '0'} %</div>
                        </div>
                    </>
            }
        </div>
    )
}

export default RatingReviews;
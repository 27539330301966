import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';

const CategoryBar = ({ loading2, categories, activeCategory, setActiveCategory, getMenuByCategory }) => {
    return (
        <div className="container" style={{marginTop:'23px'}}>
            <div className="p-3 bg-primary bg-primary mt-n3 rounded position-relative">
                <div className="d-flex align-items-center">
                    {
                        loading2 ? <CircularProgress style={{ color: 'white' }} size='2rem' />
                            :
                            <ul className='d-flex p-0 m-0' style={{ listStyleType: 'none' }}>
                                {
                                    categories?.length > 0 ? categories?.map((item, index) => {
                                        return <li
                                            className={
                                                activeCategory === item._id
                                                    ? 'text-capitalize bg-white text-center px-2 mr-2'
                                                    : 'text-capitalize text-center text-white px-2 mr-2'
                                            }
                                            key={index}
                                            onClick={() => {
                                                setActiveCategory(item?._id);
                                                getMenuByCategory(item?._id, window.location.search.split('=')[1]);
                                            }}
                                            style={{
                                                padding: '8px 0px',
                                                cursor: 'pointer',
                                                minWidth: '70px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                fontSize:'17px',
                                                borderRadius:'5px'
                                            }}>
                                            {item?.name}
                                        </li>
                                    })
                                        : <span className='text-white'>No Category Available</span>
                                }
                            </ul>
                    }
                </div>
            </div>
        </div>
    )
}

export default CategoryBar
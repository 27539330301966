import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import "../Components/Home/Filter.css";
import Aos from "aos";
import { FaStar } from "react-icons/fa";
import "aos/dist/aos.css";
import { URLForImages } from "../Apis/Apis";
import ProductDetail from "../Components/ProductDetail/ProductDetail";
// import { useParams } from "react-router-dom";
// import { Category } from "@mui/icons-material";
SwiperCore.use([Navigation]);

const Slider = ({ relatedItems, setChangeLocation }) => {
  const navigate = useNavigate();
  // const params = useParams();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [srch] = useSearchParams();
  console.log('shan',)

  useEffect(() => {
    Aos.init({ duration: 700 });
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log("relatedItems", relatedItems);

  return (
    <div className="container related mt-5">
      <h2 data-aos="fade-left" className="mb-3 ">
        Related Items
      </h2>
      <div style={{ position: "relative" }}>
        {loading === false ? (
        
          <Swiper
            style={{ position: "unset" }}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            navigation
            loop={true}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 5,
              },
              480: {
                slidesPerView: 3,
                spaceBetween: 5,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 5,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 5,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 5,
              },
            }}
          >
            {relatedItems?.length > 0
              ? relatedItems
                  ?.filter((item) => item._id !== srch.get("id")).filter(active => active.status && !active.deleted_at )
                  
                  .map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div
                          className="cat-item px-1 py-3"
                          style={{ width: "auto" }}
                        >
                          <Link
                            to={`/product?id=${item?._id}`}
                            // onClick={() => navigate(`/product?id=${item?._id}`)}
                            onClick={setChangeLocation(window.location.href)}
                            className="bg-white rounded d-block p-2 text-center shadow-sm"
                            //   onClick={() => navigate(`trending?id=${item?._id}`)}
                            tabIndex={0}
                          >
                            <img
                              style={{ width: "170px", height: "150px" }}
                              alt="#"
                              src={
                                item?.file_id
                                  ? `${URLForImages}/${item?.file_id?.file_path}`
                                  : "img/dummy.png"
                              }
                              className="img-fluid mb-2"
                            />

                            <p className="m-0 font-size-large text-capitalize text-width">
                              {item?.name}
                            </p>
                            <p className="m-0 font-size-large text-capitalize text-width">
                              Price: {item?.price}
                            </p>
                            {/* <p className="m-0 font-size-large text-capitalize text-width" style={{textAlign:'left'}}>
                                                {item?.description}
                                                </p> */}
                            <p
                              className="m-0 font-size-large text-capitalize text-width d-flex "
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              Ratings : {item?.totalRatings?.toFixed(1)}
                              <FaStar style={{ color: "goldenrod" }} />
                            </p>
                          </Link>
                        </div>
                      </SwiperSlide>
                    );
                  })
              : [
                  "Pizza",
                  "Burger",
                  "Shuwarma",
                  "Breakfast",
                  "Fries",
                  "Coffee",
                  "Sandwich",
                  "Cold Drink",
                  "Coffee",
                  "Sandwich",
                  "Cold Drink",
                ].map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div
                        className="cat-item px-1 py-3 "
                        style={{ width: 141 }}
                      >
                        <a
                          className="bg-white rounded d-block p-2 text-center shadow-sm"
                          tabIndex={-1}
                        >
                          <img
                            className="img-fluid mb-2"
                            src="img/landing1.png"
                          />
                          <p className="m-0 small">{item}</p>
                        </a>
                      </div>
                    </SwiperSlide>
                  );
                })}
          </Swiper>
        ) : (
          <div className="d-flex justify-content-center w-100 my-2">
            <CircularProgress size="2rem" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Slider;

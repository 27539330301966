import React from 'react';

const ForgetPassword = () => {
    return (
        <div className="osahan-signup login-page">
            <video loop autoPlay muted id="vid">
                <source src="img/bg.mp4" type="video/mp4" />
                <source src="img/bg.mp4" type="video/ogg" />
                Your browser does not support the video tag.
            </video>
            <div className="d-flex align-items-center justify-content-center flex-column vh-100">
                <div className="px-5 col-md-6 ml-auto">
                    <div className="px-5 col-10 mx-auto">
                        <h2>Forgot password</h2>
                        <p>Enter your email address below and we'll send you an email with instructions on how to change your password</p>
                        <form action="login.html" className="mt-5 mb-4">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Email</label>
                                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                            </div>
                            <button className="btn btn-primary btn-lg btn-block">Send</button>
                        </form>
                    </div>
                    <div className="new-acc d-flex align-items-center justify-content-center">
                        <a href="login.html">
                            <p className="text-center m-0">Already an account? Sign in</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgetPassword;
import React , {useEffect} from 'react';
import Brows from './Brows';
import Filter from './Filter';
import Offers from './Offers';
import Trending from './Trending';
import Header from '../Header/Header'


const Home = () => {
    
    useEffect(() => {
        window.scrollTo(0,0)
         }, [])
    return (
        <div className="osahan-home-page">
            <Offers/>
        
            <Filter/>
            <Trending/>
        </div>
    )
}

export default Home;
import React, { useState } from 'react';
import StarRatings from 'react-star-ratings';
import CircularProgress from '@mui/material/CircularProgress';
import { AlertHandler } from '../../Common/AlertHandler';
import axios from 'axios';
import { RateProductAPI } from '../../Apis/Apis';
import { useSelector } from 'react-redux';

const ProductComment = ({ data, getProduct }) => {
    const user = useSelector(state => state.Auth.user);

    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');
    const [loading1, setLoading1] = useState(false);

    const changeRating = (newRating, name) => {
        setRating(newRating);
    }

    const sendRatingData = async () => {
        if (!user?._id) return AlertHandler('Please login to comment', 'error');
        if (rating === 0) return AlertHandler('Please do rating', 'error');
        if (comment === '') return AlertHandler('Please write message', 'error');
        setLoading1(true);
        try {
            const obj = {
                ratings: rating,
                comment: comment,
                user_id: user?._id,
                restaurant_id: data?.restaurant_id?._id,
                branch_id: data?.branch_id?._id,
                item_id: data?._id
            }
            const res = await axios.post(RateProductAPI, obj);
            if (res?.data?.success === true) {
                setLoading1(false);
                getProduct(data?._id);
                AlertHandler(res?.data?.message, 'success');
                setRating(0);
                setComment('');
            }
        } catch (error) {
            setLoading1(false);
            console.log(error);
        }
    }

    return (
        <div className="bg-white p-3 rating-review-select-page rounded shadow-sm">
            <h6 className="mb-3">Leave Comment {data !== undefined ? `${data?.name}` : null}</h6>
            <div className="d-flex align-items-center mb-3">
                <p className="m-0 small">Rate the Places</p>
                <div className="star-rating ml-auto">
                    <StarRatings
                        rating={rating}
                        starRatedColor="#ffc107"
                        starHoverColor='#ffc107'
                        changeRating={changeRating}
                        numberOfStars={5}
                        name='rating'
                        starDimension="20px"
                        starSpacing="0px"
                    />
                </div>
            </div>
            <form>
                <div className="form-group"><label className="form-label small">Your Comment</label><textarea value={comment} onChange={(e) => setComment(e.target.value)} className="form-control" defaultValue={""} /></div>
                <div className="form-group mb-0">
                    <button onClick={sendRatingData} type="button" className="btn btn-primary btn-block">
                        {loading1 ? <CircularProgress style={{ color: 'white' }} size='2rem' /> : 'Submit Comment'}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default ProductComment
import React from 'react';
import { URLForImages } from '../../Apis/Apis';
import { getDistanceFromLatLonInKm } from '../../func';

const Branch = ({ item, location }) => {
    return (
        <div className="d-flex align-items-center list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
            <div className="list-card-image">
                <div className="star position-absolute"><span className="badge badge-success " style={{fontSize:'12px'}}><i className="feather-star mr-1"></i>
                    {item?.totalRatings ? item?.totalRatings.toFixed(1) : 0}({item?.ratings?.peopleRated ? item?.ratings?.peopleRated : 0})
                </span></div>
                <a>
                    <img alt="#" src={item?.file_id !== null ? `${URLForImages}/${item?.file_id?.file_path}` : "img/trending3.png"} className="img-fluid item-img w-100" />
                </a>
            </div>
            <div className="p-3 position-relative">
                <div className="list-card-body">
                    <h6 className="mb-1"><a className="text-black">{item?.name}
                    </a>
                    </h6>
                    <p className="text-gray mb-3">{item?.address}</p>
                    <p className="text-gray mb-3 time">
                        <span className="bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2 mb-5">
                            <i className="feather-map-pin"></i>
                            {
                                location?.coords !== null &&
                                getDistanceFromLatLonInKm(location?.coords?.latitude, location?.coords?.longitude, item?.latitude, item?.longitude)
                            }
                           {" "} Km
                        </span>
                    </p>
                </div>
                {/* <div className="list-card-badge">
                    <span className="badge badge-danger">Status</span> <small>{item?.status === 0 ? 'Active' : 'InActive'}</small>
                </div> */}
            </div>
        </div >
    )
}

export default Branch
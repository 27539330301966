import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    AmenitiesFilterValues:[]
}

const AmenitiesSlice = createSlice({
    name: 'ementities',
    initialState,
    reducers: {
        setAmenty(state, action) {
            const { AmenitiesFilterValues } = action.payload;
            console.log("SET AMENTIY",action.payload)
            state.AmenitiesFilterValues = AmenitiesFilterValues;    
            },
    }
});

export const { setAmenty } = AmenitiesSlice.actions;
export default AmenitiesSlice.reducer;
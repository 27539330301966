import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();
    const setting = useSelector(state => state.Setting.setting);

    return (
        <div>
        {/* Footer */}
        <div className="osahan-menu-fotter fixed-bottom bg-white px-3 py-2 text-center d-none">
            <div className="row">
                <div className="col selected">
                    <a href="home.html" className="text-danger small font-weight-bold text-decoration-none">
                        <p className="h4 m-0"><i className="feather-home text-danger" /></p>
                        Home
                    </a>
                </div>
                <div className="col">
                    <a href="most_popular.html" className="text-dark small font-weight-bold text-decoration-none">
                        <p className="h4 m-0"><i className="feather-map-pin" /></p>
                        Trending
                    </a>
                </div>
                <div className="col bg-white rounded-circle mt-n4 px-3 py-2">
                    <div className="bg-danger rounded-circle mt-n0 shadow">
                        <a href="checkout.html" className="text-white small font-weight-bold text-decoration-none">
                            <i className="feather-shopping-cart" />
                        </a>
                    </div>
                </div>
                <div className="col">
                    <a href="favorites.html" className="text-dark small font-weight-bold text-decoration-none">
                        <p className="h4 m-0"><i className="feather-heart" /></p>
                        Favorites
                    </a>
                </div>
                <div className="col">
                    <a href="profile.html" className="text-dark small font-weight-bold text-decoration-none">
                        <p className="h4 m-0"><i className="feather-user" /></p>
                        Profile
                    </a>
                </div>
            </div>
        </div>
        {/* footer */}
        <footer className="section-footer border-top bg-dark">
            <div className="container">
                <section className="footer-top padding-y py-5">
                    <div className="row">
                        <aside className="col-md-4 footer-about">
                            <article className="d-flex pb-3">
                                {/* <div><img alt="#" src="img/logo_web.png" className="logo-footer mr-3" /></div> */}
                                <div>
                                    <h6 className="title text-white">About Us</h6>
                                    <p className="text-muted">Our main goal is to satisfy our customers as per their demand and we are always available for our customers</p>
                                    <div className="d-flex align-items-center">
                                        <a className="btn btn-icon btn-outline-light mr-1 btn-sm" title="Facebook" target="_blank" href="#"><i className="feather-facebook" /></a>
                                        <a className="btn btn-icon btn-outline-light mr-1 btn-sm" title="Instagram" target="_blank" href="#"><i className="feather-instagram" /></a>
                                        <a className="btn btn-icon btn-outline-light mr-1 btn-sm" title="Youtube" target="_blank" href="#"><i className="feather-youtube" /></a>
                                        <a className="btn btn-icon btn-outline-light mr-1 btn-sm" title="Twitter" target="_blank" href="#"><i className="feather-twitter" /></a>
                                    </div>
                                </div>
                            </article>
                        </aside>
                        {/* <aside className="col-sm-3 col-md-2 text-white">
                            <h6 className="title">Error Pages</h6>
                            <ul className="list-unstyled hov_footer">
                                <li> <a href="not-found.html" className="text-muted">Not found</a></li>
                                <li> <a href="maintence.html" className="text-muted">Maintence</a></li>
                                <li> <a href="coming-soon.html" className="text-muted">Coming Soon</a></li>
                            </ul>
                        </aside> */}
                        <aside className="col-sm-3 col-md-2 text-white offset-1">
                            <h6 className="title">Services</h6>
                            <ul className="list-unstyled hov_footer">
                                <li> <a href="faq.html" className="text-muted">Delivery Support</a></li>
                                <li> <a href="contact-us.html" className="text-muted">Contact Us</a></li>
                                <li> <a href="terms.html" className="text-muted">Terms of use</a></li>
                                <li> <a href="privacy.html" className="text-muted">Privacy policy</a></li>
                            </ul>
                        </aside>
                        <aside className="col-sm-3  col-md-2 text-white">
                            <h6 className="title">For users</h6>
                            <ul className="list-unstyled hov_footer">
                                <li> <a href="login.html" className="text-muted"> User Login </a></li>
                                <li> <a href="signup.html" className="text-muted"> User register </a></li>
                                <li> <a href="forgot_password.html" className="text-muted"> Forgot Password </a></li>
                                <li> <a href="profile.html" className="text-muted"> Account Setting </a></li>
                            </ul>
                        </aside>
                        <aside className="col-sm-3  col-md-2 text-white">
                            <h6 className="title">More Pages</h6>
                            <ul className="list-unstyled hov_footer">
                                <li> <a href="trending.html" className="text-muted"> Trending </a></li>
                                <li> <a href="most_popular.html" className="text-muted"> Most popular </a></li>
                                <li> <a href="restaurant.html" className="text-muted"> Restaurant Details </a></li>
                                <li> <a href="favorites.html" className="text-muted"> Favorites </a></li>
                            </ul>
                        </aside>
                    </div>
                    {/* row.// */}
                </section>
                {/* footer-top.// */}
                {/* <section className="footer-center border-top padding-y py-5">
                    <h6 className="title text-white">Countries</h6>
                    <div className="row">
                        <aside className="col-sm-2 col-md-2 text-white">
                            <ul className="list-unstyled hov_footer">
                                <li> <a href="#" className="text-muted">India</a></li>
                                <li> <a href="#" className="text-muted">Indonesia</a></li>
                                <li> <a href="#" className="text-muted">Ireland</a></li>
                                <li> <a href="#" className="text-muted">Italy</a></li>
                                <li> <a href="#" className="text-muted">Lebanon</a></li>
                            </ul>
                        </aside>
                        <aside className="col-sm-2 col-md-2 text-white">
                            <ul className="list-unstyled hov_footer">
                                <li> <a href="#" className="text-muted">Malaysia</a></li>
                                <li> <a href="#" className="text-muted">New Zealand</a></li>
                                <li> <a href="#" className="text-muted">Philippines</a></li>
                                <li> <a href="#" className="text-muted">Poland</a></li>
                                <li> <a href="#" className="text-muted">Portugal</a></li>
                            </ul>
                        </aside>
                        <aside className="col-sm-2 col-md-2 text-white">
                            <ul className="list-unstyled hov_footer">
                                <li> <a href="#" className="text-muted">Australia</a></li>
                                <li> <a href="#" className="text-muted">Brasil</a></li>
                                <li> <a href="#" className="text-muted">Canada</a></li>
                                <li> <a href="#" className="text-muted">Chile</a></li>
                                <li> <a href="#" className="text-muted">Czech Republic</a></li>
                            </ul>
                        </aside>
                        <aside className="col-sm-2 col-md-2 text-white">
                            <ul className="list-unstyled hov_footer">
                                <li> <a href="#" className="text-muted">Turkey</a></li>
                                <li> <a href="#" className="text-muted">UAE</a></li>
                                <li> <a href="#" className="text-muted">United Kingdom</a></li>
                                <li> <a href="#" className="text-muted">United States</a></li>
                                <li> <a href="#" className="text-muted">Sri Lanka</a></li>
                            </ul>
                        </aside>
                        <aside className="col-sm-2 col-md-2 text-white">
                            <ul className="list-unstyled hov_footer">
                                <li> <a href="#" className="text-muted">Qatar</a></li>
                                <li> <a href="#" className="text-muted">Singapore</a></li>
                                <li> <a href="#" className="text-muted">Slovakia</a></li>
                                <li> <a href="#" className="text-muted">South Africa</a></li>
                                <li> <a href="#" className="text-muted">Green Land</a></li>
                            </ul>
                        </aside>
                        <aside className="col-sm-2 col-md-2 text-white">
                            <ul className="list-unstyled hov_footer">
                                <li> <a href="#" className="text-muted">Pakistan</a></li>
                                <li> <a href="#" className="text-muted">Bangladesh</a></li>
                                <li> <a href="#" className="text-muted">Bhutaan</a></li>
                                <li> <a href="#" className="text-muted">Nepal</a></li>
                            </ul>
                        </aside>
                    </div>
                   
                </section> */}
            </div>
            {/* //container */}
            <section className="footer-copyright border-top py-3 bg-black">
                <div className="container d-flex align-items-center">
                    <p className="mb-0 text-color"> © SKYLINX TECHNOLOGIES </p>
                    <p className="text-muted mb-0 ml-auto d-flex align-items-center">
                        <a href="#" className="d-block"><img alt="#" src="img/appstore.png" height={40} /></a>
                        <a href="#" className="d-block ml-3"><img alt="#" src="img/playmarket.png" height={40} /></a>
                    </p>
                </div>
            </section>
        </footer>
        <nav id="main-nav">
            <ul className="second-nav">
                <li><a href="home.html"><i className="feather-home mr-2" /> Homepage</a></li>
                <li><a href="my_order.html"><i className="feather-list mr-2" /> My Orders</a></li>
                <li>
                    <a href="#"><i className="feather-edit-2 mr-2" /> Authentication</a>
                    <ul>
                        <li><a href="login.html">Login</a></li>
                        <li><a href="signup.html">Register</a></li>
                        <li><a href="forgot_password.html">Forgot Password</a></li>
                        <li><a href="verification.html">Verification</a></li>
                        <li><a href="location.html">Location</a></li>
                    </ul>
                </li>
                <li><a href="favorites.html"><i className="feather-heart mr-2" /> Favorites</a></li>
                <li><a href="trending.html"><i className="feather-trending-up mr-2" /> Trending</a></li>
                <li><a href="most_popular.html"><i className="feather-award mr-2" /> Most Popular</a></li>
                <li><a href="restaurant.html"><i className="feather-paperclip mr-2" /> Restaurant Detail</a></li>
                <li><a href="checkout.html"><i className="feather-list mr-2" /> Checkout</a></li>
                <li><a href="successful.html"><i className="feather-check-circle mr-2" /> Successful</a></li>
                <li><a href="map.html"><i className="feather-map-pin mr-2" /> Live Map</a></li>
                <li>
                    <a href="#"><i className="feather-user mr-2" /> Profile</a>
                    <ul>
                        <li><a href="profile.html">Profile</a></li>
                        <li><a href="favorites.html">Delivery support</a></li>
                        <li><a href="contact-us.html">Contact Us</a></li>
                        <li><a href="terms.html">Terms of use</a></li>
                        <li><a href="privacy.html">Privacy &amp; Policy</a></li>
                    </ul>
                </li>
                <li>
                    <a href="#"><i className="feather-alert-triangle mr-2" /> Error</a>
                    <ul>
                        <li><a href="not-found.html">Not Found</a>
                        </li><li><a href="maintence.html"> Maintence</a>
                        </li><li><a href="coming-soon.html">Coming Soon</a>
                        </li></ul>
                </li>
                <li>
                    <a href="#"><i className="feather-link mr-2" /> Navigation Link Example</a>
                    <ul>
                        <li>
                            <a href="#">Link Example 1</a>
                            <ul>
                                <li>
                                    <a href="#">Link Example 1.1</a>
                                    <ul>
                                        <li><a href="#">Link</a></li>
                                        <li><a href="#">Link</a></li>
                                        <li><a href="#">Link</a></li>
                                        <li><a href="#">Link</a></li>
                                        <li><a href="#">Link</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="#">Link Example 1.2</a>
                                    <ul>
                                        <li><a href="#">Link</a></li>
                                        <li><a href="#">Link</a></li>
                                        <li><a href="#">Link</a></li>
                                        <li><a href="#">Link</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li><a href="#">Link Example 2</a></li>
                        <li><a href="#">Link Example 3</a></li>
                        <li><a href="#">Link Example 4</a></li>
                        <li data-nav-custom-content>
                            <div className="custom-message">
                                You can add any custom content to your navigation items. This text is just an example.
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="bottom-nav">
                <li className="email">
                    <a className="text-danger" href="home.html">
                        <p className="h5 m-0"><i className="feather-home text-danger" /></p>
                        Home
                    </a>
                </li>
                <li className="github">
                    <a href="faq.html">
                        <p className="h5 m-0"><i className="feather-message-circle" /></p>
                        FAQ
                    </a>
                </li>
                <li className="ko-fi">
                    <a href="contact-us.html">
                        <p className="h5 m-0"><i className="feather-phone" /></p>
                        Help
                    </a>
                </li>
            </ul>
        </nav>
        <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Filter</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body p-0">
                        <div className="osahan-filter">
                            <div className="filter">
                                {/* SORT BY */}
                                <div className="p-3 bg-light border-bottom">
                                    <h6 className="m-0">SORT BY</h6>
                                </div>
                                <div className="custom-control border-bottom px-0  custom-radio">
                                    <input type="radio" id="customRadio1f" name="location" className="custom-control-input" defaultChecked />
                                    <label className="custom-control-label py-3 w-100 px-3" htmlFor="customRadio1f">Top
                                        Rated</label>
                                </div>
                                <div className="custom-control border-bottom px-0  custom-radio">
                                    <input type="radio" id="customRadio2f" name="location" className="custom-control-input" />
                                    <label className="custom-control-label py-3 w-100 px-3" htmlFor="customRadio2f">Nearest
                                        Me</label>
                                </div>
                                <div className="custom-control border-bottom px-0  custom-radio">
                                    <input type="radio" id="customRadio3f" name="location" className="custom-control-input" />
                                    <label className="custom-control-label py-3 w-100 px-3" htmlFor="customRadio3f">Cost High to
                                        Low</label>
                                </div>
                                <div className="custom-control border-bottom px-0  custom-radio">
                                    <input type="radio" id="customRadio4f" name="location" className="custom-control-input" />
                                    <label className="custom-control-label py-3 w-100 px-3" htmlFor="customRadio4f">Cost Low to
                                        High</label>
                                </div>
                                <div className="custom-control border-bottom px-0  custom-radio">
                                    <input type="radio" id="customRadio5f" name="location" className="custom-control-input" />
                                    <label className="custom-control-label py-3 w-100 px-3" htmlFor="customRadio5f">Most
                                        Popular</label>
                                </div>
                                {/* Filter */}
                                <div className="p-3 bg-light border-bottom">
                                    <h6 className="m-0">FILTER</h6>
                                </div>
                                <div className="custom-control border-bottom px-0  custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="defaultCheck1" defaultChecked />
                                    <label className="custom-control-label py-3 w-100 px-3" htmlFor="defaultCheck1">Open Now</label>
                                </div>
                                <div className="custom-control border-bottom px-0  custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="defaultCheck2" />
                                    <label className="custom-control-label py-3 w-100 px-3" htmlFor="defaultCheck2">Credit
                                        Cards</label>
                                </div>
                                <div className="custom-control border-bottom px-0  custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="defaultCheck3" />
                                    <label className="custom-control-label py-3 w-100 px-3" htmlFor="defaultCheck3">Alcohol
                                        Served</label>
                                </div>
                                {/* Filter */}
                                <div className="p-3 bg-light border-bottom">
                                    <h6 className="m-0">ADDITIONAL FILTERS</h6>
                                </div>
                                <div className="px-3 pt-3">
                                    <input type="range" className="custom-range" min={0} max={100} name="minmax" />
                                    <div className="form-row">
                                        <div className="form-group col-6">
                                            <label>Min</label>
                                            <input className="form-control" placeholder="$0" type="number" />
                                        </div>
                                        <div className="form-group text-right col-6">
                                            <label>Max</label>
                                            <input className="form-control" placeholder="$1,0000" type="number" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer p-0 border-0">
                        <div className="col-6 m-0 p-0">
                            <button type="button" className="btn border-top btn-lg btn-block" data-dismiss="modal">Close</button>
                        </div>
                        <div className="col-6 m-0 p-0">
                            <button type="button" className="btn btn-primary btn-lg btn-block">Apply</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Footer;
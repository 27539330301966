import React, { useState, useEffect } from "react";
import { getDistanceFromLatLonInKm } from "../../func";
import { useNavigate, Link } from "react-router-dom";
import { URLForImages, favouriteProductAPI } from "../../Apis/Apis";
import { useSelector } from "react-redux";
import axios from "axios";
import { AlertHandler } from "../../Common/AlertHandler";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Aos from "aos";
import { Skeleton } from "@mui/material";
import "aos/dist/aos.css";
import love from '../love.svg'


const Sales = ({ productList, setProductList }) => {
  const [salepagedata, setsalepagedata] = useState('12')
  const navigate = useNavigate();
  const location = useSelector((state) => state.Location);
  const user = useSelector((state) => state.Auth.user);

  const [ids, setIds] = useState([]);
  const win = window.sessionStorage;
  useEffect(() => {
    Aos.init({ duration: 700 });
  }, []);

  const favouriteProduct = async (data) => {
    if (user?._id) {
      let obj = { item: data?._id, user: user?._id };
      try {
        const { data } = await axios.post(favouriteProductAPI, obj);

        if (data) {
          console.log(data);
          const _newArray = productList?.map((item) => {
            if (item._id === data?._itemUpdate?._id) {
              let newObject = Object.assign({}, item);
              newObject.favourite_by_users =
                data?._itemUpdate.favourite_by_users;

              return newObject;
            } else {
              return item;
            }
          });
          setProductList(_newArray);
          AlertHandler(data?.message, "success");
        }
      } catch (error) {
        console.log(error);
      }
    } else if (!user?._id) {
      const _sessionArray = JSON.parse(win.getItem("p_ids"));
      if (_sessionArray === null || _sessionArray.length === 0) {
        const _array = [];
        _array.push(data?._id);
        win.setItem("p_ids", JSON.stringify(_array));
        setIds([...ids, data?._id]);
        return AlertHandler("Add to Favourite", "success");
      }
      if (_sessionArray?.length > 0) {
        if (_sessionArray.includes(data?._id)) {
          const findIndex = _sessionArray.findIndex(
            (item) => item === data?._id
          );
          _sessionArray.splice(findIndex, 1);
          AlertHandler("Remove from Favourite", "success");
          setIds(_sessionArray);
          return win.setItem("p_ids", JSON.stringify(_sessionArray));
        } else if (!_sessionArray.includes(data?._id)) {
          _sessionArray.push(data?._id);
          win.setItem("p_ids", JSON.stringify(_sessionArray));
          setIds([...ids, data?._id]);
          return AlertHandler("Add to Favourite", "success");
        }
      }
    }
  };

  return (
    <>
      <>
        <div className="pt-2 pb-3 title d-flex align-items-center">
          <h5 data-aos="fade-left" className="m-0 mb-3">
            Most Rated
          </h5>
        </div>
        <div className="most_sale">
          <div className="row mb-3">
            {
              productList?.length > 0 ? (
                productList?.slice(0,salepagedata).map((item, index) => {
                
                  return (
                  
                    <div
                      key={index}
                      className=" col-xs-6 col-sm-6  col-lg-4 mb-3"
                    > 
                      <div className="d-flex align-items-center list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                        <div className="list-card-image">
                          <div className="star position-absolute">
                            <span className="badge badge-success">
                              <i className="feather-star" />{" "}
                              {item?.totalRatings > 0
                                ? item?.totalRatings.toFixed(1)
                                : 0}{" "}
                              {/* (
                              {item?.ratings?.peopleRated
                                ? item?.ratings?.peopleRated
                                : 0}
                              ) */}
                            </span>
                          </div>
                          <div className="favourite-heart  position-absolute fav-icon-div">
                            <a onClick={() => favouriteProduct(item)}>
                              {user?._id ? (
                                <FavoriteBorderOutlinedIcon
                                  fontSize="small"
                                  style={{
                                    color:
                                      item?.favourite_by_users?.includes(
                                        user?._id
                                      ) && "red",
                                    strokeWidth: "3.5px",
                                  }}
                                />
                              ) : (
                                <FavoriteBorderOutlinedIcon
                                  fontSize="small"
                                  style={{
                                    color: ids?.includes(item?._id) && "red",
                                    strokeWidth: "3.5px",
                                  }}
                                />
                              )}
                            </a>
                          </div>
                          <div className="member-plan position-absolute">
                            <span className="badge badge-dark">Promoted</span>
                          </div>
                          <Link
                            to={`/product?id=${item?._id}`}
                          >
                            <img
                              src={
                                item?.file_id
                                  ? `${URLForImages}/${item?.file_id?.file_path}`
                                  : "img/landing1.png"
                              }
                              className="img-fluid item-img w-100"
                            />
                          </Link>
                        </div>
                        <div className="p-3 position-relative">
                          <div className="list-card-body">
                            <h6 className="mb-1">
                              <a
                                onClick={() =>
                                  navigate(
                                    `restaurant?id=${item?.restaurant_id?._id}`
                                  )
                                }
                                className="text-black text-capitalize"
                              >
                                {item?.name}
                              </a>
                            </h6>
                            <p className="text-gray  mb-0 mt-0 ">
                              {item?.description}
                            </p>
                            <p className="text-gray mt-1">
                              {" "}
                              Price : {item?.price} $
                            </p>
                            {/* <p >Our best selling items </p>
                                            <p className="p-font-size">50% OFF</p> */}
                            {location?.coords && (
                              <p className="text-gray mb-3 time mt-1">
                                <span className=" text-dark rounded-sm  pb-1 pt-1 pr-2">
                                  <i className="feather-map-pin mr-1" />
                                  {getDistanceFromLatLonInKm(
                                    location?.coords?.latitude,
                                    location?.coords?.longitude,
                                    item?.branch_id?.latitude,
                                    item?.branch_id?.longitude
                                  )}{" "}
                                  Km
                                </span>
                              </p>
                            )}
                          </div>
                          
                        </div>
                        
                      </div>
                      {/* <a className="text-center w-100 d-block mt-3 font-weight-bold" onClick={()=>setsalepagedata('100')}>See All Reviews</a> */}
                      
                    </div>

                  );

                })
               
              ) : (
                <div className="d-flex justify-content-between row">
                <div className="col-3">
                  <div style={{ width: "40%" }} className="">
                    {/* For variant="text", adjust the height via font-size */}
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={350} />

                    {/* For other variants, adjust the size with `width` and `height` */}
                    <Skeleton variant="circular" width={40} height={40}  className="mt-1" />
                    <Skeleton variant="rectangular" width={210} height={60} className="mt-1" />
                    <Skeleton variant="rounded" width={210} height={60} className="mt-1" />

                  </div>
                </div>
                <div className="col-3">
                  <div style={{ width: "40%" }} className="">
                    {/* For variant="text", adjust the height via font-size */}
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={350} />

                    {/* For other variants, adjust the size with `width` and `height` */}
                    <Skeleton variant="circular" width={40} height={40}  className="mt-1" />
                    <Skeleton variant="rectangular" width={210} height={60} className="mt-1" />
                    <Skeleton variant="rounded" width={210} height={60}  className="mt-1" />

                  </div>
                </div>

                <div className="col-3">
                  <div style={{ width: "40%" }} className="">
                    {/* For variant="text", adjust the height via font-size */}
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={350} />

                    {/* For other variants, adjust the size with `width` and `height` */}
                    <Skeleton variant="circular" width={40} height={40}  className="mt-1" />
                    <Skeleton variant="rectangular" width={210} height={60} className="mt-1" />
                    <Skeleton variant="rounded" width={210} height={60}   className="mt-1"/>

                  </div>
                </div>
              </div>
              )
              // [1, 2, 3, 4, 5, 6].map((item, index) => {
              //     return (
              //       <div key={index} className="col-md-4 mb-3">
              //         <div className="d-flex align-items-center list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
              //           <div className="list-card-image">
              //             <div className="star position-absolute">
              //               <span className="badge badge-success">
              //                 <i className="feather-star" /> 3 (2)
              //               </span>
              //             </div>
              //             <div className="favourite-heart text-danger position-absolute">
              //               <a href="#">
              //                 <i className="feather-heart" />
              //               </a>
              //             </div>
              //             <div className="member-plan position-absolute">
              //               <span className="badge badge-dark">Promoted</span>
              //             </div>
              //             <a>
              //               <img
              //                 alt="#"
              //                 src={"img/landing1.png"}
              //                 className="img-fluid item-img w-100"
              //               />
              //             </a>
              //           </div>
              //           <div className="p-3 position-relative">
              //             <div className="list-card-body">
              //               <h6 className="mb-1">
              //                 <a className="text-black text-capitalize">
              //                   Burger
              //                 </a>
              //               </h6>
              //               <p className="text-gray mb-3">
              //                 Lorem ipsum dolor sit amet consectetur adipisicing
              //                 elit. Maxime mollitia, molestiae quas vel sint
              //                 commodi repudiandae consequuntur voluptatum
              //                 laborum numquam blanditiis
              //               </p>
              //               {location?.coords && (
              //                 <p className="text-gray mb-3 time">
              //                   <span className="bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2">
              //                     <i className="feather-map-pin" />8 Km
              //                   </span>
              //                 </p>
              //               )}
              //             </div>
              //             <div className="list-card-badge">
              //               <span className="badge badge-danger">OFFER</span>{" "}
              //               <small>0% Off</small>
              //             </div>
              //           </div>
              //         </div>
              //       </div>
              //     );
              //   })
            }
          </div>
        </div>
      </>
    </>
  );
};

export default Sales;

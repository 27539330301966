import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    product: null,
    rating: null,
    startPrice: null,
    endPrice: null
}

const FilterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setFilter(state, action) {
            const { product, rating, startPrice, endPrice } = action.payload;
            state.product = product;
            state.rating = rating;
            state.startPrice = startPrice;
            state.endPrice = endPrice;
        },
        removeFilter(state, action) {
            state.product = null;
            state.rating = null;
            state.startPrice = null;
            state.endPrice = null;
        }
    }
});

export const { setFilter, removeFilter } = FilterSlice.actions;
export default FilterSlice.reducer;
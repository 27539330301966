import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  GetMenuCategoriesByRestaurantAPI,
  GetMenusByCategoryAPI,
  RateProductAPI,
  SingleRestaurantAPI,
  SingleRestaurantRatingAPI,
  URLForImages,
  GetProductByIdAPI,
  TrendingProductAPI,
} from "../../Apis/Apis";
import { AlertHandler } from "../../Common/AlertHandler";
import Design from "./Design";
import Branch from "./Branch";
import Menu from "./Menu";
import Ratings from "./Ratings";
import RatingReviews from "./Rating&Reviews";
import Comment from "./Comment";
import ProductComment from "./ProductComment";
import Related from "./Related";
import RestaurantHead from "./RestaurantHead";
import CategoryBar from "./CategoryBar";
import Aos from "aos";
import "aos/dist/aos.css";
SwiperCore.use([Navigation]);


const Restaurant = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.Auth.user);
  const location = useSelector((state) => state.Location);

  const [data, setData] = useState(null);
  const [ratingData, setRatingData] = useState([]);
  const [categories, setCategories] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const [categoryMenu, setCategoryMenu] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);

  const [itemRated, setItemRated] = useState(null);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [reload, setReload] = useState(true);
  const [menuDetail, setMenuDetail] = useState({
    status: false,
    products: [],
    menu: null,
  });
  const FilterData= ratingData.filter((item)=>item.deleted_at ==null)
   let avg= FilterData.reduce((total, next) => total + next.ratings, 0) / FilterData.length;

  const [productDetail, setProductDetail] = useState(null);
  useEffect(() => {
    Aos.init({ duration: 700 });
  }, []);

  useEffect(() => {
    getRestaurantData();
    getRestaurantRatingData();
    getRestaurantCategories();
  }, [reload]);

  const getProduct = async (id) => {
    try {
      const { data } = await axios.get(`${GetProductByIdAPI}/${id}`);
      if (data.success === true) {
        setProductDetail(data.product);
        setRatingData(data?.product?.ratings);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRestaurantRatingData = async () => {
    try {
      const { data } = await axios.get(
        `${SingleRestaurantRatingAPI}/${window.location.search.split("=")[1]}`
      );
      setRatingData(data.products.filter(active=> active.status &&  !active.deleted_at));
     

    } catch (error) {
      console.log(error);
    }
  };

  const getRestaurantData = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${SingleRestaurantAPI}/${window.location.search.split("=")[1]}`
      );
      setLoading(false);
      setData(data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getRestaurantCategories = async () => {
    setLoading2(true);
    try {
      const { data } = await axios.get(
        `${GetMenuCategoriesByRestaurantAPI}/${
          window.location.search.split("=")[1]
        }`
      );
      setLoading2(false);
      setCategories(data.categories);
      setActiveCategory(data.categories[0]._id);
      getMenuByCategory(
        data.categories[0]._id,
        window.location.search.split("=")[1]
      );
    } catch (error) {
      setLoading2(false);
      console.log(error);
    }
  };

  const changeRating = (newRating, name) => {
    setRating(newRating);
  };

  const sendRatingData = async () => {
    if (!user) return AlertHandler("Please login first", "error");
    if (itemRated === null)
      return AlertHandler("Please select item first", "error");
    if (rating === 0) return AlertHandler("Please do rating", "error");
    if (comment === "") return AlertHandler("Please write message", "error");
    setLoading1(true);
    const _find = data?.products?.find((item) => item?._id === itemRated);
    try {
      const obj = {
        ratings: rating,
        comment: comment,
        user_id: user?._id,
        restaurant_id: window.location.search.split("=")[1],
        branch_id: _find?.branch_id,
        item_id: itemRated,
      };
      const { data } = await axios.post(RateProductAPI, obj);
      if (data.success === true) {
        setLoading1(false);
        AlertHandler(data?.message, "success");
        setReload(!reload);
        setRating(0)
      }
    } catch (error) {
      setLoading1(false);
      console.log(error);
    }
  };

  const getMenuByCategory = async (category, restaurant) => {
    setLoading3(true);
    try {
      const { data } = await axios.post(GetMenusByCategoryAPI, {
        category,
        restaurant,
      });
      if (data.success === true) {
        setLoading3(false);
        setCategoryMenu(data.menus);
      }
    } catch (error) {
      setLoading3(false);
      console.log(error);
    }
  };

  const [related, setRelated] = useState({
    status: false,
    data: [],
  });

  const getProductsByName = async (value) => {
    try {
      const { data } = await axios.get(`${TrendingProductAPI}/${value}`);
      if (data.success === true) {
        const _filter = data?.products?.filter(
          (item) =>
            item?.restaurant_id?._id !== window.location.search.split("=")[1]
        );
        setRelated({
          status: true,
          data: _filter,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div style={{ minHeight: "calc(100vh - 268px)", cursor: "pointer" }}>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "1rem 0rem",
            }}
          >
            <CircularProgress size="2rem" />
          </div>
        ) : (
          <>
            <RestaurantHead data={data} URLForImages={URLForImages} />
            <CategoryBar
              loading2={loading2}
              categories={categories}
              activeCategory={activeCategory}
              setActiveCategory={setActiveCategory}
              getMenuByCategory={getMenuByCategory}
            />
            <div className="container">
              <div className="">
                <p
                  data-aos="fade-left"
                  className="font-weight-bold pt-4 mb-4 "
                  style={{ fontSize: "25px" }}
                >
                  FEATURED MENUS
                </p>
                {/* slider */}
                {categoryMenu?.length > 0 ? (
                  loading3 ? (
                    <CircularProgress size="2rem" />
                  ) : (
                    <div style={{ position: "relative" }}>
                      <Swiper
                        style={{ position: "unset" }}
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        navigation
                        loop={categoryMenu.length > 3 ? true : false}
                        breakpoints={{
                          0: {
                            slidesPerView: 1,
                            spaceBetween: 5,
                          },
                          480: {
                            slidesPerView: 1,
                            spaceBetween: 5,
                          },
                          768: {
                            slidesPerView: 3,
                            spaceBetween: 5,
                          },
                          1024: {
                            slidesPerView: 3,
                            spaceBetween: 15,
                          },
                          1280: {
                            slidesPerView: 3,
                            spaceBetween: 15,
                          },
                        }}
                      >
                        {categoryMenu?.map((item, index) => {
                          return (
                            <SwiperSlide>
                              <Design
                                item={item}
                                key={index}
                                setMenuDetail={setMenuDetail}
                              />
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                  )
                ) : (
                  <div className="text-center text-danger">
                    <h5>Sorry, No item(s) found</h5>
                  </div>
                )}
              </div>

              <div className="">
                <p
                  data-aos="fade-left"
                  className="font-weight-bold pt-4 mb-4"
                  style={{ fontSize: "25px" }}
                >
                  RESTAURANT BRANCHES
                </p>
                {/* slider */}
                {data?.branches?.length > 0 ? (
                  <div style={{ position: "relative" }}>
                    <Swiper
                      style={{ position: "unset" }}
                      modules={[Navigation, Pagination, Scrollbar, A11y]}
                      navigation
                      loop={data?.branches?.length > 3 ? true : false}
                      breakpoints={{
                        0: {
                          slidesPerView: 1,
                          spaceBetween: 5,
                        },
                        480: {
                          slidesPerView: 1,
                          spaceBetween: 5,
                        },
                        768: {
                          slidesPerView: 3,
                          spaceBetween: 5,
                        },
                        1024: {
                          slidesPerView: 3,
                          spaceBetween: 15,
                        },
                        1280: {
                          slidesPerView: 3,
                          spaceBetween: 15,
                        },
                      }}
                    >
                      {data?.branches?.map((item, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <Branch
                              item={item}
                              key={index}
                              location={location}
                            />
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                ) : (
                  <div className="text-center text-danger">
                    <h5>Sorry, No item(s) found</h5>
                  </div>
                )}
              </div>

              {/* Menu */}
              <div className="container position-relative">
                <div className="row">
                  <div className="col-md-12 pt-3">
                    <Menu
                      categoryMenu={categoryMenu}
                      menuDetail={menuDetail}
                      getProduct={getProduct}
                      getProductsByName={getProductsByName}
                    />
                    <div className="mb-3">
                      <div className="bg-white rounded p-3 mb-3 clearfix graph-star-rating rounded shadow-sm">
                        <h6
                          data-aos="fade-left"
                          className="mb-0 mb-1"
                          style={{ fontSize: "25px" }}
                        >
                          Ratings and Reviews
                        </h6>
                        <p
                          className="text-muted mb-4 mt-1 "
                          style={{ fontSize: "15px" }}
                        >
                          Rated {Math.round(FilterData.reduce((total, next) => total + next.ratings, 0) / FilterData.length)} out of 5
                        </p>
                        <RatingReviews
                          data={data}
                          productDetail={productDetail}
                          ratingData={ratingData}
                        />
                      </div>
                      <div className="bg-white p-3 mb-3 restaurant-detailed-ratings-and-reviews shadow-sm rounded">
                        <a
                          className="text-primary float-right"
                          href="#"
                          style={{ fontSize: "17px" }}
                        >
                          Latest Ratings
                        </a>
                        <h6
                          data-aos="fade-left"
                          className="mb-1"
                          style={{ fontSize: "25px" }}
                        >
                          All Ratings and Reviews
                        </h6>
                        <Ratings ratingData={ratingData}
                        />
                           
                      </div>
                      {productDetail === null ? (
                        <Comment
                          data={data}
                          setItemRated={setItemRated}
                          changeRating={changeRating}
                          sendRatingData={sendRatingData}
                          loading1={loading1}
                          setComment={setComment}
                          rating={rating}
                        />
                        
                      ) : (
                        <ProductComment
                          data={productDetail}
                          ratingData={ratingData}
                          setRatingData={setRatingData}
                          getProduct={getProduct}
                        />
                        
                      )
                      }
                      
                  
                    </div>
                    {related.status === true && (
                      <Related data={related?.data} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Restaurant;

import React, { useEffect, useState } from "react";
import {
  URLForImages,
  favouriteProductAPI,
  favouriteRestaurantAPI,
} from "../../Apis/Apis";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { AlertHandler } from "../../Common/AlertHandler";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { setLoad } from "../../Redux/LoadSlice";
import "./Popular.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { Skeleton } from "@mui/material";

const Popular = ({ productList, ids, setIds }) => {
  const user = useSelector((state) => state.Auth.user);
  const load = useSelector((state) => state.Load.load);
  const dispatch = useDispatch();
  const win = window.sessionStorage;
  const [_data, setData] = useState([]);
  useEffect(() => {
    Aos.init({ duration: 700 });
  }, []);

  // function changeImage() {
  //     if (document.getElementById("imgClickAndChange").src == "http://www.userinterfaceicons.com/80x80/minimize.png"){
  //         document.getElementById("imgClickAndChange").src = "http://www.userinterfaceicons.com/80x80/maximize.png";
  //     } else {
  //         document.getElementById("imgClickAndChange").src = "http://www.userinterfaceicons.com/80x80/minimize.png";
  //     }
  // }
  useEffect(() => {
    const _newProductList = productList.slice(0);
    const _setRating = _newProductList.map((item) => {
      if (item.totalRatings === null) {
        const obj = Object.assign({}, item);
        obj.totalRatings = 0;
        return obj;
      } else {
        return item;
      }
    });
    const _data = _setRating.sort((a, b) => b.totalRatings - a.totalRatings);

    setData(_data.filter((active) => active.status && !active.deleted_at));
    // console.log(_data.filter(active => active.status && !active.deleted_at ),'majid');
    // console.log(_data.resturants.filter(active=>active.status && !active.deleted_at));
  }, [productList, load]);

  const navigate = useNavigate();

  const favouriteRestaurant = async (data) => {
    if (user?._id) {
      let obj = { restaurant: data?._id, user: user?._id };
      try {
        const { data } = await axios.post(favouriteRestaurantAPI, obj);
        if (data) {
          const _newArray = _data?.map((item) => {
            if (item?._id === data?._itemUpdate?._id) {
              let newObject = Object.assign({}, item);
              newObject.favourite_by_users =
                data?._itemUpdate.favourite_by_users;
              return newObject;
            } else {
              return item;
            }
          });
          setData(_newArray);
          dispatch(setLoad(!load));
          AlertHandler(data?.message, "success");
        }
      } catch (error) {
        console.log(error);
      }
    } else if (!user?._id) {
      const _sessionArray = JSON.parse(win.getItem("ids"));
      if (_sessionArray === null || _sessionArray.length === 0) {
        const _array = [];
        _array.push(data?._id);
        win.setItem("ids", JSON.stringify(_array));
        setIds([...ids, data?._id]);
        return AlertHandler("Add to Favourite", "success");
      }
      if (_sessionArray?.length > 0) {
        if (_sessionArray.includes(data?._id)) {
          const findIndex = _sessionArray.findIndex(
            (item) => item === data?._id
          );
          _sessionArray.splice(findIndex, 1);
          AlertHandler("Remove from Favourite", "success");
          setIds(_sessionArray);
          return win.setItem("ids", JSON.stringify(_sessionArray));
        } else if (!_sessionArray.includes(data?._id)) {
          _sessionArray.push(data?._id);
          win.setItem("ids", JSON.stringify(_sessionArray));
          setIds([...ids, data?._id]);
          return AlertHandler("Add to Favourite", "success");
        }
      }
    }
  };

  return (
    <>
      <>
        <div className="py-3 title d-flex align-items-center">
          <h5 data-aos="fade-left" className="mb-3">
            Resturants
          </h5>
        </div>
        <div className="most_popular">
          <div className="row">
            {
              _data.length > 0 ? (
                _data?.map((item, index) => {
                  return (
                    <div className=" col-sm-6 col-lg-3 pb-3" key={index}>
                      <div className="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                        <div className="list-card-image">
                          <div className="star position-absolute">
                            <span className="badge badge-success">
                              <i className="feather-star" />{" "}
                              {item?.totalRatings
                                ? item?.totalRatings.toFixed(1)
                                : 0}{" "}
                              {/* (
                              {!item?.ratings?.peopleRated
                                ? 0
                                : item?.ratings?.peopleRated}
                              ) */}
                            </span>
                          </div>
                          <div className="favourite-heart position-absolute fav-icon-div">
                            <a onClick={() => favouriteRestaurant(item)}>
                              {user?._id ? (
                                <FavoriteBorderOutlinedIcon
                                  fontSize="small"
                                  style={{
                                    color:
                                      item?.favourite_by_users?.includes(
                                        user?._id
                                      ) && "red",
                                  }}
                                />
                              ) : (
                                <FavoriteBorderOutlinedIcon
                                  fontSize="small"
                                  style={{
                                    color: ids?.includes(item?._id) && "red",
                                  }}
                                />
                              )}
                            </a>
                          </div>
                          <div className="member-plan position-absolute">
                            <span className="badge badge-dark">Promoted</span>
                          </div>
                          <Link to={`restaurant?id=${item?._id}`}>
                            <img
                              alt="#"
                              style={{ height: "167px" }}
                              src={
                                item?.file_id !== null
                                  ? `${URLForImages}/${item?.file_id?.file_path}`
                                  : "img/trending3.png"
                              }
                              className="img-fluid item-img w-100"
                            />
                          </Link>
                        </div>
                        <div className="p-3 position-relative">
                          <div className="list-card-body">
                            <h6 className="mb-1">
                              <a
                                onClick={() =>
                                  navigate(`restaurant?id=${item?._id}`)
                                }
                                className="text-black text-capitalize"
                              >
                                {item?.name}
                              </a>
                            </h6>

                            <p
                              style={{ fontSize: "14px" }}
                              className="text-gray mb-1 "
                            >
                              {item?.bio}
                            </p>
                            <p className="text-gray mb-1 rating"></p>
                            <ul className="rating-stars list-unstyled">
                              <li className="">
                                <i
                                  style={{ fontSize: "14px" }}
                                  className={
                                    item?.totalRatings === 0 ||
                                    item?.totalRatings === undefined
                                      ? "fa fa-star mr-1"
                                      : "mr-1 fa fa-star star_active"
                                  }
                                />
                                <i
                                  style={{ fontSize: "14px" }}
                                  className={
                                    item?.totalRatings < 2 ||
                                    item?.totalRatings === undefined
                                      ? "fa fa-star mr-1"
                                      : "mr-1 fa fa-star star_active"
                                  }
                                />
                                <i
                                  style={{ fontSize: "14px" }}
                                  className={
                                    item?.totalRatings < 3 ||
                                    item?.totalRatings === undefined
                                      ? "fa fa-star mr-1"
                                      : "mr-1 fa fa-star star_active"
                                  }
                                />
                                <i
                                  style={{ fontSize: "14px" }}
                                  className={
                                    item?.totalRatings < 4 ||
                                    item?.totalRatings === undefined
                                      ? "fa fa-star mr-1"
                                      : "mr-1 fa fa-star star_active"
                                  }
                                />
                                <i
                                  style={{ fontSize: "14px" }}
                                  className={
                                    item?.totalRatings < 5 ||
                                    item?.totalRatings === undefined
                                      ? "fa fa-star mr-1"
                                      : "mr-1 fa fa-star star_active"
                                  }
                                />
                              </li>
                            </ul>
                            <p />
                          </div>
                          {/* <div className="list-card-badge">
                                            <span style={{fontSize:'14px'}} className="badge badge-success">OFFER</span> <small>0% off</small>
                                        </div> */}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="d-flex justify-content-between row">
                  <div className="col-3">
                    <div style={{ width: "40%" }} className="">
                      {/* For variant="text", adjust the height via font-size */}
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem" }}
                        width={350}
                      />

                      {/* For other variants, adjust the size with `width` and `height` */}
                      <Skeleton variant="circular" width={40} height={40} />
                      <Skeleton
                        variant="rectangular"
                        width={350}
                        height={120}
                        className="mt-1"
                      />
                      <Skeleton variant="rounded" width={350} height={120} />
                    </div>
                  </div>
                  <div className="col-3">
                    <div style={{ width: "40%" }} className="">
                      {/* For variant="text", adjust the height via font-size */}
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem" }}
                        width={350}
                      />

                      {/* For other variants, adjust the size with `width` and `height` */}
                      <Skeleton variant="circular" width={40} height={40} />
                      <Skeleton
                        variant="rectangular"
                        width={350}
                        height={120}
                        className="mt-1"
                      />
                      <Skeleton variant="rounded" width={350} height={120} />
                    </div>
                  </div>

                  <div className="col-3">
                    <div style={{ width: "40%" }} className="">
                      {/* For variant="text", adjust the height via font-size */}
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem" }}
                        width={350}
                      />

                      {/* For other variants, adjust the size with `width` and `height` */}
                      <Skeleton variant="circular" width={40} height={40} />
                      <Skeleton
                        variant="rectangular"
                        width={350}
                        height={120}
                        className="mt-1"
                      />
                      <Skeleton variant="rounded" width={350} height={120} />
                    </div>
                  </div>
                </div>
              )
              // [1, 2, 3, 4].map((item, index) => {
              //     return
              //     <div className="col-md-3 pb-3" key={index}>
              //         <div className="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
              //             <div className="list-card-image">
              //                 <div className="star position-absolute"><span className="badge badge-success"><i className="feather-star" /> 3.3 (4)</span></div>
              //                 <div className="favourite-heart text-danger position-absolute"><a href="#"><i className="feather-heart" /></a></div>
              //                 <div className="member-plan position-absolute"><span className="badge badge-dark">Promoted</span></div>
              //                 <a>
              //                     <img alt="#" style={{ height: '167px' }} src={"img/trending3.png"} className="img-fluid item-img w-100" />
              //                 </a>
              //             </div>
              //             <div className="p-3 position-relative">
              //                 <div className="list-card-body">
              //                     <h6 className="mb-1"><a className="text-black text-capitalize">Famous</a></h6>
              //                     <p className="text-gray mb-1 small">Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
              //                         molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
              //                         numquam blanditiis</p>
              //                     <p className="text-gray mb-1 rating">
              //                     </p>
              //                     <ul className="rating-stars list-unstyled">
              //                         <li>
              //                             <i className={"feather-star"} />
              //                             <i className={"feather-star"} />
              //                             <i className={"feather-star"} />
              //                             <i className={"feather-star"} />
              //                             <i className={"feather-star"} />
              //                         </li>
              //                     </ul>
              //                     <p />
              //                 </div>
              //                 {/* <div className="list-card-badge">
              //                     <span className="badge badge-success">OFFER</span> <small>0% off</small>
              //                 </div> */}
              //             </div>
              //         </div>
              //     </div>
              // }
              // )
            }
          </div>
        </div>
      </>
    </>
  );
};

export default Popular;

import React from 'react';

const Profile = () => {
    return <>
        <div>
            <div className="osahan-profile">
                <div className="d-none">
                    <div className="bg-primary border-bottom p-3 d-flex align-items-center">
                        <a className="toggle togglew toggle-2" href="#"><span /></a>
                        <h4 className="font-weight-bold m-0 text-white">Profile</h4>
                    </div>
                </div>
                {/* profile */}
                <div className="container position-relative">
                    <div className="py-5 osahan-profile row">
                        <div className="col-md-4 mb-3">
                            <div className="bg-white rounded shadow-sm sticky_sidebar overflow-hidden">
                                <a href="profile.html" className>
                                    <div className="d-flex align-items-center p-3">
                                        <div className="left mr-3">
                                            <img alt="#" src="img/user1.jpg" className="rounded-circle" />
                                        </div>
                                        <div className="right">
                                            <h6 className="mb-1 font-weight-bold">Gurdeep Singh <i className="feather-check-circle text-success" /></h6>
                                            <p className="text-muted m-0 small">iamosahan@gmail.com</p>
                                        </div>
                                    </div>
                                </a>
                                <div className="osahan-credits d-flex align-items-center p-3 bg-light">
                                    <p className="m-0">Accounts Credits</p>
                                    <h5 className="m-0 ml-auto text-primary">$52.25</h5>
                                </div>
                                {/* profile-details */}
                                <div className="bg-white profile-details">
                                    <a data-toggle="modal" data-target="#paycard" className="d-flex w-100 align-items-center border-bottom p-3">
                                        <div className="left mr-3">
                                            <h6 className="font-weight-bold mb-1 text-dark">Payment Cards</h6>
                                            <p className="small text-muted m-0">Add a credit or debit card</p>
                                        </div>
                                        <div className="right ml-auto">
                                            <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                                        </div>
                                    </a>
                                    <a data-toggle="modal" data-target="#exampleModal" className="d-flex w-100 align-items-center border-bottom p-3">
                                        <div className="left mr-3">
                                            <h6 className="font-weight-bold mb-1 text-dark">Address</h6>
                                            <p className="small text-muted m-0">Add or remove a delivery address</p>
                                        </div>
                                        <div className="right ml-auto">
                                            <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                                        </div>
                                    </a>
                                    <a className="d-flex align-items-center border-bottom p-3" data-toggle="modal" data-target="#inviteModal">
                                        <div className="left mr-3">
                                            <h6 className="font-weight-bold mb-1">Refer Friends</h6>
                                            <p className="small text-primary m-0">Get $10.00 FREE</p>
                                        </div>
                                        <div className="right ml-auto">
                                            <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                                        </div>
                                    </a>
                                    <a href="faq.html" className="d-flex w-100 align-items-center border-bottom px-3 py-4">
                                        <div className="left mr-3">
                                            <h6 className="font-weight-bold m-0 text-dark"><i className="feather-truck bg-danger text-white p-2 rounded-circle mr-2" /> Delivery Support</h6>
                                        </div>
                                        <div className="right ml-auto">
                                            <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                                        </div>
                                    </a>
                                    <a href="contact-us.html" className="d-flex w-100 align-items-center border-bottom px-3 py-4">
                                        <div className="left mr-3">
                                            <h6 className="font-weight-bold m-0 text-dark"><i className="feather-phone bg-primary text-white p-2 rounded-circle mr-2" /> Contact</h6>
                                        </div>
                                        <div className="right ml-auto">
                                            <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                                        </div>
                                    </a>
                                    <a href="terms.html" className="d-flex w-100 align-items-center border-bottom px-3 py-4">
                                        <div className="left mr-3">
                                            <h6 className="font-weight-bold m-0 text-dark"><i className="feather-info bg-success text-white p-2 rounded-circle mr-2" /> Term of use</h6>
                                        </div>
                                        <div className="right ml-auto">
                                            <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                                        </div>
                                    </a>
                                    <a href="privacy.html" className="d-flex w-100 align-items-center px-3 py-4">
                                        <div className="left mr-3">
                                            <h6 className="font-weight-bold m-0 text-dark"><i className="feather-lock bg-warning text-white p-2 rounded-circle mr-2" /> Privacy policy</h6>
                                        </div>
                                        <div className="right ml-auto">
                                            <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 mb-3">
                            <div className="rounded shadow-sm p-4 bg-white">
                                <h5 className="mb-4">My account</h5>
                                <div id="edit_profile">
                                    <div>
                                        <form action="my_account.html">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputName1">First Name</label>
                                                <input type="text" className="form-control" id="exampleInputName1d" defaultValue="Gurdeep" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputName1">Last Name</label>
                                                <input type="text" className="form-control" id="exampleInputName1" defaultValue="Singh" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputNumber1">Mobile Number</label>
                                                <input type="number" className="form-control" id="exampleInputNumber1" defaultValue={1234567890} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Email</label>
                                                <input type="email" className="form-control" id="exampleInputEmail1" defaultValue="iamosahan@gmail.com" />
                                            </div>
                                            <div className="text-center">
                                                <button type="submit" className="btn btn-primary btn-block">Save Changes</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="additional">
                                        <div className="change_password my-3">
                                            <a href="forgot_password.html" className="p-3 border rounded bg-white btn d-flex align-items-center">Change Password
                                                <i className="feather-arrow-right ml-auto" /></a>
                                        </div>
                                        <div className="deactivate_account">
                                            <a href="forgot_password.html" className="p-3 border rounded bg-white btn d-flex align-items-center">Deactivate Account
                                                <i className="feather-arrow-right ml-auto" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* payment modal */}
            <div className="modal fade" id="paycard" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Credit/Debit Card</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h6 className="m-0">Add new card</h6>
                            <p className="small">WE ACCEPT <span className="osahan-card ml-2 font-weight-bold">( Master Card / Visa Card / Rupay )</span></p>
                            <form>
                                <div className="form-row">
                                    <div className="col-md-12 form-group">
                                        <label className="form-label font-weight-bold small">Card number</label>
                                        <div className="input-group">
                                            <input placeholder="Card number" type="number" className="form-control" />
                                            <div className="input-group-append"><button type="button" className="btn btn-outline-secondary"><i className="feather-credit-card" /></button></div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 form-group"><label className="form-label font-weight-bold small">Valid through(MM/YY)</label><input placeholder="Enter Valid through(MM/YY)" type="number" className="form-control" /></div>
                                    <div className="col-md-4 form-group"><label className="form-label font-weight-bold small">CVV</label><input placeholder="Enter CVV Number" type="number" className="form-control" /></div>
                                    <div className="col-md-12 form-group"><label className="form-label font-weight-bold small">Name on card</label><input placeholder="Enter Card number" type="text" className="form-control" /></div>
                                    <div className="col-md-12 form-group mb-0">
                                        <div className="custom-control custom-checkbox"><input type="checkbox" id="custom-checkbox1" className="custom-control-input" /><label title type="checkbox" htmlFor="custom-checkbox1" className="custom-control-label small pt-1">Securely save this card for a faster checkout next time.</label></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer p-0 border-0">
                            <div className="col-6 m-0 p-0">
                                <button type="button" className="btn border-top btn-lg btn-block" data-dismiss="modal">Close</button>
                            </div>
                            <div className="col-6 m-0 p-0">
                                <button type="button" className="btn btn-primary btn-lg btn-block">Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* address modal */}
            <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Delivery Address</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className>
                                <div className="form-row">
                                    <div className="col-md-12 form-group">
                                        <label className="form-label">Delivery Area</label>
                                        <div className="input-group">
                                            <input placeholder="Delivery Area" type="text" className="form-control" />
                                            <div className="input-group-append"><button type="button" className="btn btn-outline-secondary"><i className="feather-map-pin" /></button></div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 form-group"><label className="form-label">Complete Address</label><input placeholder="Complete Address e.g. house number, street name, landmark" type="text" className="form-control" /></div>
                                    <div className="col-md-12 form-group"><label className="form-label">Delivery Instructions</label><input placeholder="Delivery Instructions e.g. Opposite Gold Souk Mall" type="text" className="form-control" /></div>
                                    <div className="mb-0 col-md-12 form-group">
                                        <label className="form-label">Nickname</label>
                                        <div className="btn-group btn-group-toggle w-100" data-toggle="buttons">
                                            <label className="btn btn-outline-secondary active">
                                                <input type="radio" name="options" id="option1" defaultChecked /> Home
                                            </label>
                                            <label className="btn btn-outline-secondary">
                                                <input type="radio" name="options" id="option2" /> Work
                                            </label>
                                            <label className="btn btn-outline-secondary">
                                                <input type="radio" name="options" id="option3" /> Other
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer p-0 border-0">
                            <div className="col-6 m-0 p-0">
                                <button type="button" className="btn border-top btn-lg btn-block" data-dismiss="modal">Close</button>
                            </div>
                            <div className="col-6 m-0 p-0">
                                <button type="button" className="btn btn-primary btn-lg btn-block">Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Invite Modal*/}
            <div className="modal fade" id="inviteModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h5 className="modal-title font-weight-bold text-dark">Invite</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body py-0">
                            <button className="btn btn-light text-primary btn-sm"><i className="feather-plus" /></button>
                            <span className="ml-2 smal text-primary">Send an invite to a friend</span>
                            <p className="mt-3 small">Invited friends (2)</p>
                            <div className="d-flex align-items-center mb-3">
                                <div className="mr-3"><img alt="#" src="img/user1.jpg" className="img-fluid rounded-circle" /></div>
                                <div>
                                    <p className="small font-weight-bold text-dark mb-0">Kate Simpson</p>
                                    <p className="mb-0 small">katesimpson@outbook.com</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-3">
                                <div className="mr-3"><img alt="#" src="img/user2.png" className="img-fluid rounded-circle" /></div>
                                <div>
                                    <p className="small font-weight-bold text-dark mb-0">Andrew Smith</p>
                                    <p className="mb-0 small">andrewsmith@ui8.com</p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer border-0">
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
}

export default Profile;
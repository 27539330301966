import React, { useState, useEffect } from 'react';
import { GetProductByMenuIdAPI, URLForImages } from '../../Apis/Apis';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CircularProgress } from '@mui/material';
SwiperCore.use([Navigation]);

const MenuDetail = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [menu, setMenu] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getProducts();
    }, []);

    const getProducts = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(`${GetProductByMenuIdAPI}/${window.location.search.split('=')[1]}`);
            if (data.success === true) {
                setLoading(false);
                setMenu(data?.menu);
                setData(data?.products);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    return (
        <>
            <div style={{ minHeight: 'calc(100vh - 268px)' }}>
                {/* <div className="d-none">
                    <div className="bg-primary p-3 d-flex">
                        <a className="toggle togglew toggle-2" href="#"><span /></a>
                        <h4 className="font-weight-bold m-0 text-white">Menu Detail</h4>
                    </div>
                </div> */}
                {
                    !loading ? <>
                        <div className="offer-section py-4">
                            <div className="container position-relative">
                                <img alt="#" src={menu?.file_id ? `${URLForImages}/${menu?.file_id?.file_path}` : 'img/trending2.png'} className="restaurant-pic" />
                                <div className="pt-3 text-white d-flex align-center menu-name" style={{ height: '175px', alignItems: 'center' }}>
                                    <h2 className="font-weight-bold text-capitalize">{menu?.name}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className>
                                <p className="font-weight-bold pt-4 m-0 mb-2">MENU ITEMS</p>
                                {/* slider */}
                                <Swiper
                                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                                    navigation
                                    loop={data?.length > 3 ? true : false}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 1,
                                            spaceBetween: 5,
                                        },
                                        480: {
                                            slidesPerView: 1,
                                            spaceBetween: 5,
                                        },
                                        768: {
                                            slidesPerView: 3,
                                            spaceBetween: 5,
                                        },
                                        1024: {
                                            slidesPerView: 3,
                                            spaceBetween: 15,
                                        },
                                        1280: {
                                            slidesPerView: 3,
                                            spaceBetween: 15,
                                        },
                                    }}
                                >
                                    {
                                        data?.length > 0 ? data?.map((item, index) => {
                                            return <SwiperSlide key={index}>
                                                <div onClick={() => navigate(`/product?id=${item?._id}`)} className="osahan-slider-item" data-slick-index={0} aria-hidden="false" style={{ width: 'auto' }} tabIndex={0}>
                                                    <div className="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                                                        <div className="list-card-image">
                                                            <a tabIndex={0}>
                                                                <img style={{ height: '250px' }} alt="#" src={item?.file_id ? `${URLForImages}/${item?.file_id?.file_path}` : 'img/trending2.png'} className="img-fluid item-img w-100" />
                                                            </a>
                                                        </div>
                                                        <div className="p-3 position-relative">
                                                            <div className="list-card-body">
                                                                <h6 className="mb-1"><a onClick={() => navigate(`/product?id=${item?._id}`)} className="text-black text-capitalize" tabIndex={0}>{item?.name}</a>
                                                                </h6>
                                                                <p className="text-gray mb-3 text-capitalize">{item?.restaurant_id?.name}</p>
                                                                <p className="text-gray m-0"> <span className="text-black-50">$ {item?.price}</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        }) : <div>
                                            <h6 className='text-center text-danger'>No items found...</h6>
                                        </div>
                                    }
                                </Swiper>
                            </div>
                        </div>
                    </>
                        :
                        <div className='w-100 d-flex justify-content-center'>
                            <CircularProgress />
                        </div>
                }
                <br />
            </div>

        </>
    )
}

export default MenuDetail;
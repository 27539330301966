import React, { useEffect, useState } from 'react';
import { MostRatedBranchesAPI, URLForImages, GetMenusByCategoryIdAPI, favouriteRestaurantAPI } from '../../Apis/Apis';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { getDistanceFromLatLonInKm } from '../../func';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AlertHandler } from '../../Common/AlertHandler';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';

const Trending = () => {
    useEffect(() => {
        window.scrollTo(0,0)
         }, [])
    const navigate = useNavigate();
    const location = useSelector(state => state.Location);
    const user = useSelector(state => state.Auth.user);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [heading, setHeading] = useState('');

    const [reload, setRelaod] = useState(false);

    useEffect(() => {
        if (window.location.search === "") getTrendingData();
        if (window.location.search !== "") getData();
    }, [reload]);

    // const getData = async () => {
    //     setLoading(true);
    //     try {
    //         const { data } = await axios.get(`${TrendingProductAPI}/${window.location.search.split('=')[1]}`);
    //         if (data.success === true) {
    //             setLoading(false);
    //             setData(data.products);
    //         }
    //     } catch (error) {
    //         setLoading(false);
    //     }
    // }

    const getData = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(`${GetMenusByCategoryIdAPI}/${window.location.search.split('=')[1]}`);
            if (data.success === true) {
                setLoading(false);
                setHeading(data?.menus[0]?.menu_category_id?.name);
                setData(data?.menus);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const getTrendingData = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(MostRatedBranchesAPI);
            if (data.success === true) {
                setLoading(false);
                setData(data.branches);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const favouriteRestaurant = async (data) => {
        let obj = { restaurant: data?.restaurant_id?._id, user: user?._id };
        try {
            const { data } = await axios.post(favouriteRestaurantAPI, obj);
            if (data) {
                setRelaod(!reload);
                AlertHandler(data?.message, 'success');
            }
        } catch (error) {
            console.log(error);
        }
    }

    return <div>
        <div className="d-none">
            {/* <div className="bg-primary p-3 d-flex align-items-center">
                <a className="toggle togglew toggle-2" href="#"><span /></a>
                <h4 className="font-weight-bold m-0 text-white">{heading ? heading : 'Trending'}</h4>
            </div> */}
        </div>
        <div className="osahan-trending" style={{ minHeight: 'calc(100vh - 268px)' }}>
            {/* Most popular */}
            {
                loading ? <div style={{ display: 'flex', justifyContent: 'center', margin: '1rem 0rem' }}><CircularProgress size='2rem' /></div>
                    :
                    <div className="container">
                        
                        <div className="most_popular py-5 mt-top">
                            <div className="d-flex align-items-center mb-4">
                                <h3 className="font-weight-bold text-danger mb-0" style={{ textTransform: 'capitalize' }}>Best {heading} In Town</h3>
                                {/* <a href="#" data-toggle="modal" data-target="#filters-1" className="ml-auto btn btn-primary">Filters</a> */}
                        
                            </div> 
                            {/* <h5>We serve the best {heading}.Our Chefs are highly trained to serve customers their favorite {heading}</h5> */}
                             
                            <div className="row">
                                {
                                    data?.length > 0 ? data?.map((item, index) => {
                                        return <div key={index} className="col-lg-4 mb-3">
                                            {/* <h2>{window.location.search !== "" ? item?.restaurant_id?.name : item?.name}</h2> */}
                                            <div className="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm grid-card">
                                                <div className="list-card-image">
                                                    {/* <div className="star position-absolute"><span className="badge badge-success"><i className="feather-star" /> {item?.totalRatings ? item?.totalRatings.toFixed(1) : 0} ({item?.ratings?.peopleRated ? item?.ratings?.peopleRated : 0})</span></div> */}
                                                    {/* <div onClick={() => favouriteRestaurant(item)} className="favourite-heart text-danger position-absolute fav-icon-div"><a>
                                                        <FavoriteBorderOutlinedIcon fontSize='small'
                                                            style={{ color: item?.restaurant_id?.favourite_by_users?.includes('6310bc15b8602d61d7016de2') && 'blue' }}
                                                        />
                                                    </a></div> */}
                                                    <div className="member-plan position-absolute"><span className="badge badge-dark">Promoted</span></div>
                                                    <Link to={`/restaurant?id=${item.restaurant_id._id}`}>
                                                        <img style={{ height: '240px' }} alt="#" src={`${item?.file_id !== null ? `${URLForImages}/${item?.file_id?.file_path}` : "img/trending1.png"}`} className="img-fluid item-img w-100" />
                                                    </Link>
                                                </div>
                                                <div className="p-3 position-relative">
                                                    <div className="list-card-body">
                                                        <h6 className="mb-1"><a onClick={() => navigate(`/restaurant?id=${item.restaurant_id._id}`)} style={{ textTransform: 'capitalize', color: 'black' }} className="text-black">{window.location.search !== "" ? item?.restaurant_id?.name : item?.name}
                                                        </a>
                                                        </h6>
                                                       
                                                        <p className="text-gray mb-3">{item?.restaurant_id?.bio} </p>
                                                        <p className="text-gray mb-3 time d-flex align-center justify-content-between" style={{ alignItems: 'center' }}>
                                                            {
                                                                location?.coords !== null &&
                                                                <span className="bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2 ">
                                                                    <i className="feather-map-pin" />
                                                                    {window.location.search === "" ?
                                                                        getDistanceFromLatLonInKm(location?.coords?.latitude, location?.coords?.longitude, item?.latitude, item?.longitude)
                                                                        :
                                                                        getDistanceFromLatLonInKm(location?.coords?.latitude, location?.coords?.longitude, item?.branch_id?.latitude, item?.branch_id?.longitude)
                                                                    } Km
                                                                </span>
                                                            }
                                                            <span className="">{item?.branch_id?.address}</span>
                                                        </p>
                                                    </div>
                                                    {/* <div className="list-card-badge">
                                                        <span className="badge badge-danger">OFFER</span> <small>0% Discount</small>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    })
                                        : <div style={{ textAlign: 'center', width: '100%', color: 'red' }}>
                                            <h4>Sorry, No items found</h4>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
            }
        </div>
        <div className="modal fade" id="filters-1" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Filters</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body p-0">
                        <div className="osahan-filter">
                            <div className="filter">
                                {/* SORT BY */}
                                <div className="p-3 bg-light border-bottom">
                                    <h6 className="m-0">SORT BY</h6>
                                </div>
                                <div className="custom-control border-bottom px-0  custom-radio">
                                    <input type="radio" id="customRadio1" name="location" className="custom-control-input" defaultChecked />
                                    <label className="custom-control-label py-3 w-100 px-3" htmlFor="customRadio1">Top Rated</label>
                                </div>
                                <div className="custom-control border-bottom px-0  custom-radio">
                                    <input type="radio" id="customRadio2" name="location" className="custom-control-input" />
                                    <label className="custom-control-label py-3 w-100 px-3" htmlFor="customRadio2">Nearest Me</label>
                                </div>
                                <div className="custom-control border-bottom px-0  custom-radio">
                                    <input type="radio" id="customRadio3" name="location" className="custom-control-input" />
                                    <label className="custom-control-label py-3 w-100 px-3" htmlFor="customRadio3">Cost High to Low</label>
                                </div>
                                <div className="custom-control border-bottom px-0  custom-radio">
                                    <input type="radio" id="customRadio4" name="location" className="custom-control-input" />
                                    <label className="custom-control-label py-3 w-100 px-3" htmlFor="customRadio4">Cost Low to High</label>
                                </div>
                                <div className="custom-control border-bottom px-0  custom-radio">
                                    <input type="radio" id="customRadio5" name="location" className="custom-control-input" />
                                    <label className="custom-control-label py-3 w-100 px-3" htmlFor="customRadio5">Most Popular</label>
                                </div>
                                {/* Filter */}
                                <div className="p-3 bg-light border-bottom">
                                    <h6 className="m-0">FILTER</h6>
                                </div>
                                <div className="custom-control border-bottom px-0  custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="defaultCheck1" defaultChecked />
                                    <label className="custom-control-label py-3 w-100 px-3" htmlFor="defaultCheck1">Open Now</label>
                                </div>
                                <div className="custom-control border-bottom px-0  custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="defaultCheck2" />
                                    <label className="custom-control-label py-3 w-100 px-3" htmlFor="defaultCheck2">Credit Cards</label>
                                </div>
                                <div className="custom-control border-bottom px-0  custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="defaultCheck3" />
                                    <label className="custom-control-label py-3 w-100 px-3" htmlFor="defaultCheck3">Alcohol Served</label>
                                </div>
                                {/* Filter */}
                                <div className="p-3 bg-light border-bottom">
                                    <h6 className="m-0">ADDITIONAL FILTERS</h6>
                                </div>
                                <div className="px-3 pt-3">
                                    <input type="range" className="custom-range" min={0} max={100} name="minmax" />
                                    <div className="form-row">
                                        <div className="form-group col-6">
                                            <label>Min</label>
                                            <input className="form-control" placeholder="$0" type="number" />
                                        </div>
                                        <div className="form-group text-right col-6">
                                            <label>Max</label>
                                            <input className="form-control" placeholder="$1,0000" type="number" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer p-0 border-0">
                        <div className="col-6 m-0 p-0">
                            <a href="#" className="btn border-top btn-lg btn-block" data-dismiss="modal">Close</a>
                        </div>
                        <div className="col-6 m-0 p-0">
                            <a href="#" className="btn btn-primary btn-lg btn-block">Apply</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

}

export default Trending;
import React from 'react'

const Related = ({ data }) => {
    return (
        <div style={{ backgroundColor: 'white', padding: '1rem 0rem 0rem 1rem' }} classname="bg-white mb-3 restaurant-detailed-ratings-and-reviews shadow-sm rounded">
            <h6 classname="mb-2">Related Products</h6>
            <div className="most_sale">
                {
                    data?.length > 0 ?
                        <div className="row mb-3">
                            <div className="col-md-4 mb-3">
                                {
                                    data?.map(item => {
                                        return <div className="d-flex align-items-center list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                                            <div className="list-card-image">
                                                <div className="star position-absolute"><span className="badge badge-success"><i className="feather-star" /> 3.1 (300+)</span></div>
                                                <div className="favourite-heart text-danger position-absolute"><a href="#"><i className="feather-heart" /></a></div>
                                                <div className="member-plan position-absolute"><span className="badge badge-dark">Promoted</span></div>
                                                <a href="restaurant.html">
                                                    <img alt="#" src="img/sales2.png" className="img-fluid item-img w-100" />
                                                </a>
                                            </div>
                                            <div className="p-3 position-relative">
                                                <div className="list-card-body">
                                                    <h6 className="mb-1"><a href="restaurant.html" className="text-black">Thai Famous Cuisine</a></h6>
                                                    <p className="text-gray mb-3">North Indian • Indian • Pure veg</p>
                                                    <p className="text-gray mb-3 time"><span className="bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2"><i className="feather-clock" /> 30–35 min</span> <span className="float-right text-black-50"> $250 FOR TWO</span></p>
                                                </div>
                                                <div className="list-card-badge">
                                                    <span className="badge badge-success">OFFER</span> <small>65% off</small>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }

                            </div>
                        </div>
                        : <h6 className='text-danger text-center p-3'>No Product found...</h6>
                }
            </div>
        </div>
    )
}

export default Related
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Link, useNavigate,useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAuthLogout } from "../../Redux/AuthSlice";
import axios from "axios";
import { setLocation } from "../../Redux/LocationSlice";
import { setFilter } from "../../Redux/FilterSlice";
import { AlertHandler } from "../../Common/AlertHandler";
import {
  GetCitiesAPI,
  AllProductListAPI,
  SingleRestaurantRatingAPI,
  GetAmenitiesAPI,
} from "../../Apis/Apis";
import { setSorting } from "../../Redux/SortingSlice";
import { setRatingSort } from "../../Redux/RatingSlice";
import { setCity } from "../../Redux/CitySlice";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { top100Films } from "../Restaurant/Movies";
import { FaArrowCircleDown, FaSlidersH, FaArrowDown } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import Aos from "aos";
import "aos/dist/aos.css";
import "./Header.css";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { MenuProps, useStyles, options } from "./utils";
import { setAmenty } from "../../Redux/Amenities";
const Header = () => {
  const [amenities, setAmenities] = useState([]);
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [Gender, setGender] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.Auth.token);
  const user = useSelector((state) => state.Auth.user);
  const location = useSelector((state) => state.Location);
  const setting = useSelector((state) => state.Setting.setting);
  const [selectSorting, setselectSorting] = useState("All Filters");
  const [selectSorting2, setselectSorting2] = useState("Ratings");
  const array = ["/forget-password", "/signup", "/verification"];
  const [checkPath, setCheckPath] = useState(window.location.pathname);
  const [product, setProduct] = useState(null);
  const [rating, setRating] = useState(0);
  const [startPrice, setStartPrice] = useState(0);
  const [endPrice, setEndPrice] = useState(0);
  const [cities, setCities] = useState([]);
  const [products, setProducts] = useState([]);
  const { pathname } = window.location;
  const SORTING_LIST = [
    "Near to me",
    "Rating High to Low",
    "Rating Low to High",
    "Price High to Low",
    "Price Low to High",
  ];
  const SORTING_LIST2 = [
    "Any Ratings",
    "1 Star Rating",
    "2 Star Rating",
    "3 Star Rating",
    "4 Star Rating",
    "5 Star Rating",
  ];
  const logout = () => {
    //   signOut(auth)
    dispatch(setAuthLogout());
    return AlertHandler("You have been logout", "error");
  };
  useEffect(() => {
    Aos.init({ duration: 700 });
  }, []);

  useEffect(() => {
    getCitiesData();
    getProductsData();
    GetAmenitiesData();
    // setProduct(null);
  }, []);

  useEffect(() => {
    setCheckPath(window.location.pathname);
  }, [checkPath]);

  const getCitiesData = async () => {
    try {
      const { data } = await axios.get(GetCitiesAPI);
      setCities(data?.cities);
      console.log(data?.cities);
    } catch (error) {
      console.log(error);
    }
  };
  const getProductsData = async (item) => {
    try {
      const { data } = await axios.get(AllProductListAPI);
      setProducts(
        data?.products.sort((a, b) => {
          return a.name.localeCompare(b.name);
        })
      );
      console.log(
        data?.products.sort((a, b) => {
          return a.name.localeCompare(b.name);
        }),
        "majid121"
      );
    } catch (error) {
      console.log(error);
    }
  };
  const GetAmenitiesData = async () => {
    try {
      const { data } = await axios.get(GetAmenitiesAPI);
      setAmenities(data?.amenities.filter((amenity) => !amenity.deleted_at));
      console.log(data?.amenities, "majidabc");
    } catch (error) {
      console.log(error);
    }
  };

  const sendFilterData = () => {
    console.log("SENDING DATA...")
    console.log("LOCATION ADDRESS: ",location.address)

    if (location.address != null) {
      if (product === null)
        return AlertHandler("Please select some product", "error");
      if (startPrice > 0 && endPrice === 0)
        return AlertHandler("Please select Ending Price", "error");
      if (startPrice > 0 && endPrice === 0)
        return AlertHandler("Please select Starting Price", "error");
      dispatch(setFilter({ product, rating, startPrice, endPrice }));
      // setTimeout(() => {
        setProduct("");
        setRating("");
        setStartPrice(0);
        setEndPrice(0);
        document.getElementById("close-modal-custom").click()
        navigateFn();
        
      // }, 500);
    } else {
      console.log("ELSE")
    }
  };
const navigateFn = () => {
    // let a = document.createElement("a");
    navigate('/map')

    // a.href = "map";
    // a.click();
  };
 const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((success, error) => {
        console.log("success", success);
        getCurrentAddress(success.coords);
      });
    }
  };
const getCurrentAddress = async ({ latitude, longitude }) => {
    try {
      const { data } = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&sensor=true&key=AIzaSyDaLspkx8QhwGqLwpeHBYddGCdJ7fPVXas`
      );
      if (data) {
        console.log("success data", data);

        let _obj = { latitude, longitude };
        let _data = data?.results[0]?.formatted_address;
        dispatch(setLocation({ coords: _obj, address: _data }));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const sortingHandler2 = (value, index) => {
    console.log("majid>>>", value);
    dispatch(setRatingSort({ RatingsSort: index }));
    setselectSorting2(value);
  };

  const sortingHandler = (value) => {
    console.log("value", value);
    dispatch(setSorting({ priceSort: value }));
    setselectSorting(value);
  };

  useEffect(() => {
    dispatch(setSorting({ priceSort: "Price Low to High" }));
    dispatch(setRatingSort({ RatingsSort: 0 }));
    getCurrentLocation();
    
  }, []);
 pathname === "/" &&  dispatch(setAmenty ({AmenitiesFilterValues:[]}))
   return (
    <>
      {!array.includes(window.location.pathname) && (
        <header className="section-header">
          <section
            className="header-main shadow-sm bg-white"
            style={{
              zIndex: window.location.pathname === "/login" && 1,
              position: window.location.pathname === "/login" && "fixed",
              width: window.location.pathname === "/login" && "100%",
              display: window.location.pathname === "/login" && "none",
              //  display: window.location.pathname === "/map" && "none",
            }}
          >
            <div className="header-component">
              <div className="container-fluid ">
                <div className="row align-items-center nav-font-size">
                  <div className="col-1" style={{ textAlign: "right" }}>
                    <Link to={"/"} className="brand-wrap mb-0">
                      <img
                        alt="LOGO_IMAGE"
                        className="img-fluid"
                        src={`${setting?.base_url}/${setting?.logo_id?.file_path}`}
                      />
                    </Link>
                  </div>
                  <div
                    className="col-3 d-flex align-items-center m-none"
                    style={{ textAlign: "right" }}
                  >
                    <div className="dropdown mr-3">
                      <a
                        className="text-dark dropdown-toggle d-flex align-items-center py-3"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <div>
                          <i className="feather-map-pin mr-2 bg-light rounded-pill p-2 icofont-size" />
                        </div>
                        <div style={{ color: "white", fontSize: "15px" }}>
                          <p className=" mb-0 text-color">Select Location</p>
                          {location?.address !== null &&
                            location?.address?.substring(0, 14) + "..."}
                        </div>
                      </a>
                      <div
                        className="dropdown-menu p-0 drop-loc"
                        aria-labelledby="navbarDropdown"
                      >
                        <div className="osahan-country">
                          <div className="search_location bg-primary p-3 text-right">
                            <div className="input-group rounded shadow-sm overflow-hidden">
                              <div className="input-group-prepend">
                                <button className="border-0 btn btn-outline-secondary text-dark bg-white btn-block">
                                  <i className="feather-search" />
                                </button>
                              </div>
                              <a>
                                <input
                                  type="text"
                                  className="shadow-none border-0 form-control"
                                  placeholder="Search..."
                                  value={Gender}
                                />
                              </a>
                            </div>
                          </div>
                          <div
                            className="p-3 border-bottom"
                            onClick={getCurrentLocation}
                          >
                            <a className="text-decoration-none">
                              <p className="font-weight-bold text-primary m-0">
                                <i className="feather-navigation" />
                                Current Location
                              </p>
                            </a>
                          </div>
                          <div className="filter scroller">
                            {/* <h6 className="px-3 py-3 bg-light pb-1 m-0 border-bottom ">Choose your city</h6> */}
                            {cities.length > 0 ? (
                              cities?.map((item, index) => {
                                return (
                                  <div
                                    onClick={(e) =>
                                      dispatch(setCity({ city: item }))
                                    }
                                    key={index}
                                    className="custom-control   border-bottom px-0 custom-radio"
                                  >
                                    {/* <input type="radio" id="customRadio1" name="location" className="custom-control-input" />
                                                            <label className="custom-control-label py-3 w-100 px-3" htmlFor="customRadio1">{item?.name}</label> */}
                                    <input
                                      type="radio"
                                      id="html"
                                      name="fav_language"
                                      value={item?.name}
                                      onChange={(e) =>
                                        setGender(e.target.value)
                                      }
                                    // className="custom-control-input"
                                    />
                                    {item?.name}
                                  </div>
                                );
                              })
                            ) : (
                              <h6>No Cities Yet...</h6>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="  d-flex align-items-center justify-content-end m-h-parent">
                      <a
                        data-toggle="modal"
                        data-target="#filters"
                        className="widget-header mr-4 text-dark"
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          style={{ fontSize: "15px" }}
                          className=" text-color icon d-flex align-items-center"
                        >
                          <i className="feather-search h6 mr-2 mb-0" />{" "}
                          <span>Search</span>
                          <div className="new-header-btns d-none">
                            {token ? (
                              <>
                                <i
                                  onClick={() => dispatch(setAuthLogout())}
                                  className="feather-user h6 mr-2 mb-0 ml-3"
                                />{" "}
                                <span>Logout</span>
                              </>
                            ) : (
                              <>
                                <i
                                  onClick={() => navigate("/login")}
                                  className="feather-user h6 mr-2 mb-0 ml-3"
                                />{" "}
                                <span>Log in</span>
                              </>
                            )}
                          </div>
                        </div>
                      </a>

                      {/* <a className="toggle hc-nav-trigger  hc-nav-1" href="#" role="button" aria-controls="hc-nav-1">
                                        <span></span>
                                    </a> */}

                      {pathname === "/map" && (
                        <>
                          <div class="dropdown-header p-0">
                            <button class="dropbtn-header">
                              <FaSlidersH />
                              {selectSorting}
                            </button>
                            <div class="dropdown-content-header">
                              {SORTING_LIST.map((item, index) => (
                                <p
                                  onClick={() => sortingHandler(item)}
                                  key={item}
                                >
                                  {SORTING_LIST[index]}
                                </p>
                              ))}
                            </div>
                          </div>
                          <div class="dropdown-header p-0">
                            <button class="dropbtn-header">
                              <FaStar />
                              {selectSorting2}
                            </button>
                            <div class="dropdown-content-header">
                              {SORTING_LIST2.map((item, index) => (
                                <p onClick={() => sortingHandler2(item, index)}>
                                  {SORTING_LIST2[index]}{" "}
                                </p>
                              ))}
                            </div>
                          </div>
                        </>
                      )}
                      {token ? (
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => dispatch(setAuthLogout())}
                          className="widget-header mr-4 text-dark m-none"
                        >
                          {/* <div className="icon d-flex align-items-center">
                                                    <i className="feather-user h6 mr-2 mb-0" /> <span>Log out</span>
                                                </div> */}
                        </a>
                      ) : (
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate("/login")}
                          className="widget-header mr-4 text-dark m-none"
                        >
                          <div
                            style={{ fontSize: "15px" }}
                            className=" text-color icon d-flex align-items-center"
                          >
                            <i className="feather-user h6 mr-2 mb-0 ml-3" />{" "}
                            <span>Sign in</span>
                          </div>
                        </a>
                      )}
                      {token && (
                        <div className="dropdown mr-4 ml-3 m-none">
                          <a
                            style={{ color: "white", fontSize: "15px" }}
                            href="#"
                            className="dropdown-toggle  py-3 d-block"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <img
                              alt="#"
                              src="img/user/1.jpg"
                              className="img-fluid rounded-circle header-user mr-2 header-user"
                              style={{ textTransform: "capitalize" }}
                            />
                            {user.fullname}
                          </a>
                          <div
                            className="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <a
                              className="dropdown-item"
                              style={{ cursor: "pointer" }}
                              onClick={logout}
                            //                     data-toggle={"modal"}
                            // data-target={"#logout"}
                            >
                              Logout
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* {model} */}
          <div
            className="modal fade"
            id="logout"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-scrollable">
              <div
                className="modal-content modal-contentlogin  "
                style={{ height: "230px" }}
              >
                <div className="modal-header ">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div
                  className="modal-body p-0"
                  style={{ textAlign: "center", marginTop: "10px" }}
                >
                  <h2>
                    You have been logout Please Login Again to enjoy our
                    facilities
                  </h2>
                  <button
                    className="btn btn-outline-danger mt-1"
                    onClick={() => navigate("/login")}
                  >
                    {" "}
                    Login Again{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="filters"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title"></h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body p-0">
                  <div className="osahan-filter">
                    <div className="filter">
                      <div style={{ width: "93%", margin: "auto" }}>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={top100Films(products)}
                          sx={{ width: "100%" }}
                          size="small"
                          className="mt-3"
                          onChange={(e, v) => setProduct(v?.name)}
                          renderInput={(params) => (
                            <>
                              <TextField
                                {...params}
                                label="Select Product From Here"
                              />
                            </>
                          )}
                        />
                      </div>

                      <div
                        className="form-group mt-3"
                        style={{ width: "93%", margin: "auto" }}
                      >
                        <select
                          defaultValue={"DEFAULT"}
                          className="form-control"
                          id="exampleSelect"
                          onChange={(e) => setRating(e.target.value)}
                        >
                          <option value="DEFAULT" disabled>
                            Rating
                          </option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                        </select>
                      </div>

                      <div className="search-amenity">
                        <Select
                          defaultValue={[]}
                          isMulti
                          closeMenuOnSelect={false}
                          onChange={(values) => {
                            console.log("CHANGE VALUES: ", values)
                            dispatch(setAmenty({ AmenitiesFilterValues: values }))
                          }}
                          hideSelectedOptions={false}
                          allowSelectAll={true}
                          name="colors"
                          options={amenities.map((item) => item && { value: item._id, label: item.name })}
                        />

                      </div>

                      <div className="px-3 pt-3">
                        <div className="form-row">
                          <div className="form-group col-6">
                            <label>Min</label>
                            <input
                              min="0"
                              value={startPrice}
                              onChange={(e) => setStartPrice(e.target.value)}
                              className="form-control"
                              placeholder=""
                              type="number"
                            />
                          </div>
                          <div className="form-group text-right col-6">
                            <label>Max</label>
                            <input
                              min="0"
                              value={endPrice}
                              onChange={(e) => setEndPrice(e.target.value)}
                              className="form-control"
                              placeholder=""
                              type="number"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer p-0 border-0">
                  <div className="col-6 m-0 p-0">
                    <a
                      href="#"
                      className="btn border-top btn-lg btn-block"
                      data-dismiss="modal"
                      id="close-modal-custom"
                    >
                      Close
                    </a>
                  </div>
                  <div className="col-6 m-0 p-0" onClick={sendFilterData}>
                    <a className="btn btn-primary btn-lg btn-block text-white">
                      Apply
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      )}
    </>
  );
};
export default Header;

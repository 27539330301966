import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RegisterApi, FacebookLoginAPI, GoogleLoginAPI } from "../../Apis/Apis";
import { AlertHandler } from "../../Common/AlertHandler";
import { setAuthData } from "../../Redux/AuthSlice";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";

import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";

const Signup = () => {
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const handlePasswordChange = (prop) => (event) => {
  //   setValues({ ...values, [prop]: event.target.value })
  //   setPassword(e.target.value);
  // };
  useEffect(() => {
    document.querySelector("#logout button")?.click();
    const backdrop = document.querySelector(".modal-backdrop");
    backdrop?.classList.remove("show", "fade");
    if (backdrop) backdrop.style.display = "none";
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.Auth.token);

  const [username, setUsername] = useState("");
  const [fullname, setFull_name] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  // Password toggle handler
  const togglePassword = () => {
    
    setPasswordShown(!passwordShown);
  };
  const sendRegisterData = async (e) => {
    e.preventDefault();
    if (username === "" || fullname === "" || password === "") {
      return AlertHandler("Please fill all the fields", "error");
    }
    try {
      setLoading(true);
      const { data } = await axios.post(RegisterApi, {
        username,
        fullname,
        password,
      });
      if (data.success === true) signUpSuccessfullFunc(data);
    } catch (error) {
      setLoading(false);
      AlertHandler("Something wrong with Register", "error");
    }
  };

  const responseFacebook = async (response) => {
    try {
      const { data } = await axios.post(FacebookLoginAPI, response);
      if (data.success === true) signUpSuccessfullFunc(data);
    } catch (error) {
      console.log(error);
      AlertHandler("Something wrong with facebook login", "error");
    }
  };

  const responseGoogle = async (response) => {
    const { tokenId } = response;
    try {
      const { data } = await axios.post(GoogleLoginAPI, { tokenId });
      if (data.success === true) signUpSuccessfullFunc(data);
    } catch (error) {
      AlertHandler("Something wrong with Google login", "error");
    }
  };

  const signUpSuccessfullFunc = (data) => {
    setLoading(false);
    dispatch(setAuthData(data));
    AlertHandler("Register Successfull", "success");
  };
 
   
  

  return (
    <>
      <div className="osahan-signup login-page">
        {token && navigate("/")}
        <video loop autoPlay muted id="vid">
          <source src="img/bg.mp4" type="video/mp4" />
          <source src="img/bg.mp4" type="video/ogg" />
          Your browser does not support the video tag.
        </video>
        <div
          className="d-flex align-items-center justify-content-center flex-column"
          style={{ height: "100vh" }}
        >
          <div className="px-5 col-md-6 ">
            <div className="px-5 col-10 mx-auto mt-4">
              <h2 className="text-dark my-0">Sign up to continue</h2>
              <form className="mt-3 mb-4">
                <div className="form-group">
                  <label htmlFor="exampleInputName1" className="text-dark">
                    Username
                  </label>
                  <input
                    onChange={(e) => setUsername(e.target.value)}
                    type="text"
                    placeholder="Enter Username"
                    className="form-control"
                    id="exampleInputName1"
                    aria-describedby="nameHelp"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputNumber1" className="text-dark">
                    Full Name
                  </label>
                  <input
                    onChange={(e) => setFull_name(e.target.value)}
                    type="text"
                    placeholder="Enter Full Name"
                    className="form-control"
                    id="exampleInputNumber1"
                    aria-describedby="numberHelp"
                  />
                </div>
                <div className="form-group d-flex" style={{alignItems:'center'}} >
                    <div style={{width:'100%'}}> <label htmlFor="exampleInputPassword1" className="text-dark">
                    Password
                  </label>

                  <input
                    htmlFor="standard-adornment-password"
                    onChange={(e) => setPassword(e.target.value)}
                    type={passwordShown ? "text" : "password"}
                    placeholder="Enter Password"
                    className="form-control"
                    id="exampleInputPassword1"
                  /></div>
                  <div>   <i onClick={togglePassword} className="fa fa-eye-slash" style={{fontSize:'20px' , marginTop:'15px'}}></i></div>
                 
                 
                  
        
                  
                </div>
                {/* <div>
                <i className="fa fa-eye" id="myInput"></i>
                </div> */}

                <button
                  onClick={sendRegisterData}
                  className="btn btn-primary btn-lg btn-block"
                >
                  {loading ? (
                    <CircularProgress size="1rem" style={{ color: "white" }} />
                  ) : (
                    "SIGN UP"
                  )}
                </button>
                <div className="py-2">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      document.getElementsByClassName("btnFacebook")[0].click();
                    }}
                    className="btn btn-facebook btn-lg btn-block"
                  >
                    <i className="feather-facebook" /> Connect with Facebook
                  </button>
                  <FacebookLogin
                    appId="1063588737606172"
                    fields="name,email,picture"
                    callback={responseFacebook}
                    autoLoad={false}
                    cssClass="btnFacebook"
                  />
                  <GoogleLogin
                    clientId="425955384574-itgctjobrmjl0jiciutgvv9ge4b043jg.apps.googleusercontent.com"
                    buttonText="Connect with Google"
                    onSuccess={responseGoogle}
                    cookiePolicy={"single_host_origin"}
                    className="btn-lg btn-block my-2 py-1 d-flex justify-content-center"
                    style={{ borderRadius: "5px" }}
                  />
                </div>
              </form>
            </div>
            <div className="new-acc d-flex align-items-center justify-content-center">
              <a
                style={{ cursor: "pointer", marginBottom: "7px" }}
                onClick={() => navigate(`/login`)}
              >
                <p className="text-center m-0">
                  Already have an account? Sign in
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;

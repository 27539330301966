import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    RatingsSort:0
}

const RatingSlice = createSlice({
    name: 'rating',
    initialState,
    reducers: {
        setRatingSort(state, action) {
            const { RatingsSort } = action.payload;
            state.RatingsSort = RatingsSort;        },
        // removeFilter(state, action) {
        //     state.product = null;
        //     state.rating = null;
        //     state.startPrice = null;
        //     state.endPrice = null;
        // }
    }
});

export const { setRatingSort } = RatingSlice.actions;
export default RatingSlice.reducer;
import React, { useState } from 'react';
import { LoginAPI, FacebookLoginAPI, GoogleLoginAPI } from '../../Apis/Apis';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthData } from '../../Redux/AuthSlice';
import { AlertHandler } from '../../Common/AlertHandler';
import CircularProgress from '@mui/material/CircularProgress';
import FacebookLogin from 'react-facebook-login';
import { useNavigate } from 'react-router-dom';
import GoogleLogin from 'react-google-login';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector(state => state.Auth.token);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const loginFunc = async (e) => {
        e.preventDefault();
        setLoading(true);
        const obj = { username, password }
        try {
            const { data } = await axios.post(LoginAPI, obj);
            if (data.success === true) loginSuccessfullFunc(data);
        } catch (error) {
            setLoading(false);
            AlertHandler(error.response.data.message, 'error');
        }
    }

    const responseFacebook = async (response) => {
        try {
            const { data } = await axios.post(FacebookLoginAPI, response);
            if (data.success === true) loginSuccessfullFunc(data);
        } catch (error) {
            AlertHandler('Something wrong with facebook login', 'error');
        }
    }

    const responseGoogle = async (response) => {
        const { tokenId } = response;
        try {
            const { data } = await axios.post(GoogleLoginAPI, { tokenId });
            if (data.success === true) loginSuccessfullFunc(data);
        } catch (error) {
            AlertHandler('Something wrong with Google login', 'error');
        }
    }


    const loginSuccessfullFunc = (data) => {
        setLoading(false);
        dispatch(setAuthData(data));
        AlertHandler('Login Successfull', 'success');
    }

    return (
        <form className="" action="verification.html">
            {/* <div className="form-group">
            <label htmlFor="exampleInputEmail1" className="text-dark">Username</label>
            <input onChange={(e) => setUsername(e.target.value)} type="email" placeholder="Enter Username" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
        </div>
        <div className="form-group">
            <label htmlFor="exampleInputPassword1" className="text-dark">Password</label>
            <input onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Enter Password" className="form-control" id="exampleInputPassword1" />
        </div> */}
            {/* <button onClick={loginFunc} className="btn btn-primary btn-lg btn-block">
            {loading ? <CircularProgress size='1rem' style={{ color: 'white' }} /> : 'SIGN IN'}
        </button> */}
            <div className="py-2">
                <button onClick={(e) => {
                    e.preventDefault();
                    document.getElementsByClassName('btnFacebook')[0].click();
                }}
                    className="btn btn-lg btn-facebook btn-block m-auto facebook-login"><i className="feather-facebook" /> Connect with Facebook</button>
                <FacebookLogin
                    appId="1063588737606172"
                    fields="name,email,picture"
                    callback={responseFacebook}
                    cssClass="btnFacebook"
                />
                <GoogleLogin
                    clientId="425955384574-itgctjobrmjl0jiciutgvv9ge4b043jg.apps.googleusercontent.com"
                    buttonText="Connect with Google"
                    onSuccess={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                    className='btn-lg btn-block py-1 d-flex justify-content-center m-auto google-login'
                    style={{ borderRadius: '5px' }}
                />
                <div className='login-modal-email'><button onClick={()=> navigate("/signup")}>Sign up using Email</button></div>
                <div>
                    <p className='text-center mt-4'>Already have an account  ? <span  onClick={() => navigate("/login")} style={{color:'red' , cursor:'pointer'}}>Log in</span></p>
                </div>
            </div>
        </form>
        // <div className="login-page vh-100" >
        //     {token && navigate('/')}

        //     <div className="">


        //                 <a href="forgot_password.html" className="text-decoration-none">
        //                     {/* <p className="text-center">Forgot your password?</p> */}
        //                 </a>
        //                 <div className="d-flex align-items-center justify-content-center">
        //                     <a style={{ cursor: 'pointer' }} onClick={() => navigate('/signup')}>
        //                         <p className="text-center m-0">Don't have an account? Sign up</p>
        //                     </a>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>

    )
}

export default Login;
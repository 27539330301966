import axios from "axios";
import { useSearchParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
  GetProductByIdAPI,
  URLForImages,
  RateProductAPI,
} from "../../Apis/Apis";
import moment from "moment";
import StarRatings from "react-star-ratings";
import { AlertHandler } from "../../Common/AlertHandler";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Slider from "../../Common/Slider";
import Login from "../../Components/Login/Login";
import LoginModal from "../../Common/Modal/LoginModal";

const ProductDetail = () => {
  const [data, setData] = useState([]);
  const user = useSelector((state) => state.Auth.user);
  const RelateItem = useSelector((state) => state.RelateItem);

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [loading1, setLoading1] = useState(false);
  const [changeLocation, setChangeLocation] = useState(window.location.href);
  const [reload, setReload] = useState(true);
  const [loading, setLoading] = useState(false);
  const [productpagecomments, setproductpagecomments] = useState(5);
  useEffect(() => {
    getProduct();
  }, [reload, changeLocation]);
  const [srch] = useSearchParams();
  // console.log(srch.get("id"), "shan");

  const getProduct = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${GetProductByIdAPI}/${window.location.search.split("=")[1]}`
      );
      if (data.success === true) {
        setLoading(false);
        setData(data.product);
        console.log(data.product, "dattaa");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  

  const changeRating = (newRating, name) => {
    setRating(newRating);
  };

  const sendRatingData = async () => {
    if (user != null) {
      if (rating === 0) return AlertHandler("Please do rating", "error");
      if (comment === "") return AlertHandler("Please write message", "error");
      if (!user?._id) return AlertHandler("Please login to comment", "error");
      setLoading1(true);
      try {
        const obj = {
          ratings: rating,
          comment: comment,
          user_id: user?._id,
          restaurant_id: data?.restaurant_id?._id,
          branch_id: data?.branch_id?._id,
          item_id: window.location.search.split("=")[1],
        };
        const res = await axios.post(RateProductAPI, obj);
        if (res?.data?.success === true) {
          setLoading1(false);
          AlertHandler(res?.data?.message, "success");
          setRating(0);
          setComment("");
          setReload(!reload);
        }
      } catch (error) {
        setLoading1(false);
        console.log(error);
      }
    }
  };

  // console.log(
  //   ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>RelateItem",
  //   RelateItem
  // );
  const { relatedItems } = RelateItem;
  return (
    <div style={{ minHeight: "calc(100vh - 268px)" }}>
      {/* <div className="d-none">
        <div className="bg-primary p-3 d-flex align-items-center">
          <a className="toggle togglew toggle-2" href="#"><span /></a>
          <h4 className="font-weight-bold m-0 text-white">Product Detail</h4>
        </div>
      </div> */}
      {loading ? (
        <div className="d-flex justify-content-center w-100">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="offer-section py-4">
            <div
              className="container position-relative "
              style={{
                marginTop: "20px",
              }}
            >
              <img
                style={{ width: "222px" }}
                alt="#"
                src={
                  data?.file_id
                    ? `${URLForImages}/${data?.file_id?.file_path}`
                    : "img/trending2.png"
                }
                className="restaurant-pic"
              />
              <div className=" text-white">
                <h1
                  style={{
                    textTransform: "capitalize",
                  }}
                >
                  {data?.restaurant_id?.name}
                </h1>
                <h2 className="font-weight-bold text-capitalize ">
                  {data?.name}
                </h2>
                <p
                  className="text-white m-0 r-detail"
                  style={{ width: "70%", fontSize: "18px" }}
                >
                  {data?.description}
                </p>
                <div className="rating-wrap d-flex align-items-center mt-2">
                  <ul className="rating-stars list-unstyled">
                    <li>
                      <i
                        className={
                          data?.totalRatings === 0 ||
                          data?.totalRatings === null
                            ? "fa fa-star"
                            : "fa fa-star text-warning"
                        }
                      />
                      <i
                        className={
                          data?.totalRatings < 2
                            ? "fa fa-star"
                            : "fa fa-star text-warning"
                        }
                      />
                      <i
                        className={
                          data?.totalRatings < 3
                            ? "fa fa-star"
                            : "fa fa-star text-warning"
                        }
                      />
                      <i
                        className={
                          data?.totalRatings < 4
                            ? "fa fa-star"
                            : "fa fa-star text-warning"
                        }
                      />
                      <i
                        className={
                          data?.totalRatings < 5
                            ? "fa fa-star"
                            : "fa fa-star text-warning"
                        }
                      />
                    </li>
                  </ul>
                  <p className="label-rating text-white ml-2 ">
                    {" "}
                    ({data?.rating?.peopleRated
                      ? data?.rating?.peopleRated
                      : 0}{" "}
                    Reviews)
                  </p>
                </div>
              </div>
              <div className="pb-4">
                <div className="row">
                  <div className="col-6 col-md-2">
                    <p
                      className="text-white-50 font-weight-bold m-0 "
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      Price
                    </p>
                    <p className="text-white m-0">${data?.price}</p>
                  </div>
                  {/* <div className="col-6 col-md-2">
                      <p className="text-white-50 font-weight-bold m-0 " style={{
                        fontSize: '18px'
                      }}>Status</p>
                      <p className="text-white m-0">
                        Active
                        {data?.status === 1 ? 'Active' : 'InActive'}
                      </p>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* Menu */}
          <div className="container position-relative">
            <div className="row">
              <div className="col-md-12 pt-3">
                <div className="mb-3">
                  <div className="bg-white rounded p-3 mb-3 clearfix graph-star-rating rounded shadow-sm">
                    <h6
                      className="mb-0 mb-1"
                      style={{
                        fontSize: "25px",
                      }}
                    >
                      Ratings and Reviews
                    </h6>
                    <p
                      className="text-muted mb-4 mt-1 "
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      Rated {data?.totalRatings?.toFixed(1)} out of 5
                    </p>
                    <div className="graph-star-rating-body">
                      <div className="rating-list">
                        <div className="rating-list-left font-weight-bold small">
                          5 Star
                        </div>
                        <div className="rating-list-center">
                          <div className="progress">
                            <div
                              role="progressbar"
                              className="progress-bar bg-info"
                              aria-valuenow={data?.rating?.star5}
                              aria-valuemin={0}
                              aria-valuemax={100}
                              style={{ width: `${data?.rating?.star5}%` }}
                            />
                          </div>
                        </div>
                        <div className="rating-list-right font-weight-bold small">
                          {data?.rating?.star5 ? data?.rating?.star5 : 0} %
                        </div>
                      </div>
                      <div className="rating-list">
                        <div className="rating-list-left font-weight-bold small">
                          4 Star
                        </div>
                        <div className="rating-list-center">
                          <div className="progress">
                            <div
                              role="progressbar"
                              className="progress-bar bg-info"
                              aria-valuenow={data?.rating?.star4}
                              aria-valuemin={0}
                              aria-valuemax={100}
                              style={{ width: `${data?.rating?.star4}%` }}
                            />
                          </div>
                        </div>
                        <div className="rating-list-right font-weight-bold small">
                          {data?.rating?.star4 ? data?.rating?.star4 : 0} %
                        </div>
                      </div>
                      <div className="rating-list">
                        <div className="rating-list-left font-weight-bold small">
                          3 Star
                        </div>
                        <div className="rating-list-center">
                          <div className="progress">
                            <div
                              role="progressbar"
                              className="progress-bar bg-info"
                              aria-valuenow={data?.rating?.star3}
                              aria-valuemin={0}
                              aria-valuemax={100}
                              style={{ width: `${data?.rating?.star3}%` }}
                            />
                          </div>
                        </div>
                        <div className="rating-list-right font-weight-bold small">
                          {data?.rating?.star3 ? data?.rating?.star3 : 0} %
                        </div>
                      </div>
                      <div className="rating-list">
                        <div className="rating-list-left font-weight-bold small">
                          2 Star
                        </div>
                        <div className="rating-list-center">
                          <div className="progress">
                            <div
                              role="progressbar"
                              className="progress-bar bg-info"
                              aria-valuenow={data?.rating?.star2}
                              aria-valuemin={0}
                              aria-valuemax={100}
                              style={{ width: `${data?.rating?.star2}%` }}
                            />
                          </div>
                        </div>
                        <div className="rating-list-right font-weight-bold small">
                          {data?.rating?.star2 ? data?.rating?.star2 : 0} %
                        </div>
                      </div>
                      <div className="rating-list">
                        <div className="rating-list-left font-weight-bold small">
                          1 Star
                        </div>
                        <div className="rating-list-center">
                          <div className="progress">
                            <div
                              role="progressbar"
                              className="progress-bar bg-info"
                              aria-valuenow={data?.rating?.star1}
                              aria-valuemin={0}
                              aria-valuemax={100}
                              style={{ width: `${data?.rating?.star1}%` }}
                            />
                          </div>
                        </div>
                        <div className="rating-list-right font-weight-bold small">
                          {data?.rating?.star1 ? data?.rating?.star1 : 0} %
                        </div>
                      </div>
                    </div>
                    {/* <div className="graph-star-rating-footer text-center mt-3"><button type="button" className="btn btn-primary btn-block btn-sm">Rate and Review</button></div> */}
                  </div>
                  <div className="bg-white p-3 mb-3 restaurant-detailed-ratings-and-reviews shadow-sm rounded">
                    <a
                      className="text-primary float-right"
                      style={{
                        fontSize: "20px",
                      }}
                    >
                      Latest Ratings
                    </a>
                    <h6
                      className="mb-1"
                      style={{
                        fontSize: "25px",
                      }}
                    >
                      All Ratings and Reviews
                    </h6>
                    {data?.ratings?.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt))
                      .slice(0, productpagecomments)
                      .map((item, index) => {
                        return (
                          <div className="reviews-members py-3" key={index}>
                            <div className="media">
                              <a href="#">
                                <img
                                  alt="#"
                                  src="img/reviewer2.png"
                                  className="mr-3 rounded-pill"
                                />
                              </a>
                              <div className="media-body">
                                <div className="reviews-members-header">
                                  <div className="star-rating float-right">
                                    <div
                                      className="d-inline-block"
                                      style={{ fontSize: 14 }}
                                    >
                                      <i
                                        className={
                                          item?.ratings === 0
                                            ? "fa fa-star"
                                            : "fa fa-star text-warning"
                                        }
                                      />
                                      <i
                                        className={
                                          item?.ratings < 2
                                            ? "fa fa-star"
                                            : "fa fa-star text-warning"
                                        }
                                      />
                                      <i
                                        className={
                                          item?.ratings < 3
                                            ? "fa fa-star"
                                            : "fa fa-star text-warning"
                                        }
                                      />
                                      <i
                                        className={
                                          item?.ratings < 4
                                            ? "fa fa-star"
                                            : "fa fa-star text-warning"
                                        }
                                      />
                                      <i
                                        className={
                                          item?.ratings < 5
                                            ? "fa fa-star"
                                            : "fa fa-star text-warning"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <h6 className="mb-0">
                                    <a
                                      className="text-dark"
                                      href="#"
                                      style={{
                                        fontSize: "18px",
                                      }}
                                    >
                                      {item?.user_id?.fullname}
                                    </a>
                                  </h6>
                                  <p
                                    className="text-capitalize mb-0"
                                    style={{
                                      fontSize: "18px",
                                    }}
                                  >
                                    {item?.comment}.
                                  </p>
                                  <p
                                    className="text-muted "
                                    style={{
                                      fontSize: "18px",
                                    }}
                                  >
                                    {moment(item?.createdAt).format("L")}
                                  </p>
                                </div>

                                {/* <div className="reviews-members-footer"><a className="total-like btn btn-sm btn-outline-primary" href="#"><i className="feather-thumbs-up" /> 88K</a> <a className="total-like btn btn-sm btn-outline-primary" href="#"><i className="feather-thumbs-down" /> 1K</a><span className="total-like-user-main ml-2" dir="rtl"><a href="#"><img alt="#" src="img/reviewer3.png" className="total-like-user rounded-pill" /></a><a href="#"><img alt="#" src="img/reviewer4.png" className="total-like-user rounded-pill" /></a><a href="#"><img alt="#" src="img/reviewer5.png" className="total-like-user rounded-pill" /></a><a href="#"><img alt="#" src="img/reviewer6.png" className="total-like-user rounded-pill" /></a></span></div> */}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    <hr />
                    {data?.ratings?.length > 5 ? (
                        <a
                          className="text-center w-100 d-block mt-3 font-weight-bold"
                          onClick={() => setproductpagecomments((prev) => (prev === 5 ? data?.ratings?.length : 5))}
                        >
                           {productpagecomments === 5 ? "See More Reviews" : "See Less Reviews"}
                        </a>
                      ) : (
                         <></>
                      )}
                  </div>
                  {(user && (
                    <div className="bg-white p-3 rating-review-select-page rounded shadow-sm">
                      <h6
                        className="mb-3"
                        style={{
                          fontSize: "18px",
                        }}
                      >
                        Leave Comment
                      </h6>
                      <div className="d-flex align-items-center mb-3">
                        <p
                          className="m-0 "
                          style={{
                            fontSize: "18px",
                          }}
                        >
                          Rate the Place
                        </p>
                        <div className="star-rating ml-auto">
                          <StarRatings
                            rating={rating}
                            starRatedColor="#ffc107"
                            starHoverColor="#ffc107"
                            changeRating={changeRating}
                            numberOfStars={5}
                            name="rating"
                            starDimension="20px"
                            starSpacing="0px"
                          />
                        </div>
                      </div>
                      <form>
                        <div className="form-group">
                          <label
                            className="form-label "
                            style={{
                              fontSize: "18px",
                            }}
                          >
                            Your Comment
                          </label>
                          <textarea
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            className="form-control"
                            defaultValue={""}
                          />
                        </div>
                        <div className="form-group mb-0">
                          <button
                            onClick={sendRatingData}
                            type="button"
                            className="btn btn-primary btn-block"
                            style={{
                              width: "fit-content",
                            }}
                          >
                            submit comment
                          </button>
                          <div></div>
                        </div>
                      </form>

                      <div
                        className="modal fade"
                        id="login"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-scrollable">
                          <div className="modal-content modal-contentlogin " style={{height:'auto'}}>
                            <div className="modal-header ">
                              <h5 className=" cmnt">
                                You have to log in first to add comments
                              </h5>

                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div className="modal-body p-0">
                              <LoginModal />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )) || (
                    <div className="login-modal-data">
                      <a
                        data-toggle={user == null && "modal"}
                        data-target={(user == null && "#login") || ""}
                        className="widget-header mr-4 "
                        style={{ cursor: "pointer", textAlign: "center" }}
                      >
                        PLEASE LOGIN FIRST TO ADD COMMENTS
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="modal fade"
                id="login"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content modal-contentlogin ">
                    <div className="modal-header ">
                      <h5 className=" cmnt"></h5>

                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body p-0">
                      <LoginModal />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {relatedItems?.filter((item) => item._id !== srch.get("id")).length >
            0 && (
            <Slider
              relatedItems={relatedItems}
              setChangeLocation={setChangeLocation}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ProductDetail;

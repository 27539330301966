import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    setting: null
}

const SettingSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        setSetting(state, action) {
            state.setting = action.payload;
        },
    }
});

export const { setSetting } = SettingSlice.actions;

export default SettingSlice.reducer;
import React from 'react'

const Orders = () => {
    return <>
        <div>
            <div className="d-none">
                <div className="bg-primary border-bottom p-3 d-flex align-items-center">
                    <a className="toggle togglew toggle-2" href="#"><span /></a>
                    <h4 className="font-weight-bold m-0 text-white">My Order</h4>
                </div>
            </div>
            <section className="py-4 osahan-main-body">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 mb-3">
                            <ul className="nav nav-tabsa custom-tabsa border-0 flex-column bg-white rounded overflow-hidden shadow-sm p-2 c-t-order" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link border-0 text-dark py-3 active" id="completed-tab" data-toggle="tab" href="#completed" role="tab" aria-controls="completed" aria-selected="true">
                                        <i className="feather-check mr-2 text-success mb-0" /> Completed</a>
                                </li>
                                <li className="nav-item border-top" role="presentation">
                                    <a className="nav-link border-0 text-dark py-3" id="progress-tab" data-toggle="tab" href="#progress" role="tab" aria-controls="progress" aria-selected="false">
                                        <i className="feather-clock mr-2 text-warning mb-0" /> On Progress</a>
                                </li>
                                <li className="nav-item border-top" role="presentation">
                                    <a className="nav-link border-0 text-dark py-3" id="canceled-tab" data-toggle="tab" href="#canceled" role="tab" aria-controls="canceled" aria-selected="false">
                                        <i className="feather-x-circle mr-2 text-danger mb-0" /> Canceled</a>
                                </li>
                            </ul>
                        </div>
                        <div className="tab-content col-md-9" id="myTabContent">
                            <div className="tab-pane fade show active" id="completed" role="tabpanel" aria-labelledby="completed-tab">
                                <div className="order-body">
                                    <div className="pb-3">
                                        <div className="p-3 rounded shadow-sm bg-white">
                                            <div className="d-flex border-bottom pb-3">
                                                <div className="text-muted mr-3">
                                                    <img alt="#" src="img/popular5.png" className="img-fluid order_img rounded" />
                                                </div>
                                                <div>
                                                    <p className="mb-0 font-weight-bold"><a href="restaurant.html" className="text-dark">Conrad Chicago Restaurant</a></p>
                                                    <p className="mb-0">Punjab, India</p>
                                                    <p>ORDER #321DERS</p>
                                                    <p className="mb-0 small"><a href="status_complete.html">View Details</a></p>
                                                </div>
                                                <div className="ml-auto">
                                                    <p className="bg-success text-white py-1 px-2 rounded small mb-1">Delivered</p>
                                                    <p className="small font-weight-bold text-center"><i className="feather-map-pin" /> 06/04/2020</p>
                                                </div>
                                            </div>
                                            <div className="d-flex pt-3">
                                                <div className="small">
                                                    <p className="text- font-weight-bold mb-0">Kesar Sweet x 1</p>
                                                    <p className="text- font-weight-bold mb-0">Gulab Jamun x 4</p>
                                                </div>
                                                <div className="text-muted m-0 ml-auto mr-3 small">Total Payment<br />
                                                    <span className="text-dark font-weight-bold">$12.74</span>
                                                </div>
                                                <div className="text-right">
                                                    <a href="checkout.html" className="btn btn-primary px-3">Reorder</a>
                                                    <a href="contact-us.html" className="btn btn-outline-primary px-3">Help</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="p-3 rounded shadow-sm bg-white">
                                            <div className="d-flex border-bottom pb-3">
                                                <div className="text-muted mr-3">
                                                    <img alt="#" src="img/popular4.png" className="img-fluid order_img rounded" />
                                                </div>
                                                <div>
                                                    <p className="mb-0 font-weight-bold"><a href="restaurant.html" className="text-dark">Conrad Chicago Restaurant</a></p>
                                                    <p className="mb-0">Punjab, India</p>
                                                    <p>ORDER #321DERS</p>
                                                    <p className="mb-0 small"><a href="status_complete.html">View Details</a></p>
                                                </div>
                                                <div className="ml-auto">
                                                    <p className="bg-success text-white py-1 px-2 rounded small mb-1">Delivered</p>
                                                    <p className="small font-weight-bold text-center"><i className="feather-map-pin" /> 06/04/2020</p>
                                                </div>
                                            </div>
                                            <div className="d-flex pt-3">
                                                <div className="small">
                                                    <p className="text- font-weight-bold mb-0">Kesar Sweet x 1</p>
                                                    <p className="text- font-weight-bold mb-0">Gulab Jamun x 4</p>
                                                </div>
                                                <div className="text-muted m-0 ml-auto mr-3 small">Total Payment<br />
                                                    <span className="text-dark font-weight-bold">$12.74</span>
                                                </div>
                                                <div className="text-right">
                                                    <a href="checkout.html" className="btn btn-primary px-3">Reorder</a>
                                                    <a href="contact-us.html" className="btn btn-outline-primary px-3">Help</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="progress" role="tabpanel" aria-labelledby="progress-tab">
                                <div className="order-body">
                                    <div className="pb-3">
                                        <div className="p-3 rounded shadow-sm bg-white">
                                            <div className="d-flex border-bottom pb-3">
                                                <div className="text-muted mr-3">
                                                    <img alt="#" src="img/popular1.png" className="img-fluid order_img rounded" />
                                                </div>
                                                <div>
                                                    <p className="mb-0 font-weight-bold"><a href="restaurant.html" className="text-dark">Conrad Chicago Restaurant</a></p>
                                                    <p className="mb-0">Punjab, India</p>
                                                    <p>ORDER #321DERS</p>
                                                    <p className="mb-0 small"><a href="status_onprocess.html">View Details</a></p>
                                                </div>
                                                <div className="ml-auto">
                                                    <p className="bg-warning text-white py-1 px-2 rounded small mb-1">On Process</p>
                                                    <p className="small font-weight-bold text-center"><i className="feather-map-pin" /> 06/04/2020</p>
                                                </div>
                                            </div>
                                            <div className="d-flex pt-3">
                                                <div className="small">
                                                    <p className="text- font-weight-bold mb-0">Kesar Sweet x 1</p>
                                                    <p className="text- font-weight-bold mb-0">Gulab Jamun x 4</p>
                                                </div>
                                                <div className="text-muted m-0 ml-auto mr-3 small">Total Payment<br />
                                                    <span className="text-dark font-weight-bold">$12.74</span>
                                                </div>
                                                <div className="text-right">
                                                    <a href="status_onprocess.html" className="btn btn-primary px-3">Track</a>
                                                    <a href="contact-us.html" className="btn btn-outline-primary px-3">Help</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="p-3 rounded shadow-sm bg-white">
                                            <div className="d-flex border-bottom pb-3">
                                                <div className="text-muted mr-3">
                                                    <img alt="#" src="img/popular2.png" className="img-fluid order_img rounded" />
                                                </div>
                                                <div>
                                                    <p className="mb-0 font-weight-bold"><a href="restaurant.html" className="text-dark">Conrad Chicago Restaurant</a></p>
                                                    <p className="mb-0">Punjab, India</p>
                                                    <p>ORDER #321DERS</p>
                                                    <p className="mb-0 small"><a href="status_onprocess.html">View Details</a></p>
                                                </div>
                                                <div className="ml-auto">
                                                    <p className="bg-warning text-white py-1 px-2 rounded small mb-1">On Process</p>
                                                    <p className="small font-weight-bold text-center"><i className="feather-map-pin" /> 06/04/2020</p>
                                                </div>
                                            </div>
                                            <div className="d-flex pt-3">
                                                <div className="small">
                                                    <p className="text- font-weight-bold mb-0">Kesar Sweet x 1</p>
                                                    <p className="text- font-weight-bold mb-0">Gulab Jamun x 4</p>
                                                </div>
                                                <div className="text-muted m-0 ml-auto mr-3 small">Total Payment<br />
                                                    <span className="text-dark font-weight-bold">$12.74</span>
                                                </div>
                                                <div className="text-right">
                                                    <a href="status_onprocess.html" className="btn btn-primary px-3">Track</a>
                                                    <a href="contact-us.html" className="btn btn-outline-primary px-3">Help</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="p-3 rounded shadow-sm bg-white">
                                            <div className="d-flex border-bottom pb-3">
                                                <div className="text-muted mr-3">
                                                    <img alt="#" src="img/popular3.png" className="img-fluid order_img rounded" />
                                                </div>
                                                <div>
                                                    <p className="mb-0 font-weight-bold"><a href="restaurant.html" className="text-dark">Conrad Chicago Restaurant</a></p>
                                                    <p className="mb-0">Punjab, India</p>
                                                    <p>ORDER #321DERS</p>
                                                    <p className="mb-0 small"><a href="status_onprocess.html">View Details</a></p>
                                                </div>
                                                <div className="ml-auto">
                                                    <p className="bg-warning text-white py-1 px-2 rounded small mb-1">On Process</p>
                                                    <p className="small font-weight-bold text-center"><i className="feather-map-pin" /> 06/04/2020</p>
                                                </div>
                                            </div>
                                            <div className="d-flex pt-3">
                                                <div className="small">
                                                    <p className="text- font-weight-bold mb-0">Kesar Sweet x 1</p>
                                                    <p className="text- font-weight-bold mb-0">Gulab Jamun x 4</p>
                                                </div>
                                                <div className="text-muted m-0 ml-auto mr-3 small">Total Payment<br />
                                                    <span className="text-dark font-weight-bold">$12.74</span>
                                                </div>
                                                <div className="text-right">
                                                    <a href="status_onprocess.html" className="btn btn-primary px-3">Track</a>
                                                    <a href="contact-us.html" className="btn btn-outline-primary px-3">Help</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="canceled" role="tabpanel" aria-labelledby="canceled-tab">
                                <div className="order-body">
                                    <div className="pb-3">
                                        <div className="p-3 rounded shadow-sm bg-white">
                                            <div className="d-flex border-bottom pb-3">
                                                <div className="text-muted mr-3">
                                                    <img alt="#" src="img/popular6.png" className="img-fluid order_img rounded" />
                                                </div>
                                                <div>
                                                    <p className="mb-0 font-weight-bold"><a href="restaurant.html" className="text-dark">Conrad Chicago Restaurant</a></p>
                                                    <p className="mb-0">Punjab, India</p>
                                                    <p>ORDER #321DERS</p>
                                                    <p className="mb-0 small"><a href="status_canceled.html">View Details</a></p>
                                                </div>
                                                <div className="ml-auto">
                                                    <p className="bg-danger text-white py-1 px-2 rounded small mb-1">Payment failed</p>
                                                    <p className="small font-weight-bold text-center"><i className="feather-map-pin" /> 06/04/2020</p>
                                                </div>
                                            </div>
                                            <div className="d-flex pt-3">
                                                <div className="small">
                                                    <p className="text- font-weight-bold mb-0">Kesar Sweet x 1</p>
                                                    <p className="text- font-weight-bold mb-0">Gulab Jamun x 4</p>
                                                </div>
                                                <div className="text-muted m-0 ml-auto mr-3 small">Total Payment<br />
                                                    <span className="text-dark font-weight-bold">$12.74</span>
                                                </div>
                                                <div className="text-right">
                                                    <a href="contact-us.html" className="btn btn-outline-primary px-3">Help</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="p-3 rounded shadow-sm bg-white">
                                            <div className="d-flex border-bottom pb-3">
                                                <div className="text-muted mr-3">
                                                    <img alt="#" src="img/popular6.png" className="img-fluid order_img rounded" />
                                                </div>
                                                <div>
                                                    <p className="mb-0 font-weight-bold"><a href="restaurant.html" className="text-dark">Conrad Chicago Restaurant</a></p>
                                                    <p className="mb-0">Punjab, India</p>
                                                    <p>ORDER #321DERS</p>
                                                    <p className="mb-0 small"><a href="status_canceled.html">View Details</a></p>
                                                </div>
                                                <div className="ml-auto">
                                                    <p className="bg-danger text-white py-1 px-2 rounded small mb-1">Canceled</p>
                                                    <p className="small font-weight-bold text-center"><i className="feather-map-pin" /> 06/04/2020</p>
                                                </div>
                                            </div>
                                            <div className="d-flex pt-3">
                                                <div className="small">
                                                    <p className="text- font-weight-bold mb-0">Kesar Sweet x 1</p>
                                                    <p className="text- font-weight-bold mb-0">Gulab Jamun x 4</p>
                                                </div>
                                                <div className="text-muted m-0 ml-auto mr-3 small">Total Payment<br />
                                                    <span className="text-dark font-weight-bold">$12.74</span>
                                                </div>
                                                <div className="text-right">
                                                    <a href="contact-us.html" className="btn btn-outline-primary px-3">Help</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    </>
}

export default Orders
import React , {useEffect} from 'react'

const MostPopular = () => {
    useEffect(() => {
        window.scrollTo(0,0)
         }, [])
    return (
        <div className="osahan-popular">
            <div className="d-none">
                <div className="bg-primary border-bottom p-3 d-flex align-items-center">
                    <a className="toggle togglew toggle-2" href="#"><span /></a>
                    <h4 className="font-weight-bold m-0 text-white">List</h4>
                </div>
            </div>
            {/* Most popular */}
            <div className="container">
                <div className="most_popular py-5" >
                    <div className="d-flex align-items-center mb-4">
                        <h3 className="font-weight-bold text-dark mb-0">Most Popular</h3>
                        <a href="#" data-toggle="modal" data-target="#filters" className="ml-auto btn btn-primary">Filters</a>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4 mb-3">
                            <div className="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                                <div className="list-card-image">
                                    <div className="popular-slider slick-initialized slick-slider">
                                        <div className="slick-list draggable" style={{ padding: '0px 30px' }}><div className="slick-track" style={{ opacity: 1, width: 2528, transform: 'translate3d(-632px, 0px, 0px)' }}><div className="osahan-slider-item slick-slide slick-cloned" data-slick-index={-2} aria-hidden="true" style={{ width: 316 }} tabIndex={-1}>
                                            <a href="restaurant.html" tabIndex={-1}>
                                                <img alt="#" src="img/popular2.png" className="img-fluid item-img w-100 rounded" />
                                            </a>
                                        </div><div className="osahan-slider-item slick-slide slick-cloned" data-slick-index={-1} aria-hidden="true" style={{ width: 316 }} tabIndex={-1}>
                                                <a href="restaurant.html" tabIndex={-1}>
                                                    <img alt="#" src="img/popular3.png" className="img-fluid item-img w-100 rounded" />
                                                </a>
                                            </div><div className="osahan-slider-item slick-slide slick-current slick-active slick-center" data-slick-index={0} aria-hidden="false" style={{ width: 316 }} tabIndex={0}>
                                                <a href="restaurant.html" tabIndex={0}>
                                                    <img alt="#" src="img/popular1.png" className="img-fluid item-img w-100 rounded" />
                                                </a>
                                            </div><div className="osahan-slider-item slick-slide" data-slick-index={1} aria-hidden="true" style={{ width: 316 }} tabIndex={-1}>
                                                <a href="restaurant.html" tabIndex={-1}>
                                                    <img alt="#" src="img/popular2.png" className="img-fluid item-img w-100 rounded" />
                                                </a>
                                            </div><div className="osahan-slider-item slick-slide" data-slick-index={2} aria-hidden="true" style={{ width: 316 }} tabIndex={-1}>
                                                <a href="restaurant.html" tabIndex={-1}>
                                                    <img alt="#" src="img/popular3.png" className="img-fluid item-img w-100 rounded" />
                                                </a>
                                            </div><div className="osahan-slider-item slick-slide slick-cloned" data-slick-index={3} aria-hidden="true" style={{ width: 316 }} tabIndex={-1}>
                                                <a href="restaurant.html" tabIndex={-1}>
                                                    <img alt="#" src="img/popular1.png" className="img-fluid item-img w-100 rounded" />
                                                </a>
                                            </div><div className="osahan-slider-item slick-slide slick-cloned slick-center" data-slick-index={4} aria-hidden="true" style={{ width: 316 }} tabIndex={-1}>
                                                <a href="restaurant.html" tabIndex={-1}>
                                                    <img alt="#" src="img/popular2.png" className="img-fluid item-img w-100 rounded" />
                                                </a>
                                            </div><div className="osahan-slider-item slick-slide slick-cloned" data-slick-index={5} aria-hidden="true" style={{ width: 316 }} tabIndex={-1}>
                                                <a href="restaurant.html" tabIndex={-1}>
                                                    <img alt="#" src="img/popular3.png" className="img-fluid item-img w-100 rounded" />
                                                </a>
                                            </div></div></div>
                                    </div>
                                </div>
                                <div className="p-3 position-relative">
                                    <div className="list-card-body">
                                        <h6 className="mb-1"><a href="restaurant.html" className="text-black">The osahan Restaurant
                                        </a>
                                        </h6>
                                        <p className="text-gray mb-3">North • Hamburgers • Pure veg</p>
                                        <p className="text-gray mb-3 time"><span className="bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2"><i className="feather-map-pin" /> 15–25 min</span> <span className="float-right text-black-50"> $500 FOR TWO</span></p>
                                    </div>
                                    <div className="list-card-badge">
                                        <span className="badge badge-danger">OFFER</span> <small>65% OSAHAN50</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mb-3">
                            <div className="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                                <div className="list-card-image">
                                    <div className="popular-slider slick-initialized slick-slider">
                                        <div className="slick-list draggable" style={{ padding: '0px 30px' }}><div className="slick-track" style={{ opacity: 1, width: 2528, transform: 'translate3d(-632px, 0px, 0px)' }}><div className="osahan-slider-item slick-slide slick-cloned" data-slick-index={-2} aria-hidden="true" style={{ width: 316 }} tabIndex={-1}>
                                            <a href="restaurant.html" tabIndex={-1}>
                                                <img alt="#" src="img/popular8.png" className="img-fluid item-img w-100 rounded" />
                                            </a>
                                        </div><div className="osahan-slider-item slick-slide slick-cloned" data-slick-index={-1} aria-hidden="true" style={{ width: 316 }} tabIndex={-1}>
                                                <a href="restaurant.html" tabIndex={-1}>
                                                    <img alt="#" src="img/popular6.png" className="img-fluid item-img w-100 rounded" />
                                                </a>
                                            </div><div className="osahan-slider-item slick-slide slick-current slick-active slick-center" data-slick-index={0} aria-hidden="false" style={{ width: 316 }} tabIndex={0}>
                                                <a href="restaurant.html" tabIndex={0}>
                                                    <img alt="#" src="img/popular7.png" className="img-fluid item-img w-100 rounded" />
                                                </a>
                                            </div><div className="osahan-slider-item slick-slide" data-slick-index={1} aria-hidden="true" style={{ width: 316 }} tabIndex={-1}>
                                                <a href="restaurant.html" tabIndex={-1}>
                                                    <img alt="#" src="img/popular8.png" className="img-fluid item-img w-100 rounded" />
                                                </a>
                                            </div><div className="osahan-slider-item slick-slide" data-slick-index={2} aria-hidden="true" style={{ width: 316 }} tabIndex={-1}>
                                                <a href="restaurant.html" tabIndex={-1}>
                                                    <img alt="#" src="img/popular6.png" className="img-fluid item-img w-100 rounded" />
                                                </a>
                                            </div><div className="osahan-slider-item slick-slide slick-cloned" data-slick-index={3} aria-hidden="true" style={{ width: 316 }} tabIndex={-1}>
                                                <a href="restaurant.html" tabIndex={-1}>
                                                    <img alt="#" src="img/popular7.png" className="img-fluid item-img w-100 rounded" />
                                                </a>
                                            </div><div className="osahan-slider-item slick-slide slick-cloned slick-center" data-slick-index={4} aria-hidden="true" style={{ width: 316 }} tabIndex={-1}>
                                                <a href="restaurant.html" tabIndex={-1}>
                                                    <img alt="#" src="img/popular8.png" className="img-fluid item-img w-100 rounded" />
                                                </a>
                                            </div><div className="osahan-slider-item slick-slide slick-cloned" data-slick-index={5} aria-hidden="true" style={{ width: 316 }} tabIndex={-1}>
                                                <a href="restaurant.html" tabIndex={-1}>
                                                    <img alt="#" src="img/popular6.png" className="img-fluid item-img w-100 rounded" />
                                                </a>
                                            </div></div></div>
                                    </div>
                                </div>
                                <div className="p-3 position-relative">
                                    <div className="list-card-body">
                                        <h6 className="mb-1"><a href="restaurant.html" className="text-black">Bayfront Catering Restaurant
                                        </a>
                                        </h6>
                                        <p className="text-gray mb-3">North • Hamburgers • Pure veg</p>
                                        <p className="text-gray mb-3 time"><span className="bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2"><i className="feather-map-pin" /> 15–25 min</span> <span className="float-right text-black-50"> $500 FOR TWO</span></p>
                                    </div>
                                    <div className="list-card-badge">
                                        <span className="badge badge-danger">OFFER</span> <small>65% OSAHAN50</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mb-3">
                            <div className="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                                <div className="list-card-image">
                                    <div className="popular-slider slick-initialized slick-slider">
                                        <div className="slick-list draggable" style={{ padding: '0px 30px' }}><div className="slick-track" style={{ opacity: 1, width: 2528, transform: 'translate3d(-632px, 0px, 0px)' }}><div className="osahan-slider-item slick-slide slick-cloned" data-slick-index={-2} aria-hidden="true" style={{ width: 316 }} tabIndex={-1}>
                                            <a href="restaurant.html" tabIndex={-1}>
                                                <img alt="#" src="img/popular5.png" className="img-fluid item-img w-100 rounded" />
                                            </a>
                                        </div><div className="osahan-slider-item slick-slide slick-cloned" data-slick-index={-1} aria-hidden="true" style={{ width: 316 }} tabIndex={-1}>
                                                <a href="restaurant.html" tabIndex={-1}>
                                                    <img alt="#" src="img/popular6.png" className="img-fluid item-img w-100 rounded" />
                                                </a>
                                            </div><div className="osahan-slider-item slick-slide slick-current slick-active slick-center" data-slick-index={0} aria-hidden="false" style={{ width: 316 }} tabIndex={0}>
                                                <a href="restaurant.html" tabIndex={0}>
                                                    <img alt="#" src="img/popular4.png" className="img-fluid item-img w-100 rounded" />
                                                </a>
                                            </div><div className="osahan-slider-item slick-slide" data-slick-index={1} aria-hidden="true" style={{ width: 316 }} tabIndex={-1}>
                                                <a href="restaurant.html" tabIndex={-1}>
                                                    <img alt="#" src="img/popular5.png" className="img-fluid item-img w-100 rounded" />
                                                </a>
                                            </div><div className="osahan-slider-item slick-slide" data-slick-index={2} aria-hidden="true" style={{ width: 316 }} tabIndex={-1}>
                                                <a href="restaurant.html" tabIndex={-1}>
                                                    <img alt="#" src="img/popular6.png" className="img-fluid item-img w-100 rounded" />
                                                </a>
                                            </div><div className="osahan-slider-item slick-slide slick-cloned" data-slick-index={3} aria-hidden="true" style={{ width: 316 }} tabIndex={-1}>
                                                <a href="restaurant.html" tabIndex={-1}>
                                                    <img alt="#" src="img/popular4.png" className="img-fluid item-img w-100 rounded" />
                                                </a>
                                            </div><div className="osahan-slider-item slick-slide slick-cloned slick-center" data-slick-index={4} aria-hidden="true" style={{ width: 316 }} tabIndex={-1}>
                                                <a href="restaurant.html" tabIndex={-1}>
                                                    <img alt="#" src="img/popular5.png" className="img-fluid item-img w-100 rounded" />
                                                </a>
                                            </div><div className="osahan-slider-item slick-slide slick-cloned" data-slick-index={5} aria-hidden="true" style={{ width: 316 }} tabIndex={-1}>
                                                <a href="restaurant.html" tabIndex={-1}>
                                                    <img alt="#" src="img/popular6.png" className="img-fluid item-img w-100 rounded" />
                                                </a>
                                            </div></div></div>
                                    </div>
                                </div>
                                <div className="p-3 position-relative">
                                    <div className="list-card-body">
                                        <h6 className="mb-1"><a href="restaurant.html" className="text-black">Conrad Chicago Restaurant
                                        </a>
                                        </h6>
                                        <p className="text-gray mb-3">North • Hamburgers • Pure veg</p>
                                        <p className="text-gray mb-3 time"><span className="bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2"><i className="feather-map-pin" /> 15–25 min</span> <span className="float-right text-black-50"> $500 FOR TWO</span></p>
                                    </div>
                                    <div className="list-card-badge">
                                        <span className="badge badge-danger">OFFER</span> <small>65% OSAHAN50</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default MostPopular
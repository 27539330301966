import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import AuthSlice from './AuthSlice';
import AlertSlice from './AlertSlice';
import LocationSlice from './LocationSlice';
import FilterSlice from './FilterSlice';
import CitySlice from './CitySlice';
import SettingSlice from './SettingSlice';
import LoadSlice from './LoadSlice';
import SortingSlice from './SortingSlice';
import RatingSlice from './RatingSlice';
import RelateItemSlice from './RelatedItems';
import AmenitiesSlice from './Amenities';





const reducers = combineReducers({
    Auth: AuthSlice,
    Alert: AlertSlice,
    Location: LocationSlice,
    Filter: FilterSlice,
    Sort:SortingSlice,
    Rating: RatingSlice,
    RelateItem: RelateItemSlice,
    City: CitySlice,
    Setting: SettingSlice,
    Load: LoadSlice,
    Amenity: AmenitiesSlice
});

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
    devTools: process.env.NODE_ENV !== 'production',
});

export default store;
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
priceSort:'Price Low to High'
}

const SortingSlice = createSlice({
    name: 'sorting',
    initialState,
    reducers: {
        setSorting(state, action) {
            const { priceSort } = action.payload;
            state.priceSort = priceSort;        },
        // removeFilter(state, action) {
        //     state.product = null;
        //     state.rating = null;
        //     state.startPrice = null;
        //     state.endPrice = null;
        // }
    }
});

export const { setSorting } = SortingSlice.actions;
export default SortingSlice.reducer;
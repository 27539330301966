export const ServerURL = 'https://activeapi.skylinxtech.com';
// export const ServerURL = 'http://localhost:5000';
export const URLForImages = 'https://activeweb.skylinxtech.com'

export const RegisterApi = `${ServerURL}/api/user/register`;
export const LoginAPI = `${ServerURL}/api/user/login`;
export const FacebookLoginAPI = `${ServerURL}/api/user/facebookLogin`;
export const GoogleLoginAPI = `${ServerURL}/api/user/googleLogin`;
export const TrendingProductAPI = `${ServerURL}/api/product`;
export const AllProductListAPI = `${ServerURL}/api/product/list`;
export const MostRatedRestaurantsAPI = `${ServerURL}/api/restaurant/most-rated-list`;
export const MostRatedBranchesAPI = `${ServerURL}/api/restaurant/branches`;
export const SingleRestaurantAPI = `${ServerURL}/api/restaurant/detail`;
export const SingleRestaurantRatingAPI = `${ServerURL}/api/rating/rating-by-restaurant`;
export const ContactAPI = `${ServerURL}/api/contact/submit`;
export const BannerAPI = `${ServerURL}/api/banners`;
export const MostRatedBranchesByCityAPI = `${ServerURL}/api/restaurant/most-rated-by-city`;
export const MostRatedBranchesAllAPI = `${ServerURL}/api/restaurant/most-rated-all`;
export const RateProductAPI = `${ServerURL}/api/rating/add`;
export const GetMenuCategoriesAPI = `${ServerURL}/api/menuCategory/get`;
export const GetMenuCategoriesByRestaurantAPI = `${ServerURL}/api/menuCategory`;
export const GetMenusByCategoryAPI = `${ServerURL}/api/menu/list`;
export const GetMenusByCategoryIdAPI = `${ServerURL}/api/menu`;
export const GetProductByMenuIdAPI = `${ServerURL}/api/product/menu`;
export const GetProductByIdAPI = `${ServerURL}/api/product/detail`;
export const GetCitiesAPI = `${ServerURL}/api/city/list`;
export const GetAmenitiesAPI = `${ServerURL}/api/amenity/get`;
export const GetSettingAPI = `${ServerURL}/api/setting`;
export const favouriteProductAPI = `${ServerURL}/api/favourite-item`;
export const favouriteRestaurantAPI = `${ServerURL}/api/favourite-restaurant`;

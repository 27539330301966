import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { URLForImages, BannerAPI } from '../../Apis/Apis';
import { useNavigate } from 'react-router-dom';
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react';
import './Offers.css';
import Aos from 'aos'
import 'aos/dist/aos.css'
SwiperCore.use([Navigation]);

// var yourImg = document.getElementById('yourImgId');
// if(yourImg && yourImg.style > 500) { /*  image actual size above 1000px then set below height and width */
//     yourImg.style.height = '200px';
//     yourImg.style.top = '20px';
//     yourImg.style.bottom = '20px';
//     yourImg.style.width = '200px';
// }
// else if (yourImg && yourImg.style < 1000) /*  image actual size below  1000px then set below height and width */
// {
//   yourImg.style.height = '200px';
//     yourImg.style.width = '200px';

// }
const Offers = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    useEffect(() => {
        Aos.init({duration:700});
        }, [])

    useEffect(() => {
        getBannerImg();
    }, []);

    const getBannerImg = async () => {
        try {
            const { data } = await axios.get(BannerAPI);
            setData(data.banners.filter(active => active.status && !active.deleted_at ));
            // console.log(data.banners.filter(banner=>!banner.deleted_at))
            // console.log(data.banners.filter(active => active.status && !active.deleted_at ),'baloch')
            // console.log('baloch')
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div >
            <div className="container-fluid p-0 offers">
                {/* <h5 data-aos="fade-left">Banners</h5> */}
                <div style={{ position: "relative"}}>
                <Swiper
                  style={{ position: "unset"}}
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    navigation
                    loop={true}
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                            spaceBetween: 5,
                        },
                        480: {
                            slidesPerView: 1,
                            spaceBetween: 5,
                        },
                        768: {
                            slidesPerView: 1,
                            spaceBetween: 5,
                        },
                        1024: {
                            slidesPerView: 1,
                            spaceBetween: 5,
                        },
                        1280: {
                            slidesPerView: 1,
                            spaceBetween: 5,
                        },
                    }}
                >
                    {
                        data?.length > 0 ? data?.map((item, index) => {
                            return <SwiperSlide key={index}>
                                <div id='yourImgId' className="cat-item " style={{ width: 'auto' }}>
                                    <a className="d-block text-center shadow-sm " href={`${item?.redirect_url}`} tabIndex={-1}>
                                        <img   alt="#" src={`${URLForImages}/${item?.file_id?.file_path}`} className="banner-image-projection"   />
                                    <button  className='btn btn-danger button-banner' href={`${item?.redirect_url}`}> Visit Our Restaurant</button>
                                    </a>
                                </div>
                            </SwiperSlide>
                            
                        })
                            :
                            [1, 2, 3, 4].map((item, index) => {
                                return <SwiperSlide key={index} >
                                    <div className="cat-item px-1 py-3" style={{ width: 'auto' }}>
                                        <a className="d-block text-center shadow-sm" tabIndex={-1}>
                                            <img alt="#" src='img/landing1.png' className=" banner-image-projection"   />
                                        </a>
                                    </div>
                                </SwiperSlide>
                            })
                    }
                </Swiper>
                </div>
            </div>
        </div>
    )
}

export default Offers;
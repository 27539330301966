import React from 'react';

const Faq = () => {
    return (
        <div className="osahan-profile">
            <div className="d-none">
                <div className="bg-primary border-bottom p-3 d-flex align-items-center">
                    <a className="toggle togglew toggle-2" href="#"><span /></a>
                    <h4 className="font-weight-bold m-0 text-white">Profile</h4>
                </div>
            </div>
            {/* profile */}
            <div className="container position-relative">
                <div className="py-5 osahan-profile row">
                    <div className="col-md-4 mb-3">
                        <div className="bg-white rounded shadow-sm sticky_sidebar overflow-hidden">
                            <a href="profile.html" className>
                                <div className="d-flex align-items-center p-3">
                                    <div className="left mr-3">
                                        <img alt="#" src="img/user1.jpg" className="rounded-circle" />
                                    </div>
                                    <div className="right">
                                        <h6 className="mb-1 font-weight-bold">Gurdeep Singh <i className="feather-check-circle text-success" /></h6>
                                        <p className="text-muted m-0 small">iamosahan@gmail.com</p>
                                    </div>
                                </div>
                            </a>
                            <div className="osahan-credits d-flex align-items-center p-3 bg-light">
                                <p className="m-0">Accounts Credits</p>
                                <h5 className="m-0 ml-auto text-primary">$52.25</h5>
                            </div>
                            {/* profile-details */}
                            <div className="bg-white profile-details">
                                <a data-toggle="modal" data-target="#paycard" className="d-flex w-100 align-items-center border-bottom p-3">
                                    <div className="left mr-3">
                                        <h6 className="font-weight-bold mb-1 text-dark">Payment Cards</h6>
                                        <p className="small text-muted m-0">Add a credit or debit card</p>
                                    </div>
                                    <div className="right ml-auto">
                                        <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                                    </div>
                                </a>
                                <a data-toggle="modal" data-target="#exampleModal" className="d-flex w-100 align-items-center border-bottom p-3">
                                    <div className="left mr-3">
                                        <h6 className="font-weight-bold mb-1 text-dark">Address</h6>
                                        <p className="small text-muted m-0">Add or remove a delivery address</p>
                                    </div>
                                    <div className="right ml-auto">
                                        <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                                    </div>
                                </a>
                                <a className="d-flex align-items-center border-bottom p-3" data-toggle="modal" data-target="#inviteModal">
                                    <div className="left mr-3">
                                        <h6 className="font-weight-bold mb-1">Refer Friends</h6>
                                        <p className="small text-primary m-0">Get $10.00 FREE</p>
                                    </div>
                                    <div className="right ml-auto">
                                        <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                                    </div>
                                </a>
                                <a href="faq.html" className="d-flex w-100 align-items-center border-bottom px-3 py-4">
                                    <div className="left mr-3">
                                        <h6 className="font-weight-bold m-0 text-dark"><i className="feather-truck bg-danger text-white p-2 rounded-circle mr-2" /> Delivery Support</h6>
                                    </div>
                                    <div className="right ml-auto">
                                        <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                                    </div>
                                </a>
                                <a href="contact-us.html" className="d-flex w-100 align-items-center border-bottom px-3 py-4">
                                    <div className="left mr-3">
                                        <h6 className="font-weight-bold m-0 text-dark"><i className="feather-phone bg-primary text-white p-2 rounded-circle mr-2" /> Contact</h6>
                                    </div>
                                    <div className="right ml-auto">
                                        <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                                    </div>
                                </a>
                                <a href="terms.html" className="d-flex w-100 align-items-center border-bottom px-3 py-4">
                                    <div className="left mr-3">
                                        <h6 className="font-weight-bold m-0 text-dark"><i className="feather-info bg-success text-white p-2 rounded-circle mr-2" /> Term of use</h6>
                                    </div>
                                    <div className="right ml-auto">
                                        <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                                    </div>
                                </a>
                                <a href="privacy.html" className="d-flex w-100 align-items-center px-3 py-4">
                                    <div className="left mr-3">
                                        <h6 className="font-weight-bold m-0 text-dark"><i className="feather-lock bg-warning text-white p-2 rounded-circle mr-2" /> Privacy policy</h6>
                                    </div>
                                    <div className="right ml-auto">
                                        <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 mb-3">
                        <div className="osahan-cart-item-profile">
                            <div className="box bg-white mb-3 shadow-sm rounded">
                                <div className="p-3 d-flex align-items-center">
                                    <i className="feather-message-circle display-4" />
                                    <div className="ml-3">
                                        <h6 className="text-dark mb-2 mt-0">Help Forum</h6>
                                        <p className="mb-0 text-muted">Find the answer to any question, from the basics ...
                                        </p>
                                    </div>
                                </div>
                                <div className="overflow-hidden border-top d-flex align-items-center p-3">
                                    <a className="font-weight-bold d-block" href="#"> Swiggiweb Help Forum. </a>
                                    <i className="feather-arrow-right-circle ml-auto text-primary" />
                                </div>
                            </div>
                            <div className="box bg-white mb-3 shadow-sm rounded">
                                <div className="p-3 d-flex align-items-center">
                                    <i className="feather-lock display-4" />
                                    <div className="ml-3">
                                        <h6 className="text-dark mb-2 mt-0">Safety Center</h6>
                                        <p className="mb-0 text-muted">Want to learn more about setting up and managing ...
                                        </p>
                                    </div>
                                </div>
                                <div className="overflow-hidden border-top d-flex align-items-center p-3">
                                    <a className="font-weight-bold d-block" href="#"> Swiggiweb Safety Center. </a>
                                    <i className="feather-arrow-right-circle ml-auto text-primary" />
                                </div>
                            </div>
                            <div id="basics">
                                {/* Title */}
                                <div className="mb-2 mt-3">
                                    <h5 className="font-weight-semi-bold mb-0">Basics</h5>
                                </div>
                                {/* End Title */}
                                {/* Basics Accordion */}
                                <div id="basicsAccordion">
                                    {/* Card */}
                                    <div className="box border-bottom bg-white mb-2 rounded shadow-sm overflow-hidden">
                                        <div id="basicsHeadingOne">
                                            <h5 className="mb-0">
                                                <button className="shadow-none btn btn-block d-flex justify-content-between card-btn p-3 collapsed" data-toggle="collapse" data-target="#basicsCollapseOne" aria-expanded="false" aria-controls="basicsCollapseOne">
                                                    Do you have any built-in caching?
                                                    <span className="card-btn-arrow">
                                                        <span className="feather-chevron-down" />
                                                    </span>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="basicsCollapseOne" className="collapse show" aria-labelledby="basicsHeadingOne" data-parent="#basicsAccordion" style={{}}>
                                            <div className="card-body border-top p-3 text-muted">
                                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid ...
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box border-bottom bg-white mb-2 rounded shadow-sm overflow-hidden">
                                        <div id="basicsHeadingTwo">
                                            <h5 className="mb-0">
                                                <button className="shadow-none btn btn-block d-flex justify-content-between card-btn p-3 collapsed" data-toggle="collapse" data-target="#basicsCollapseTwo" aria-expanded="false" aria-controls="basicsCollapseTwo">
                                                    Can I add/upgrade my plan at any time?
                                                    <span className="card-btn-arrow">
                                                        <span className="feather-chevron-down" />
                                                    </span>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="basicsCollapseTwo" className="collapse" aria-labelledby="basicsHeadingTwo" data-parent="#basicsAccordion" style={{}}>
                                            <div className="card-body border-top p-3 text-muted">
                                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid ...
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box border-bottom bg-white mb-2 rounded shadow-sm overflow-hidden">
                                        <div id="basicsHeadingThree">
                                            <h5 className="mb-0">
                                                <button className="shadow-none btn btn-block d-flex justify-content-between card-btn p-3 collapsed" data-toggle="collapse" data-target="#basicsCollapseThree" aria-expanded="false" aria-controls="basicsCollapseThree">
                                                    What access comes with hosting plan?
                                                    <span className="card-btn-arrow">
                                                        <span className="feather-chevron-down" />
                                                    </span>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="basicsCollapseThree" className="collapse" aria-labelledby="basicsHeadingThree" data-parent="#basicsAccordion" style={{}}>
                                            <div className="card-body border-top p-3 text-muted">
                                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid ...
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box border-bottom bg-white mb-2 rounded shadow-sm overflow-hidden">
                                        <div id="basicsHeadingFour">
                                            <h5 className="mb-0">
                                                <button className="shadow-none btn btn-block d-flex justify-content-between card-btn collapsed p-3" data-toggle="collapse" data-target="#basicsCollapseFour" aria-expanded="false" aria-controls="basicsCollapseFour">
                                                    How do I change my password?
                                                    <span className="card-btn-arrow">
                                                        <span className="feather-chevron-down" />
                                                    </span>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="basicsCollapseFour" className="collapse" aria-labelledby="basicsHeadingFour" data-parent="#basicsAccordion">
                                            <div className="card-body border-top p-3 text-muted">
                                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid ...
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Card */}
                                </div>
                                {/* End Basics Accordion */}
                            </div>
                            <div id="account">
                                {/* Title */}
                                <div className="mb-2 mt-3">
                                    <h5 className="font-weight-semi-bold mb-0">Account</h5>
                                </div>
                                {/* End Title */}
                                {/* Account Accordion */}
                                <div id="accountAccordion">
                                    {/* Card */}
                                    <div className="box border-bottom bg-white mb-2 rounded shadow-sm overflow-hidden">
                                        <div id="accountHeadingOne">
                                            <h5 className="mb-0">
                                                <button className="shadow-none btn btn-block d-flex justify-content-between card-btn p-3" data-toggle="collapse" data-target="#accountCollapseOne" aria-expanded="false" aria-controls="accountCollapseOne">
                                                    How do I change my password?
                                                    <span className="card-btn-arrow">
                                                        <span className="feather-chevron-down" />
                                                    </span>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="accountCollapseOne" className="collapse show" aria-labelledby="accountHeadingOne" data-parent="#accountAccordion">
                                            <div className="card-body border-top p-3 text-muted">
                                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid ...
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Card */}
                                    {/* Card */}
                                    <div className="box border-bottom bg-white mb-2 rounded shadow-sm overflow-hidden">
                                        <div id="accountHeadingTwo">
                                            <h5 className="mb-0">
                                                <button className="shadow-none btn btn-block d-flex justify-content-between card-btn collapsed p-3" data-toggle="collapse" data-target="#accountCollapseTwo" aria-expanded="false" aria-controls="accountCollapseTwo">
                                                    How do I delete my account?
                                                    <span className="card-btn-arrow">
                                                        <span className="feather-chevron-down" />
                                                    </span>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="accountCollapseTwo" className="collapse" aria-labelledby="accountHeadingTwo" data-parent="#accountAccordion">
                                            <div className="card-body border-top p-3 text-muted">
                                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid ...
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Card */}
                                    {/* Card */}
                                    <div className="box border-bottom bg-white mb-2 rounded shadow-sm overflow-hidden">
                                        <div id="accountHeadingThree">
                                            <h5 className="mb-0">
                                                <button className="shadow-none btn btn-block d-flex justify-content-between card-btn collapsed p-3" data-toggle="collapse" data-target="#accountCollapseThree" aria-expanded="false" aria-controls="accountCollapseThree">
                                                    How do I change my account settings?
                                                    <span className="card-btn-arrow">
                                                        <span className="feather-chevron-down" />
                                                    </span>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="accountCollapseThree" className="collapse" aria-labelledby="accountHeadingThree" data-parent="#accountAccordion">
                                            <div className="card-body border-top p-3 text-muted">
                                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid ...
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Card */}
                                    {/* Card */}
                                    <div className="box border-bottom bg-white mb-2 rounded shadow-sm overflow-hidden">
                                        <div id="accountHeadingFour">
                                            <h5 className="mb-0">
                                                <button className="shadow-none btn btn-block d-flex justify-content-between card-btn collapsed p-3" data-toggle="collapse" data-target="#accountCollapseFour" aria-expanded="false" aria-controls="accountCollapseFour">
                                                    I forgot my password. How do I reset it?
                                                    <span className="card-btn-arrow">
                                                        <span className="feather-chevron-down" />
                                                    </span>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="accountCollapseFour" className="collapse" aria-labelledby="accountHeadingFour" data-parent="#accountAccordion">
                                            <div className="card-body border-top p-3 text-muted">
                                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid ...
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Card */}
                                </div>
                                {/* End Account Accordion */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer */}
        </div>
    )
}

export default Faq;
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import "./Map.css";
import axios from "axios";
import { useDispatch } from "react-redux";

import {
  URLForImages,
  TrendingProductAPI,
  GetAmenitiesAPI,
} from "../../Apis/Apis";
import CircularProgress from "@mui/material/CircularProgress";
import { getDistanceFromLatLonInKm } from "../../func";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";

import { Link, useNavigate } from "react-router-dom";
import { GOOGLE_MAPAPI } from "../../config";
import { setRelateItems } from "../../Redux/RelatedItems";
import { red } from "@mui/material/colors";
import { setAmenty } from "../../Redux/Amenities";
import { updateLocale } from "moment/moment";

const MapContainer = (props) => {

  const dispatch = useDispatch();

  const coords = useSelector((state) => state?.Location?.coords);
  const Filter = useSelector((state) => state?.Filter);
  const SortFilter = useSelector((state) => state?.Sort);
  const RatingFilter = useSelector((state) => state?.Rating);
  const AmenitiesSelect = useSelector(
    (state) => state?.Amenity.AmenitiesFilterValues
  );
  console.log('data from------------>>',AmenitiesSelect);
  const { priceSort } = SortFilter;
  const { RatingsSort } = RatingFilter;

  localStorage.setItem("priceSort", priceSort);
  localStorage.setItem("RatingFilter", RatingsSort);
  const navigate = useNavigate();
  const [emenSearch, setemenSearch] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [searchData, setData] = useState([]);
  const [appyfilter, setappyfilter] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [amenitiesData, setAmenitiesData] = useState([]);
  const [openWindow, setOpenWindow] = useState(false);
  const [mapInstance, setMapInstance] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [infoData, setInfoData] = useState();
  const [testData, setTestData] = useState([]);
  const [nameArray, setNameArray] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
const [coun, setcoun] = useState(0)
  const START_RATING = {
    0: 0,
    1: 1,
    3: 3,
    4: 4,
    5: 5,
  };

  const _coords = useMemo(() => {
    return {
      lat: (coords?.latitude && coords?.latitude) || "",
      lng: (coords?.longitude && coords?.longitude) || "",
    };
  }, [coords]);

  useEffect(() => {
    const _newSearchData = [...searchData];
    if (_newSearchData) {
      _newSearchData.push({
        branch_id: { latitude: coords?.latitude, longitude: coords?.longitude },
      });
    }

    if (window.google !== undefined) {
      const bounds = new window.google.maps.LatLngBounds();
      for (let item of _newSearchData) {
        const marker = item?.branch_id;
        const newPoint = new window.google.maps.LatLng(
          marker?.latitude,
          marker?.longitude
        );
        bounds.extend(newPoint);
      }
    }
  }, [searchData]);
  const AmenityIds = (AmenitiesSelect) => {
    let amenitiescpy = [...amenities];
    amenitiescpy = amenitiescpy.map((item) => {
      return AmenitiesSelect.map((item2) => {
        if (item._id == item2.value) {
          console.log(">>>", item);
        } else {
          console.log("chl ja bhai");
        }
      });
    });
    console.log("slect", amenitiescpy);
    setAmenities(amenitiescpy);
  };

  useEffect(() => {
    GetAmenitiesData((data) => {
      console.log("dara", data)
    });
    getData();

    AmenityIds(AmenitiesSelect);
  }, [Filter.product]);

  const getData = async () => {
    try {
      const { data } = await axios.get(
        `${TrendingProductAPI}/${Filter?.product}`
      );
      if (data.success === true) {
        setLoading(false);
        filterData(data?.products);
        setemenSearch(data?.products);
        console.log("AmenitiesSelect", AmenitiesSelect)
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };


  console.log("coun",coun)
  const filterData = (products) => {
    console.log("products",products)
    let filteredProducts = [];
    let amenitesArrange=[]
    AmenitiesSelect.forEach(element => {
      let values=element.value
      amenitesArrange=[...amenitesArrange,values]
    })
    if ((Filter.startPrice >= 0 && Filter.endPrice >= 0) || Filter.rating) {
      filteredProducts = products.filter((item) =>
        Filter.startPrice >= 0 && Filter.endPrice > 0
          ? item.price >= Filter.startPrice && item.price <= Filter.endPrice
          : item
      );
      filteredProducts = filteredProducts.filter((item2) =>
        Filter.rating > 0
          ? Math.round(item2.totalRatings) == Filter.rating
          : item2
      );

      setData(filteredProducts);
      setappyfilter(filteredProducts);
      dispatch(setRelateItems({ relatedItems: filteredProducts }));
      filterByEmenities(amenitesArrange,filteredProducts);
    } else {
      dispatch(setRelateItems({ relatedItems: products }));
      // setData(products);
      setappyfilter(products);
  
      filterByEmenities(amenitesArrange, products);

    }
    // let cond1 = Number(Filter?.rating) === 0 && (Number(Filter.startPrice) === 0) && (Number(Filter.startPrice) === 0);
    // let cond2 = Number(Filter?.rating) > 0 && (Number(Filter.startPrice) === 0) && (Number(Filter.endPrice) === 0);
    // let cond3 = Number(Filter?.rating) > 0 && (Number(Filter.startPrice) >= 0) && (Number(Filter.endPrice) > 0);

    // if (cond1) {
    //     setData(products);
    // }
    // else if (cond2) {
    //     const _data = products?.filter(item => Math.floor(item?.totalRatings) === Number(Filter?.rating));
    //     setData(_data);
    // }
    // else if (cond3) {
    //     const _data = products?.filter(item => Math.floor(item?.totalRatings) === Number(Filter?.rating) && (item?.price >= Number(Filter?.startPrice)) && (item?.price <= Number(Filter?.endPrice)));
    // }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };
  console.log("filter", RatingFilter);
  // const { RatingsSort } = RatingFilter

  const handleChange = async (e) => {
    const { value } = e.target;
    setSearch(value);
    if (value.length === 0) setData([]);
    if (value.length > 0) {
      setLoading(true);
      try {
        const { data } = await axios.get(`${TrendingProductAPI}/${value}`);
        if (data.success === true) {
          setLoading(false);
          filterHandler(data?.products, data?.products);
          setappyfilter(data?.products);
          filterByEmenities(nameArray,data?.products)
          // setemenSearch(data?.products)
          // setData(data?.products.filter(item => {
          //   return RatingsSort != 0 ? Math.round(item.totalRatings) == RatingsSort : item
          // }))
          
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  const optimizeVersion = useCallback(debounce(handleChange), []);

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  console.log("<<< outer", RatingFilter.RatingsSort);

  const filterHandler = (copySearchData, appyfilter) => {
    let priceSortcpy = localStorage.getItem("priceSort");
    let RatingFilter = localStorage.getItem("RatingFilter");

    console.log("<<< inner", RatingFilter);
    console.log("<<< inner appyfilter", appyfilter);

    copySearchData = appyfilter.filter((item) => {
      return parseInt(RatingFilter) != 0
        ? Math.round(item.totalRatings) == parseInt(RatingFilter)
        : item;
    });
    setData(copySearchData);
    dispatch(setRelateItems({ relatedItems: [...copySearchData] }));

    if (priceSortcpy == "Rating High to Low") {
      copySearchData = copySearchData.sort(
        (a, b) => parseFloat(b.totalRatings) - parseFloat(a.totalRatings)
      );
      setData(copySearchData);
      dispatch(setRelateItems({ relatedItems: [...copySearchData] }));
    }

    if (priceSortcpy == "Rating Low to High") {
      copySearchData = copySearchData.sort(
        (a, b) => parseFloat(a.totalRatings) - parseFloat(b.totalRatings)
      );
      setData(copySearchData);
      dispatch(setRelateItems({ relatedItems: [...copySearchData] }));
    }

    if (priceSortcpy == "Price High to Low") {
      copySearchData = copySearchData.sort(
        (a, b) => parseFloat(b.price) - parseFloat(a.price)
      );
      setData(copySearchData);
      dispatch(setRelateItems({ relatedItems: [...copySearchData] }));
    }
    if (priceSortcpy == "Price Low to High") {
      copySearchData = copySearchData.sort(
        (a, b) => parseFloat(a.price) - parseFloat(b.price)
      );
      setData(copySearchData);
      dispatch(setRelateItems({ relatedItems: [...copySearchData] }));
    }
    if (priceSortcpy == "Near to me") {
      let distance = copySearchData.map((item) => {
        return {
          ...item,
          distance: getDistanceFromLatLonInKm(
            coords?.latitude,
            coords?.longitude,
            item?.branch_id?.latitude,
            item?.branch_id?.longitude
          ),
        };
      });
      // console.log("distanse", distanse)
      copySearchData = distance.sort(
        (a, b) => parseFloat(a.distance) - parseFloat(b.distance)
      );
      // setData(copySearchData);
      filterByEmenities(nameArray,copySearchData)
      dispatch(setRelateItems({ relatedItems: [...copySearchData] }));
    }
  };

  useEffect(() => {
    let copySearchData = [].concat(searchData);
    filterHandler(copySearchData, appyfilter);
  }, [SortFilter, RatingFilter]);

  const onMarkerClick = (props, marker, item) => {
    if (showingInfoWindow) {
      item.stopPropagation();
      return;
    } else {
      setActiveMarker(marker);
      setSelectedPlace(props);
      setShowingInfoWindow(true);
      setInfoData(item);
    }
  };
  const onInfoWindowClose = () => {
    setActiveMarker(null);

    setShowingInfoWindow(false);
  };
  const onMapClicked = () => {
    if (showingInfoWindow) setActiveMarker(null);
    setShowingInfoWindow(false);
  };

  let amenitiessample = []
  const GetAmenitiesData = async (callback) => {
    try {
      const { data } = await axios.get(GetAmenitiesAPI);
      // setAmenities(data?.amenities.filter((amenity) => !amenity.deleted_at));
      amenitiessample = data?.amenities.filter((amenity) => !amenity.deleted_at)
      callback(amenitiessample)
      amenitiessample.map((amenList) => {
        AmenitiesSelect.map((item) => Object.assign(amenList, { select: amenList._id == item.value && true || false }))
      })
      setAmenities(amenitiessample)

    } catch (error) {
      console.log(error);
    }
  };

  const filterByEmenities = (filterArray,products) => {
    console.log("filterByEmenities",filterArray,products)
    console.log("filterArray",filterArray,products,emenSearch)
    if (filterArray.length>0) {
      const filteredData = [];
      setNameArray(filterArray)
      for (const item of products) {
        const innerArray = item.restaurant_id.amenities_id;
        if (filterArray.every(elem => innerArray.includes(elem))) {
          console.log("amenities",amenities )
          filteredData.push(item);
        }
      }
      setData(filteredData)     
    }
    else{
      console.log("products",products)
      setData(products)
    }
 
  };

  const handleFilterAmenities = (id, e) => {
    e.stopPropagation();
    let selectedAmenities = [];
    if (nameArray.includes(id)) {
      selectedAmenities = nameArray.filter((item) => item !== id);
      setNameArray(selectedAmenities);
    } else {
      selectedAmenities = [...nameArray, id];
      setNameArray(selectedAmenities);
    }
    filterByEmenities(selectedAmenities,emenSearch);
  };

  return (
    <>
      <div id="wrapper">
        {/* Sidebar */}
        <div id="sidebar-wrapper">
          <div>
            <ul className="sidebar-nav" id="side_bar">
              <li
                className="sidebar-brand"
                style={{ fontWeight: "bold", color: "#999999" }}
              >
                {/* <h1>Places</h1> */}
                <div className="search " style={{ width: "334px" }}>
                  {console.log("searchData", searchData)}
                  <div className="input-group mb-1">
                    <input
                      defaultValue={search === "" ? Filter?.product : search}
                      onChange={optimizeVersion}
                      type="text"
                      className="form-control form-control-lg input_search border-right-0"
                      id="inlineFormInputGroup"
                      placeholder="Search Product"
                    />
                    <div className="input-group-prepend">
                      <div className="btn input-group-text bg-white border_search border-left-0 text-primary">
                        <i className="feather-search" />
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              {/* <li className="">
              <div >
              <a href="#" data-toggle="modal" data-target="#filters-1" className="ml-auto btn btn-primary">Amenties Filters</a>
              </div>
              <hr className="mt-0" />.filter(active => active.status && !active.deleted_at )
                  .map((item, index) => {
            </li> */}
              {searchData.length > 0 && loading === false ? (
                searchData?.map((item, index) => {
                  // console.log(item?.restaurant_id?.amenities_id?.filter(item => { return nameArray.includes(item) }), "majidbaloch");
                  return (
                    <li
                      onClick={() => navigate(`/product?id=${item?._id}`)}
                      style={{ cursor: "pointer" }}
                      key={index}
                    >
                      <div className="listing_item">
                        {console.log("amenitiescpy", amenities)}
                        <div className="content_image_flex">
                          <div>
                            <Link
                              to={`/product?id=${item?._id}`}
                              className="item_name text-capitalize"
                              style={{ color: "#DF314E" }}
                            >
                              {item?.name}
                            </Link>
                            <div className="d-flex align-center">
                              <li>
                                <i
                                  className={
                                    item?.totalRatings === 0 ||
                                      item?.totalRatings === null
                                      ? "fa fa-star"
                                      : "fa fa-star text-warning"
                                  }
                                />
                                <i
                                  className={
                                    Math.round(item?.totalRatings) < 2
                                      ? "fa fa-star"
                                      : "fa fa-star text-warning"
                                  }
                                />
                                <i
                                  className={
                                    Math.round(item?.totalRatings) < 3
                                      ? "fa fa-star"
                                      : "fa fa-star text-warning"
                                  }
                                />
                                <i
                                  className={
                                    Math.round(item?.totalRatings) < 4
                                      ? "fa fa-star"
                                      : "fa fa-star text-warning"
                                  }
                                />
                                <i
                                  className={
                                    Math.round(item?.totalRatings) < 5
                                      ? "fa fa-star"
                                      : "fa fa-star text-warning"
                                  }
                                />
                              </li>
                              {/* <span className="ml-2">
                                ({item?.ratings?.peopleRated})
                              </span> */}
                            </div>
                            <div>
                              <span
                                className="address text-capitalize font-weight-bold"
                                style={{ fontSize: "14px" }}
                              >
                                {item?.restaurant_id?.name}
                              </span>
                            </div>
                            <div>
                              <span className="text-capitalize">
                                {item?.branch_id?.address} (
                                {getDistanceFromLatLonInKm(
                                  coords?.latitude,
                                  coords?.longitude,
                                  item?.branch_id?.latitude,
                                  item?.branch_id?.longitude
                                )}
                                Km)
                              </span>
                            </div>
                          </div>
                          <div className="content_image">
                            <img
                              src={`${URLForImages}/${item?.file_id?.file_path}`}
                              alt="Food Image"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                  "https://images.unsplash.com/photo-1546069901-ba9599a7e63c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8&w=1000&q=80";
                              }}
                            />
                          </div>
                        </div>
                        <div className="review_flex ">
                          <div className="review_content">
                            <p className="mb-0">
                              <b>Price : {item?.price} $ </b>
                            </p>
                            <p style={{ marginBottom: 0 }}>
                              {item?.description}
                            </p>
                          </div>
                        </div>
                      </div>
                      {searchData?.length - 1 !== index && (
                        <hr style={{ margin: 0 }} />
                      )}
                    </li>
                  );
                })
              ) : (
                <h6 className="text-center my-4 text-danger">
                  {loading === true ? (
                    <CircularProgress size="2rem" />
                  ) : (
                    <h1></h1>
                  )}
                </h6>
              )}
            </ul>
          </div>
        </div>
        {/* Page Content */}
        <div id="page-content-wrapper">
          <div className="container-fluid" style={{ padding: 0 }}>
            <div className="row" style={{ margin: 0 }}>
              <div className="col-lg-12" style={{ padding: 0 }}>
                <h1 style={{ display: "none" }}>
                  <a
                    href="#menu-toggle"
                    className="gradient-menu"
                    id="menu-toggle"
                  />
                  &nbsp;Map&nbsp;
                </h1>
                {console.log("_coords", _coords)}
                <div
                  id="map_canvas"
                  style={{
                    height: "100vh",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  {/* <Map
                    google={props.google}
                    zoom={12}
                    style={containerStyle}
                    initialCenter={_coords}
                  >
                    {searchData?.map((item, index) => {
                      return (
                        <Marker
                          onMouseover={() => setOpenWindow(true)}
                          key={index}
                          position={{
                            lat: item?.branch_id?.latitude,
                            lng: item?.branch_id?.longitude,
                          }}
                        />
                          
                     
                      );
                    }
                    )}

                    <Marker
                      position={{
                        lat: 31.4613587,
                        lng: 74.2780832,
                      }}
                      icon={{
                        url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                      }}
                      title='dkdkffkfk'
                      text="My Marker"
                    />
                        
                   
                  </Map> */}
                  <Map
                    google={props.google}
                    zoom={12}
                    style={containerStyle}
                    initialCenter={_coords}
                    className="map"
                    onClick={onMapClicked}
                  >
                    {searchData?.map((item, index) => {
                      return (
                        <Marker
                          name="Marker 1"
                          onMouseover={(props, marker) =>
                            onMarkerClick(props, marker, item)
                          }
                          onMouseout={() => {
                            setShowingInfoWindow(false);
                            setActiveMarker(null);
                          }}
                          key={index}
                          position={{
                            lat: item?.branch_id?.latitude,
                            lng: item?.branch_id?.longitude,
                          }}
                        />
                      );
                    })}
                    <Marker
                      position={{
                        lat: 31.4613587,
                        lng: 74.2780832,
                      }}
                      icon={{
                        url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                      }}
                      title="Current Location"
                    />

                    <InfoWindow
                      marker={activeMarker}
                      onClose={onInfoWindowClose}
                      visible={showingInfoWindow}
                    >
                      <div className="">
                        <div className="map-info-window">
                          {" "}
                          <h4>{infoData?.name}</h4>
                          <img
                            src={`${URLForImages}/${infoData?.file_id?.file_path}`}
                            style={{
                              width: "250px",
                              height: "150px",
                              marginBottom: "10px",
                            }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src =
                                "https://images.unsplash.com/photo-1546069901-ba9599a7e63c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8&w=1000&q=80";
                            }}
                          />
                        </div>

                        <div className="d-flex align-center mt-1">
                          <i
                            className={
                            infoData?.totalRatings === 0 ||
                                infoData?.totalRatings === null
                                ? "fa fa-star"
                                : "fa fa-star text-warning"
                            }
                          />
                          <i
                            className={
                              Math.round(infoData?.totalRatings) < 2
                                ? "fa fa-star"
                                : "fa fa-star text-warning"
                            }
                          />
                          <i
                            className={
                              Math.round(infoData?.totalRatings) < 3
                                ? "fa fa-star"
                                : "fa fa-star text-warning"
                            }
                          />
                          <i
                            className={
                              Math.round(infoData?.totalRatings) < 4
                                ? "fa fa-star"
                                : "fa fa-star text-warning"
                            }
                          />
                          <i
                            className={
                              Math.round(infoData?.totalRatings) < 5
                                ? "fa fa-star"
                                : "fa fa-star text-warning"
                            }
                          />
                          {/* <span className="ml-2 font-weight-bold">
                            ({infoData?.ratings?.peopleRated})
                          </span> */}
                        </div>

                        <div className="mt-1 d-flex flex-column">
                          {/* <span className="text-capitalize font-weight-bold mt-2">
                            {infoData?.branch_id?.address}{" "}
                          </span> */}
                          <span className="mt-2">
                            {getDistanceFromLatLonInKm(
                              coords?.latitude,
                              coords?.longitude,
                              infoData?.branch_id?.latitude,
                              infoData?.branch_id?.longitude
                            )}
                            Km from your location
                          </span>
                        </div>
                        <div>
                          <div className="review_flex ">
                            <div className="review_content">
                              <p className="mb-0 mt-1">
                                <b>Price : {infoData?.price} $ </b>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </InfoWindow>
                  </Map>

                  {/* {
                                    <LoadScript
                                        googleMapsApiKey="AIzaSyDaLspkx8QhwGqLwpeHBYddGCdJ7fPVXas"
                                    >
                                        <GoogleMap
                                            mapContainerStyle={containerStyle}
                                            center={_coords}
                                            zoom={14}
                                            onLoad={(map) => setTimeout(() => setMapInstance(map))}
                                            onClick={() => setOpenWindow(false)}
                                        >
                                            {
                                                mapInstance &&
                                                <Marker
                                                    position={{ lat: coords?.latitude, lng: coords?.longitude }}
                                                    icon={{ url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png" }}
                                                />
                                            }
                                            {
                                                (mapInstance && searchData.length > 0) && searchData?.map((item, index) => {
                                                    return <Marker
                                                        onMouseOver={() => setOpenWindow(true)}
                                                        onMouseOut={() => setOpenWindow(false)}
                                                        key={index}
                                                        position={{ lat: Number(item?.branch_id?.latitude), lng: Number(item?.branch_id?.longitude) }}
                                                    >
                                                        {
                                                            openWindow &&
                                                            <InfoWindowF
                                                                onCloseClick={() => setOpenWindow(false)}
                                                            >
                                                                <div style={{ minWidth: '200px', minHeight: '100px' }}>
                                                                    <h6 className='text-danger'>{item?.name}</h6>
                                                                    <div className='d-flex align-center'>
                                                                        <i className={(item?.totalRatings === 0 || item?.totalRatings === null) ? "feather-star" : "feather-star text-warning"} />
                                                                        <i className={item?.totalRatings < 2 ? "feather-star" : "feather-star text-warning"} />
                                                                        <i className={item?.totalRatings < 3 ? "feather-star" : "feather-star text-warning"} />
                                                                        <i className={item?.totalRatings < 4 ? "feather-star" : "feather-star text-warning"} />
                                                                        <i className={item?.totalRatings < 5 ? "feather-star" : "feather-star text-warning"} />
                                                                        <span className='ml-2 font-weight-bold'>
                                                                            ({item?.ratings?.peopleRated})
                                                                        </span>
                                                                    </div>
                                                                    <div className='mt-1 d-flex flex-column'>
                                                                        <span className="text-capitalize font-weight-bold">{item?.branch_id?.address}{" "}
                                                                        </span>
                                                                        <span className='mt-1'>
                                                                            {
                                                                                getDistanceFromLatLonInKm(coords?.latitude, coords?.longitude, item?.branch_id?.latitude, item?.branch_id?.longitude)
                                                                            }Km
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </InfoWindowF>
                                                        }
                                                    </Marker>
                                                })
                                            }
                                        </GoogleMap>
                                    </LoadScript>
                                } */}
                </div>
              </div>
            </div>
            {console.log("amenitiesamenities", nameArray)}
            <div className="amenities-row">
              {amenities.map((item, index) => {
                return (
                  <ul className="pl-0  ">
                    <li className="ml-1" key={index}>
                      <input
                        type="checkbox"
                        id={item._id}
                        className="input-label-bg"
                        onChange={(e) => handleFilterAmenities(item?._id, e)}
                      />
                      <label
                        for={item._id}
                        className={`font-size-label ${nameArray?.includes(item._id) && "bg-danger"}`}
                      >
                        <i className="fa fa-cutlery"></i>
                        <p className="mb-0">{item?.name}</p>
                      </label>
                    </li>
                  </ul>
                );
              })}
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="filters-1"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Filters</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body p-0">
                <div className="osahan-filter">
                  <div className="filter">
                    {/* SORT BY */}
                    <div className="p-3 bg-light border-bottom">
                      <h6 className="m-0">SORT BY</h6>
                    </div>
                    <div className="custom-control border-bottom px-0  custom-radio">
                      <input
                        type="radio"
                        id="customRadio1"
                        name="location"
                        className="custom-control-input"
                        defaultChecked
                      />
                      <label
                        className="custom-control-label py-3 w-100 px-3"
                        htmlFor="customRadio1"
                      >
                        Top Rated
                      </label>
                    </div>
                    <div className="custom-control border-bottom px-0  custom-radio">
                      <input
                        type="radio"
                        id="customRadio2"
                        name="location"
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label py-3 w-100 px-3"
                        htmlFor="customRadio2"
                      >
                        Nearest Me
                      </label>
                    </div>
                    <div className="custom-control border-bottom px-0  custom-radio">
                      <input
                        type="radio"
                        id="customRadio3"
                        name="location"
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label py-3 w-100 px-3"
                        htmlFor="customRadio3"
                      >
                        Cost High to Low
                      </label>
                    </div>
                    <div className="custom-control border-bottom px-0  custom-radio">
                      <input
                        type="radio"
                        id="customRadio4"
                        name="location"
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label py-3 w-100 px-3"
                        htmlFor="customRadio4"
                      >
                        Cost Low to High
                      </label>
                    </div>
                    <div className="custom-control border-bottom px-0  custom-radio">
                      <input
                        type="radio"
                        id="customRadio5"
                        name="location"
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label py-3 w-100 px-3"
                        htmlFor="customRadio5"
                      >
                        Most Popular
                      </label>
                    </div>
                    {/* Filter */}
                    <div className="p-3 bg-light border-bottom">
                      <h6 className="m-0">FILTER</h6>
                    </div>
                    <div className="custom-control border-bottom px-0  custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="defaultCheck1"
                        defaultChecked
                      />
                      <label
                        className="custom-control-label py-3 w-100 px-3"
                        htmlFor="defaultCheck1"
                      >
                        Open Now
                      </label>
                    </div>
                    <div className="custom-control border-bottom px-0  custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="defaultCheck2"
                      />
                      <label
                        className="custom-control-label py-3 w-100 px-3"
                        htmlFor="defaultCheck2"
                      >
                        Credit Cards
                      </label>
                    </div>
                    <div className="custom-control border-bottom px-0  custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="defaultCheck3"
                      />
                      <label
                        className="custom-control-label py-3 w-100 px-3"
                        htmlFor="defaultCheck3"
                      >
                        Alcohol Served
                      </label>
                    </div>
                    {/* Filter */}
                    <div className="p-3 bg-light border-bottom">
                      <h6 className="m-0">ADDITIONAL FILTERS</h6>
                    </div>
                    <div className="px-3 pt-3">
                      <input
                        type="range"
                        className="custom-range"
                        min={0}
                        max={100}
                        name="minmax"
                      />
                      <div className="form-row">
                        <div className="form-group col-6">
                          <label>Min</label>
                          <input
                            className="form-control"
                            placeholder="$0"
                            type="number"
                          />
                        </div>
                        <div className="form-group text-right col-6">
                          <label>Max</label>
                          <input
                            className="form-control"
                            placeholder="$1,0000"
                            type="number"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer p-0 border-0">
                <div className="col-6 m-0 p-0">
                  <a
                    href="#"
                    className="btn border-top btn-lg btn-block"
                    data-dismiss="modal"
                  >
                    Close
                  </a>
                </div>
                <div className="col-6 m-0 p-0">
                  <a href="#" className="btn btn-primary btn-lg btn-block">
                    Apply
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// export default Map;
export default GoogleApiWrapper({
  apiKey: GOOGLE_MAPAPI,
})(MapContainer);

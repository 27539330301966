import axios from 'axios';
import React, { useEffect, useState , CSSProperties} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { GetSettingAPI } from './Apis/Apis';
import './App.css';
import Checkout from './Components/Checkout/Checkout';
import ComingSoon from './Components/ComingSoon/ComingSoon';
import Contact from './Components/ContactUs/Contact';
import Faq from './Components/Faq/Faq';
import Favourite from './Components/Favourites/Favourite';
import Footer from './Components/Footer/Footer';
import ForgetPassword from './Components/ForgetPassword/ForgetPassword';
import Header from './Components/Header/Header';
import Home from './Components/Home/Home';
import Location from "./Components/Location/Location";
import Login from './Components/Login/Login';
import Maintainance from "./Components/Maintainance/Maintainance";
import Map from "./Components/Map/Map";
import MenuDetail from "./Components/MenuDetail/MenuDetail";
import MostPopular from "./Components/Most Popular/MostPopular";
import Offers from "./Components/Offers/Offers";
import Orders from "./Components/Orders/Orders";
import Successfull from "./Components/OrderSuccessfull/Successfull";
import Page404 from "./Components/Page404/Page404";
import Privacy from "./Components/Privacy/Privacy";
import ProductDetail from "./Components/ProductDetail/ProductDetail";
import Profile from "./Components/Profile/Profile";
import Restaurant from "./Components/Restaurant/Restaurant";
import Signup from "./Components/Signup/Signup";
import Cancelled from "./Components/StatusCancelled/Cancelled";
import Completed from "./Components/StatusCompleted/Completed";
import OnProcess from "./Components/StatusOnprocess/OnProcess";
import Terms from "./Components/Terms/Terms";
import Toast from './Components/Toast/Toast';
import Trending from "./Components/Trending/Trending";
import Verification from "./Components/Verification/Verification";
import { setSetting } from './Redux/SettingSlice';
// import ClipLoader from "react-spinners/ClipLoader";
import logo from "./Components/loaderLogo.png";





function App() {

  const [loading,setLoading] = useState(false);
  useEffect(() => {
   setLoading(true);
   setTimeout(() => {
    setLoading(false);
   }, 2000);
  }, [])
  
  const array = ['/forget-password', '/signup', '/verification'];
  const setting = useSelector(state => state.Setting.setting);
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0,0)
     }, [])
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = `${setting?.base_url}/${setting?.favicon_id?.file_path}`;
  }, []);

  useEffect(() => {
    getSetting();
  }, []);

  const getSetting = async () => {
    try {
      const { data } = await axios.get(GetSettingAPI);
      if (data.success === true) {
        dispatch(setSetting(data.settings[0]))
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
   {
    loading ?
    (
      <div  className="loader-image-logo">
       <img src={logo}
      className="rotate"
     
      />
      </div>
  
    ):
    <BrowserRouter>
    <Header/>
    <Toast />
    <Routes>
      <Route index path='/' element={<Home />} />
      <Route index path='/login' element={<Login />} />
      <Route index path='/forget-password' element={<ForgetPassword />} />
      <Route index path='/coming-soon' element={<ComingSoon />} />
      <Route index path='/contact' element={<Contact />} />
      <Route index path='/checkout' element={<Checkout />} />
      <Route index path='/faq' element={<Faq />} />
      <Route index path='/favourite' element={<Favourite />} />
      <Route index path='/location' element={<Location />} />
      <Route index path='/maintainance' element={<Maintainance />} />
      <Route index path='/map' element={<Map />} />
      <Route index path='/most-Popular' element={<MostPopular />} />
      <Route index path='/orders' element={<Orders />} />
      <Route index path='/offers' element={<Offers />} />
      <Route index path='/privacy' element={<Privacy />} />
      <Route index path='/profile' element={<Profile />} />
      <Route index path='/restaurant' element={<Restaurant />} />
      <Route index path='/signup' element={<Signup />} />
      <Route index path='/status-cencelled' element={<Cancelled />} />
      <Route index path='/status-completed' element={<Completed />} />
      <Route index path='/status-process' element={<OnProcess />} />
      <Route index path='/order-successfull' element={<Successfull />} />
      <Route index path='/terms' element={<Terms />} />
      <Route index path='/trending' element={<Trending />} />
      <Route index path='/verification' element={<Verification />} />
      <Route index path='/menu' element={<MenuDetail />} />
      <Route index path='/product' element={<ProductDetail />} />
      <Route index path='/*' element={<Page404 />} />
    </Routes> 
    {
      !array.includes(window.location.pathname) && <Footer />
    }
  </BrowserRouter>
   }
   
    </>
  );
}

export default App;
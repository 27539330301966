import { createSlice } from '@reduxjs/toolkit';
const win = window.sessionStorage;

const initialState = {
    load: false,
}

const LoadSlice = createSlice({
    name: 'load',
    initialState,
    reducers: {
        setLoad(state, action) {
            if (state.load === false) {
                state.load = true;
            }
            else if (state.load === true) {
                state.load = false;
            }
        },
    }
});

export const { setLoad } = LoadSlice.actions;
export default LoadSlice.reducer;
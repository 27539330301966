import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    relatedItems: []
}

const RelateItemSlice = createSlice({
    name: 'relatedItems',
    initialState,
    reducers: {
        setRelateItems(state, action) {
            const { relatedItems } = action.payload;
            state.relatedItems = relatedItems;
        },
    }
});

export const { setRelateItems } = RelateItemSlice.actions;
export default RelateItemSlice.reducer;
import React, { useState, useEffect } from "react";
import Popular from "./Popular";
import Sales from "./Sales";
import {
  URLForImages,
  MostRatedRestaurantsAPI,
  AllProductListAPI,
  MostRatedBranchesByCityAPI,
  MostRatedBranchesAllAPI,
  favouriteRestaurantAPI,
} from "../../Apis/Apis";
import axios from "axios";
import { getDistanceFromLatLonInKm } from "../../func";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { Skeleton } from "@mui/material";
import { AlertHandler } from "../../Common/AlertHandler";
import { setLoad } from "../../Redux/LoadSlice";
import "./Trending.css";
import Aos from "aos";
import "aos/dist/aos.css";
SwiperCore.use([Navigation]);

const Trending = () => {
  const navigate = useNavigate();
  const win = window.sessionStorage;
  const [data, setData] = useState([]);
  const [productList, setProductList] = useState([]);
  const [branches, setBranches] = useState([]);
  const location = useSelector((state) => state.Location);
  const city = useSelector((state) => state.City.city);
  const load = useSelector((state) => state.Load.load);
  const user = useSelector((state) => state.Auth.user);
  const dispatch = useDispatch();

  const [reload, setRelaod] = useState(false);
  const [ids, setIds] = useState([]);
  useEffect(() => {
    Aos.init({ duration: 700 });
  }, []);

  useEffect(() => {
    getMostRatedRestaurants();
    getMostRatedProducts();
    if (city?._id) {
      getBranchesByCity();
    } else {
      getBranchesAll();
    }
  }, [load]);

  const getBranchesByCity = async () => {
    try {
      const { data } = await axios.get(
        `${MostRatedBranchesByCityAPI}/${city?._id}`
      );
      // setBranches(data?.branches);
      setBranches(data?.branches);
      console.log(data?.branches.sort((a,b)=>b.totalRatings - a.totalRatings), 'ghjk')
      // console.log(data?.branches.filter(active => active.status && !active.deleted_at ),'m');
    } catch (error) {
      console.log(error);
    }
  };

  const getBranchesAll = async () => {
    try {
      const { data } = await axios.get(`${MostRatedBranchesAllAPI}`);
      setBranches(data?.branches);

   

    } catch (error) {
      console.log(error);
    }
  };

  const getMostRatedProducts = async () => {
    try {
      const { data } = await axios.get(AllProductListAPI);
      // setProductList(data?.products);
      setProductList(data?.products);

    } catch (error) {
      console.log(error);
    }
  };

  const getMostRatedRestaurants = async () => {
    try {
      const { data } = await axios.get(MostRatedRestaurantsAPI);
      setData(data?.restaurants);
    } catch (error) {
      console.log(error);
    }
  };

  const favouriteRestaurant = async (data) => {
    if (user?._id) {
      let obj = { restaurant: data?.restaurant_id?._id, user: user?._id };
      try {
        const { data } = await axios.post(favouriteRestaurantAPI, obj);
        if (data) {
          setRelaod(!reload);
          dispatch(setLoad(!load));
          AlertHandler(data?.message, "success");
        }
      } catch (error) {
        console.log(error);
      }
    } else if (!user?._id) {
      const _sessionArray = JSON.parse(win.getItem("ids"));
      if (_sessionArray === null || _sessionArray.length === 0) {
        const _array = [];
        _array.push(data?.restaurant_id?._id);
        AlertHandler("Add to Favourite", "success");
        setIds([...ids, data?.restaurant_id?._id]);
        return win.setItem("ids", JSON.stringify(_array));
      }
      if (_sessionArray?.length > 0) {
        if (_sessionArray.includes(data?.restaurant_id?._id)) {
          const findIndex = _sessionArray.findIndex(
            (item) => item === data?.restaurant_id?._id
          );
          _sessionArray.splice(findIndex, 1);
          AlertHandler("Remove from Favourite", "success");
          setIds(_sessionArray);
          return win.setItem("ids", JSON.stringify(_sessionArray));
        } else {
          _sessionArray.push(data?.restaurant_id?._id);
          AlertHandler("Add to Favourite", "success");
          setIds([...ids, data?.restaurant_id?._id]);
          return win.setItem("ids", JSON.stringify(_sessionArray));
        }
      }
    }
  };
  // const favouriteProduct = async (data) => {
  //     if (user?._id) {
  //         let obj = { item: data?._id, user: user?._id };
  //         try {
  //             const { data } = await axios.post(favouriteProductAPI, obj);
  //             if (data) {
  //                 const _newArray = productList?.map(item => {
  //                     if (item._id === data?._itemUpdate?._id) {
  //                         let newObject = Object.assign({}, item);
  //                         newObject.favourite_by_users = data?._itemUpdate.favourite_by_users
  //                         return newObject;
  //                     }
  //                     else {
  //                         return item;
  //                     }
  //                 })
  //                 setProductList(_newArray);
  //                 AlertHandler(data?.message, 'success');
  //             }
  //         } catch (error) {
  //             console.log(error);
  //         }
  //     }
  //     else if (!user?._id) {
  //         const _sessionArray = JSON.parse(win.getItem('p_ids'));
  //         if (_sessionArray === null || _sessionArray.length === 0) {
  //             const _array = [];
  //             _array.push(data?._id);
  //             win.setItem('p_ids', JSON.stringify(_array));
  //             setIds([...ids, data?._id]);
  //             return AlertHandler('Add to Favourite', 'success');
  //         }
  //         if (_sessionArray?.length > 0) {
  //             if (_sessionArray.includes(data?._id)) {
  //                 const findIndex = _sessionArray.findIndex(item => item === data?._id);
  //                 _sessionArray.splice(findIndex, 1);
  //                 AlertHandler('Remove from Favourite', 'success');
  //                 setIds(_sessionArray);
  //                 return win.setItem('p_ids', JSON.stringify(_sessionArray));
  //             }
  //             else if (!_sessionArray.includes(data?._id)) {
  //                 _sessionArray.push(data?._id);
  //                 win.setItem('p_ids', JSON.stringify(_sessionArray));
  //                 setIds([...ids, data?._id]);
  //                 return AlertHandler('Add to Favourite', 'success');
  //             }
  //         }
  //     }
  // }

  return (
    <div className="container trending">
      <>
        <Popular
          productList={data}
          setProductList={setProductList}
          ids={ids}
          setIds={setIds}
        />
        <Sales productList={productList} setProductList={setProductList} />
        <div className="pt-4 pb-3 title d-flex align-items-center ">
          <h5 data-aos="fade-left" className="mb-3">
            Restaurant Branches
          </h5>
        </div>
        <div style={{ position: "relative" }}>
          <Swiper
            style={{ position: "unset" }}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            navigation
            loop={true}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 5,
              },
              480: {
                slidesPerView: 2,
                spaceBetween: 5,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
            }}
          >
            {branches.length > 0
              ? branches?.sort((a,b)=>b.totalRatings - a.totalRatings).map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div
                      className="osahan-slider-item"
                      style={{ width: "auto" }}
                    >
                      <div className="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                        <div className="list-card-image">
                          <div className="star position-absolute">
                            <span className="badge badge-success">
                              <i className="feather-star" />{" "}
                              {item?.totalRatings
                                ? item?.totalRatings.toFixed(1)
                                : 0}{" "}
                              {/* (
                              {item?.ratings?.peopleRated
                                ? item?.ratings?.peopleRated
                                : 0}
                              ) */}
                            </span>
                          </div>
                          <div className="favourite-heart  position-absolute fav-icon-div">
                            <a onClick={() => favouriteRestaurant(item)}>
                              {
                                user?._id ? (
                                  <FavoriteBorderOutlinedIcon
                                    fontSize="small"
                                    style={{
                                      color:
                                        item?.restaurant_id?.favourite_by_users?.includes(
                                          user?._id
                                        ) && "red",
                                    }}
                                  />
                                ) : (
                                  <FavoriteBorderOutlinedIcon
                                    fontSize="small"
                                    style={{
                                      color:
                                        ids?.includes(item?._id) && "red",
                                    }}
                                  />
                                )
                                //     <FavoriteBorderOutlinedIcon fontSize='small'
                                //     style={{ color : item?.favourite_by_users?.includes(user?._id) && 'red' }}
                                // />
                                // :
                                // <FavoriteBorderOutlinedIcon fontSize='small'
                                //     style={{ color : ids?.includes(item?._id) && 'red'  }}
                                // />
                              }
                            </a>
                          </div>
                          <div className="member-plan position-absolute">
                            <span className="badge badge-dark">Promoted</span>
                          </div>
                          <Link
                            to={`restaurant?id=${item?.restaurant_id?._id}`}
                          >
                            <img
                              style={{ height: "227px" }}
                              alt="#"
                              src={
                                item?.file_id !== null
                                  ? `${URLForImages}/${item?.file_id?.file_path}`
                                  : "img/trending3.png"
                              }
                              className="img-fluid item-img w-100"
                            />
                          </Link>
                        </div>
                        <div
                          className="p-3 position-relative"
                          style={{ minHeight: "120px" }}
                        >
                          <div className="list-card-body">
                            <h6 className="mb-1">
                              <a
                                onClick={() =>
                                  navigate(
                                    `restaurant?id=${item?.restaurant_id?._id}`
                                  )
                                }
                                className="text-black text-capitalize"
                                tabIndex={-1}
                              >
                                {item?.name}
                              </a>
                            </h6>
                            {/* <p className="text-gray mb-3 mt-2">North Indian • Indian • Pure veg</p> */}
                            {/* <p className="text-gray mb-3 time mt-2"><span className="bg-light text-dark rounded-sm pl-0 pb-1 pt-1 pr-2" ><i className="feather-clock" /> 30–35 min</span> <span className="float-right text-black-50"> $250 FOR TWO</span></p> */}
                            {/* <div className="list-card-badge">
                                <span className="badge badge-danger mt-2">OFFER</span> <small>65% off</small>
                            </div> */}
                            <p className="text-gray mb-0 mt-2">{item?.bio}</p>
                            <p className="text-gray mb-0 mt-2">
                              {item?.opening_time} to {item?.closing_time}
                            </p>

                            <p className="text-gray mb-0 time">
                              {location?.coords !== null && (
                                <>
                                  <p className=" text-dark rounded-sm  pb-1 pt-1 pr-2 mb-0">
                                    <i className="feather-map-pin mr-1"  />
                                    {getDistanceFromLatLonInKm(
                                      location?.coords?.latitude,
                                      location?.coords?.longitude,
                                      item?.latitude,
                                      item?.longitude
                                    )}
                                    Km
                                  </p>
                                </>
                              )}
                              <p className="float-right text-black-50 text-start-left">
                                {" "}
                                {item?.address}
                              </p>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div class="flip-card">
  <div class="flip-card-inner">
    <div class="flip-card-front">
      <img width={'300px'} height={'300px'} src="https://www.seriouseats.com/thmb/gsco3uhFd26vcJNlJfJQi8tDs0g=/1125x1125/smart/filters:no_upscale()/__opt__aboutcom__coeus__resources__content_migration__serious_eats__seriouseats.com__recipes__images__2014__09__20140918-jamie-olivers-comfort-food-insanity-burger-david-loftus-f7d9042bdc2a468fbbd50b10d467dafd.jpg" alt="Avatar" />
    </div>
    <div class="flip-card-back">
      <h1 className='mt-3'>John Doe</h1> 
      <p className='text-color'>Architect & Engineer</p> 
      <p className='text-color'>We love that guy</p>
    </div>
  </div>
</div> */}
                  </SwiperSlide>
                );
              })
              :
              <>
                <div className="d-flex justify-content-between row">
                  <div className="col-3">
                    <div style={{ width: "40%" }} className="">
                      {/* For variant="text", adjust the height via font-size */}
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={350} />

                      {/* For other variants, adjust the size with `width` and `height` */}
                      <Skeleton variant="circular" width={40} height={40} />
                      <Skeleton variant="rectangular" width={350} height={120} className="mt-1" />
                      <Skeleton variant="rounded" width={350} height={120} />

                    </div>
                  </div>
                  <div className="col-3">
                    <div style={{ width: "40%" }} className="">
                      {/* For variant="text", adjust the height via font-size */}
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={350} />

                      {/* For other variants, adjust the size with `width` and `height` */}
                      <Skeleton variant="circular" width={40} height={40} />
                      <Skeleton variant="rectangular" width={350} height={120} className="mt-1" />
                      <Skeleton variant="rounded" width={350} height={120} />

                    </div>
                  </div>

                  <div className="col-3">
                    <div style={{ width: "40%" }} className="">
                      {/* For variant="text", adjust the height via font-size */}
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={350} />

                      {/* For other variants, adjust the size with `width` and `height` */}
                      <Skeleton variant="circular" width={40} height={40} />
                      <Skeleton variant="rectangular" width={350} height={120} className="mt-1" />
                      <Skeleton variant="rounded" width={350} height={120} />

                    </div>
                  </div>
                </div>

              </>

              // [1, 2, 3, 4].map((item, index) => {
              //     return (

              //       <SwiperSlide key={index}>
              //         <div
              //           className="osahan-slider-item"
              //           style={{ width: "auto" }}
              //         >
              //           <div className="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
              //             <div className="list-card-image">
              //               <div className="star position-absolute">
              //                 <span className="badge badge-success">
              //                   <i className="feather-star" /> 3.12 (350+)
              //                 </span>
              //               </div>
              //               <div className="favourite-heart text-danger position-absolute">
              //                 <a href="#" tabIndex={0}>
              //                   <i className="feather-heart" />
              //                 </a>
              //               </div>
              //               <div className="member-plan position-absolute">
              //                 <span className="badge badge-dark">Promoted</span>
              //               </div>
              //               <a tabIndex={0}>
              //                 <img
              //                   style={{ height: "230px" }}
              //                   alt="#"
              //                   src="img/landing1.png"
              //                   className="img-fluid item-img w-100"
              //                 />
              //               </a>
              //             </div>
              //             <div className="p-3 position-relative">
              //               <div className="list-card-body">
              //                 <h6 className="mb-1">
              //                   <a className="text-black" tabIndex={-1}>
              //                     Thai Famous Cuisine
              //                   </a>
              //                 </h6>
              //                 <p className="text-gray mb-3">
              //                   North Indian • Indian • Pure veg
              //                 </p>
              //                 <p className="text-gray mb-3 time">
              //                   <span className="bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2">
              //                     <i className="feather-map-pin" /> 30–35 min
              //                   </span>{" "}
              //                   <span className="float-right text-black-50">
              //                     {" "}
              //                     $250 FOR TWO
              //                   </span>
              //                 </p>
              //               </div>
              //               <div className="list-card-badge">
              //                 <span className="badge badge-success">OFFER</span>{" "}
              //                 <small>65% off</small>
              //               </div>
              //             </div>
              //           </div>
              //         </div>
              //       </SwiperSlide>
              //     );
              //   })
            }
          </Swiper>
        </div>
      </>

    </div>
  );
};

export default Trending;

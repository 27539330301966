import React, { useState } from "react";
import moment from "moment";

const Ratings = ({ ratingData }) => {
  const [slicedData, setslicedData] = useState(5);

  return ratingData?.length ? (
    <>
      {ratingData?.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt)).slice(0, slicedData).map((item, index) => {
        return (
          <div className="reviews-members py-3" key={index}>
            <div className="media">
              <a href="#">
                <img
                  alt="#"
                  src="img/reviewer2.png"
                  className="mr-3 rounded-pill"
                />
              </a>
              <div className="media-body">
                <div className="reviews-members-header">
                  <div className="star-rating float-right">
                    <div className="d-inline-block" style={{ fontSize: 14 }}>
                      <i
                        className={
                          item?.ratings === 0
                            ? "fa fa-star"
                            : "fa fa-star text-warning"
                        }
                      />
                      <i
                        className={
                          item?.ratings < 2
                            ? "fa fa-star"
                            : "fa fa-star text-warning"
                        }
                      />
                      <i
                        className={
                          item?.ratings < 3
                            ? "fa fa-star"
                            : "fa fa-star text-warning"
                        }
                      />
                      <i
                        className={
                          item?.ratings < 4
                            ? "fa fa-star"
                            : "fa fa-star text-warning"
                        }
                      />
                      <i
                        className={
                          item?.ratings < 5
                            ? "fa fa-star"
                            : "fa fa-star text-warning"
                        }
                      />
                    </div>
                  </div>
                  <h6 className="mb-0">
                    <a className="text-dark" href="#">
                      {item?.user_id?.fullname}
                    </a>
                  </h6>
                  <p
                    className="text-capitalize mb-0"
                    style={{ fontSize: "15px" }}
                  >
                    {item?.comment}.
                  </p>
                  <p className="text-muted " style={{ fontSize: "14px" }}>
                    {moment(item?.createdAt).format("L")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      {ratingData.length > 5 ? (
        <a
          className="text-center w-100 d-block mt-3 font-weight-bold"
          onClick={() =>
            setslicedData((prev) => (prev === 5 ? ratingData.length : 5))
          }
        >
          {slicedData === 5 ? "See More Reviews" : "See Less Reviews"}
        </a>
      ) : (
        <></>
      )}
    </>
  ) : (
    <h6 className="text-center text-danger">No reviews Yet</h6>
  );
};

export default Ratings;

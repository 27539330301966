import React from "react";
import { top100Films } from "./Movies";
import Autocomplete from "@mui/material/Autocomplete";
import StarRatings from "react-star-ratings";
import { CircularProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import LoginModal from "../../Common/Modal/LoginModal";

const Comment = ({
  data,
  setItemRated,
  changeRating,
  sendRatingData,
  loading1,
  setComment,
  rating,
}) => {
  useEffect(() => {
    Aos.init({ duration: 700 });
  }, []);
  const user = useSelector((state) => state.Auth.user);

  return (
    <>
      {(user && (
        <div className="bg-white p-3 rating-review-select-page rounded shadow-sm">
          <h6
            data-aos="fade-left"
            className="mb-3"
            style={{ fontSize: "25px" }}
          >
            Leave Comment
          </h6>
          <Autocomplete
            style={{ width: "100%", marginBottom: "15px" }}
            disablePortal
            id="combo-box-demo"
            options={top100Films(data?.products)}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Available Products" />
            )}
            size="small"
            onChange={(e, v) => setItemRated(v?._id)}
          />
          <div className="d-flex align-items-center mb-3">
            <p className="m-0" style={{ fontSize: "15px" }}>
              Rate the Place
            </p>
            <div className="star-rating ml-auto">
              <StarRatings
                rating={rating}
                starRatedColor="#ffc107"
                starHoverColor="#ffc107"
                changeRating={changeRating}
                numberOfStars={5}
                name="rating"
                starDimension="20px"
                starSpacing="0px"
              />
            </div>
          </div>
          <form>
            <div className="form-group">
              <label className="form-label " style={{ fontSize: "15px" }}>
                Your Comment
              </label>
              <textarea
                onChange={(e) => setComment(e.target.value)}
                className="form-control"
                defaultValue={""}
              />
            </div>
            <div onClick={sendRatingData} className="form-group mb-0">
              <button
                type="button"
                className="btn btn-primary btn-block "
                style={{ fontSize: "16px", width: "auto" }}
              >
                {loading1 ? <CircularProgress size="2rem" /> : "Submit Comment"}{" "}
              </button>
            </div>
          </form>
        </div>
      )) || (
        <div className="login-modal-data">
          <a
            data-toggle={"modal"}
            data-target={"#login" || ""}
            className="widget-header mr-4 "
            style={{ cursor: "pointer", textAlign: "center" }}
          >
            PLEASE LOGIN FIRST TO ADD COMMENTS
          </a>
        </div>
      )}
      <div
        className="modal fade"
        id="login"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content modal-contentlogin " style={{height:'auto'}}>
            <div className="modal-header ">
              <h5 className=" cmnt"></h5>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <LoginModal />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Comment;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    city: null
}

const CitySlice = createSlice({
    name: 'city',
    initialState,
    reducers: {
        setCity(state, action) {
            state.city = action.payload.city;
        },
        removeCity(state, action) {
            state.city = null;
        }
    }
});

export const { setCity, removeCity } = CitySlice.actions;
export default CitySlice.reducer;
import React, { useState, useEffect } from "react";
import { LoginAPI, FacebookLoginAPI, GoogleLoginAPI } from "../../Apis/Apis";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setAuthData } from "../../Redux/AuthSlice";
import { AlertHandler } from "../../Common/AlertHandler";
import CircularProgress from "@mui/material/CircularProgress";
import FacebookLogin from "react-facebook-login";
import { useNavigate } from "react-router-dom";
import GoogleLogin from "react-google-login";

const Login = () => {
  useEffect(() => {
    document.querySelector("#logout button")?.click();
    const backdrop = document.querySelector(".modal-backdrop");
    backdrop?.classList.remove("show", "fade");
    if (backdrop) backdrop.style.display = "none";
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.Auth.token);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const loginFunc = async (e) => {
    e.preventDefault();
    setLoading(true);
    const obj = { username, password };
    try {
      const { data } = await axios.post(LoginAPI, obj);
      if (data.success === true) loginSuccessfullFunc(data);
    } catch (error) {
      setLoading(false);
      AlertHandler(error.response.data.message, "error");
    }
  };

  const responseFacebook = async (response) => {
    try {
      const { data } = await axios.post(FacebookLoginAPI, response);
      if (data.success === true) loginSuccessfullFunc(data);
    } catch (error) {
      AlertHandler("Something wrong with facebook login", "error");
    }
  };

  const responseGoogle = async (response) => {
    const { tokenId } = response;
    try {
      const { data } = await axios.post(GoogleLoginAPI, { tokenId });
      if (data.success === true) loginSuccessfullFunc(data);
    } catch (error) {
      AlertHandler("Something wrong with Google login", "error");
    }
  };

  const loginSuccessfullFunc = (data) => {
    setLoading(false);
    dispatch(setAuthData(data));
    AlertHandler("Login Successfull", "success");
  };



  return (
    <div className="login-page vh-100" style={{ position: "relative" }}>
      {token && navigate("/")}
      <video loop autoPlay muted id="vid">
        <source src="img/bg.mp4" type="video/mp4" />
        <source src="img/bg.mp4" type="video/ogg" />
        Your browser does not support the video tag.
      </video>
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="px-5 col-md-6 ">
          <div className="px-5 col-10 mx-auto">
            <h2 className="text-dark my-0">Welcome Back</h2>
            <p className="text-50">Sign in to continue</p>
            <form className="mt-5 mb-4" action="verification.html">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="text-dark">
                  Username
                </label>
                <input
                  onChange={(e) => setUsername(e.target.value)}
                  type="email"
                  placeholder="Enter Username"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div
                className="form-group d-flex"
                style={{ alignItems: "center" }}
              >
                <div style={{ width: "100%" }}>
                  <label htmlFor="exampleInputPassword1" className="text-dark">
                    Password
                  </label>

                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    type={passwordShown ? "text" : "password"}
                    placeholder="Enter Password"
                    className="form-control"
                    id="exampleInputPassword1"
                  />
                </div>
                <div>
                  
                  <i
                    onClick={togglePassword}
                    className="fa fa-eye-slash"
                    style={{ fontSize: "20px", marginTop: "15px" }}
                  ></i>
                </div>
              </div>
              <button
                onClick={loginFunc}
                className="btn btn-primary btn-lg btn-block"
              >
                {loading ? (
                  <CircularProgress size="1rem" style={{ color: "white" }} />
                ) : (
                  "SIGN IN"
                )}
              </button>
              <div className="py-2">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    document.getElementsByClassName("btnFacebook")[0].click();
                  }}
                  className="btn btn-lg btn-facebook btn-block"
                >
                  <i className="feather-facebook" /> Connect with Facebook
                </button>
                <FacebookLogin
                  appId="1063588737606172"
                  fields="name,email,picture"
                  callback={responseFacebook}
                  cssClass="btnFacebook"
                />
                <GoogleLogin
                  clientId="425955384574-itgctjobrmjl0jiciutgvv9ge4b043jg.apps.googleusercontent.com"
                  buttonText="Connect with Google"
                  onSuccess={responseGoogle}
                  cookiePolicy={"single_host_origin"}
                  className="btn-lg btn-block my-2 py-1 d-flex justify-content-center"
                  cssClass="btngoogle-api"
                  style={{ borderRadius: "5px", color: "black" }}
                />
              </div>
            </form>
            <a href="forgot_password.html" className="text-decoration-none">
              {/* <p className="text-center">Forgot your password?</p> */}
            </a>
            <div className="d-flex align-items-center justify-content-center">
              <a
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/signup")}
              >
                <p className="text-center m-0">
                  Don't have an account? Sign up
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Filter.css";
import { GetMenuCategoriesAPI, URLForImages } from "../../Apis/Apis";
import Aos from "aos";
import "aos/dist/aos.css";
import { Category } from "@mui/icons-material";
SwiperCore.use([Navigation]);

const Filter = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    Aos.init({ duration: 700 });
  }, []);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(GetMenuCategoriesAPI);
      if (data?.status === true) {
        setLoading(false);
        setData(
          data.categories.filter(
            (active) => active.status && !active.deleted_at
          )
        );
        // console.log(data.categories.filter(active => active.status &&  !active.deleted_at),"majid-1");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="container filter mt-5">
      <h2 data-aos="fade-left" className="mb-3 ">
        Food Categories
      </h2>
      <div style={{ position: "relative" }}>
        {loading === false ? (
          <Swiper
            style={{ position: "unset" }}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            navigation
            loop={true}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 5,
              },
              480: {
                slidesPerView: 3,
                spaceBetween: 5,
              },
              768: {
                slidesPerView: 5,
                spaceBetween: 5,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 5,
              },
              1280: {
                slidesPerView: 7,
                spaceBetween: 5,
              },
            }}
          >
            {data?.length > 0
              ? data?.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div
                        className="cat-item px-1 py-3"
                        style={{ width: "auto" }}
                      >
                        <Link
                          to={`trending?id=${item?._id}`}
                          className="bg-white rounded d-block p-2 text-center shadow-sm"
                        //   onClick={() => navigate(`trending?id=${item?._id}`)}
                          tabIndex={0}
                        >
                          <img
                            style={{ width: "70px", height: "70px" }}
                            alt="#"
                            src={
                              item?.file_id
                                ? `${URLForImages}/${item?.file_id?.file_path}`
                                : "img/dummy.png"
                            }
                            className="img-fluid mb-2"
                          />
                          <p className="m-0 font-size-large text-capitalize text-width">
                            {item?.name}
                          </p>
                        </Link>
                      </div>
                    </SwiperSlide>
                  );
                })
              : [
                  "Pizza",
                  "Burger",
                  "Shuwarma",
                  "Breakfast",
                  "Fries",
                  "Coffee",
                  "Sandwich",
                  "Cold Drink",
                  "Coffee",
                  "Sandwich",
                  "Cold Drink",
                ].map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div
                        className="cat-item px-1 py-3 "
                        style={{ width: 141 }}
                      >
                        <a
                          className="bg-white rounded d-block p-2 text-center shadow-sm"
                          tabIndex={-1}
                        >
                          <img
                            className="img-fluid mb-2"
                            src="img/landing1.png"
                          />
                          <p className="m-0 small">{item}</p>
                        </a>
                      </div>
                    </SwiperSlide>
                  );
                })}
          </Swiper>
        ) : (
          <div className="d-flex justify-content-center w-100 my-2">
            <CircularProgress size="2rem" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Filter;

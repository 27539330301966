import React from 'react'
import { Link , useSearchParams } from 'react-router-dom'

const Menu = ({ categoryMenu, menuDetail, getProduct, getProductsByName }) => {
    const [srch] = useSearchParams()
    return (
        <div className="shadow-sm rounded bg-white mb-3 overflow-hidden" style={{ marginBottom: '1rem' }}>
            <div className="row m-0">
                <h6 className="p-3 m-0 bg-light w-100" style={{fontSize:'25px'}}>{menuDetail?.status === false ? 'Menu' : menuDetail?.menu?.name} <span className="text-black-50" style={{fontSize:'14px'}}>{menuDetail?.status === false ? categoryMenu?.length : menuDetail?.products?.length} ITEMS</span></h6>
                <div className="col-md-12 px-0 border-top">
                    {
                        menuDetail?.status === false ? categoryMenu?.map((item, index) => {
                            return <div className key={index}>
                                <Link  to={`/product?id=${item?._id}`}>
                                <div className="p-3 border-bottom gold-members">
                                    <div className="media">
                                        <div className="mr-3 font-weight-bold text-danger non_veg">.</div>
                                        <div className="media-body">
                                            <h6 className="mb-1 text-capitalize" style={{fontSize:'19px'}}>{item?.name} </h6>
                                        </div>
                                    </div>
                                </div>
                                </Link>
                              
                            </div>
                        }) :
                            menuDetail?.products?.filter(item => item._id !== srch.get('id')).map((item, index) => {
                                return <div onClick={() => {
                                    getProduct(item?._id);
                                    getProductsByName(item?.name);
                                }} className key={index}>
                                       <Link  to={`/product?id=${item?._id}`}><div className="p-3 border-bottom gold-members">
                                        <div className="media">
                                            <div className="mr-3 font-weight-bold text-danger non_veg">.</div>
                                            <div className="media-body">
                                                <h6 className="mb-1 text-capitalize">{item?.name} </h6>
                                            </div>
                                        </div>
                                    </div></Link>
                                    
                                </div>
                            })

                    }
                </div>
            </div>
        </div>
    )
}

export default Menu